import React from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import LoadingButton from "../../components/loading-button";
import { FieldSet } from "../../components/form-layout";

import renderTextField from "../../components/textfield-field";
import renderCheckbox from "../../components/single-checkbox-field";
import CheckboxFieldSet from "../../components/checkbox-fieldset";

import Account from "@material-ui/icons/AccountCircleOutlined";
import Group from "@material-ui/icons/Group";

import { roleKeys } from "../../store/user";
import { changeUser } from "../../store/admin";

function UserForm({ user, changeUser }) {
  const title = user.displayName || user.email;
  const subheader = user.displayName ? user.email : "";
  const roles = Object.keys(user.customClaims || {});

  const initialValues = {
    displayName: user.displayName,
    disabled: user.disabled,
    roles
  };

  const onSubmit = values => {
    console.log(values);
    return changeUser(user.uid, values);
  };
  const roleOptions = roleKeys.map(r => ({
    label: r,
    value: r
  }));
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, submitError }) => (
        <Card>
          <CardHeader
            avatar={<Avatar src={user.photoURL} />}
            title={title}
            subheader={subheader}
          />
          <Divider />
          <CardContent>
            {submitError}
            <FieldSet icon={<Account />}>
              <Field
                name="displayName"
                component={renderTextField}
                label="Name"
              />
              <Field
                type="checkbox"
                name="disabled"
                label="Disabled"
                component={renderCheckbox}
              />
            </FieldSet>
            <FieldSet icon={<Group />} variant="selection">
              <CheckboxFieldSet
                name="roles"
                label="Roles"
                options={roleOptions}
              />
            </FieldSet>
          </CardContent>
          <Divider />
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={pristine || submitting}
              loading={submitting}
              onClick={handleSubmit}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      )}
    </Form>
  );
}

export default connect(
  (state, props) => {
    return {
      user: state.admin.users.find(u => u.uid === props.userId) || {}
    };
  },
  { changeUser }
)(UserForm);
