import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { firestoreConnect } from "react-redux-firebase";
import { downloadExport } from "../../store/upload";

export default function makeTaskContainer(collection) {
  const mapStateToProps = (state, props) => {
    return {
      tasks: state.firestore.ordered[collection]
    };
  };

  const enhance = compose(
    firestoreConnect(props => [
      {
        collection,
        where: [["deleted", "==", false]]
      }
    ]),
    withHandlers({
      archive: props => taskId => {
        props.firestore.update(`${collection}/${taskId}`, { deleted: true });
      },
      downloadExport: props => path => downloadExport(path)
    }),
    connect(mapStateToProps)
  );
  return enhance;
}
