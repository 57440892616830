import React, { useState } from "react";

import {
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  LabelSeries
} from "react-vis";
import { withTheme } from "@material-ui/core/styles";

function StatsChart({ data, selected, setSelected, theme }) {
  const [hovered, setHover] = useState(false);

  const labelStyle = {
    ...theme.typography.overline,
    fill: theme.palette.common.white,
    fontWeight: "bold"
  };

  const d = data.map(({ name, count, code }, i) => ({
    x: code,
    y: count,
    opacity: code === hovered ? 0.6 : 1,
    color:
      code === selected
        ? theme.palette.primary.dark
        : theme.palette.primary.light
  }));
  return (
    <FlexibleWidthXYPlot
      height={500}
      xType="ordinal"
      onMouseLeave={() => setHover(false)}
    >
      <XAxis />
      <YAxis />
      <VerticalBarSeries
        data={d}
        colorType="literal"
        onNearestX={value => {
          setHover(value.x);
        }}
        onValueClick={d => {
          setSelected(d.x);
        }}
      />
      <LabelSeries
        data={d}
        getLabel={d => d.y}
        labelAnchorX="middle"
        marginTop={15}
        labelAnchorY="before-edge"
        style={labelStyle}
      />
    </FlexibleWidthXYPlot>
  );
}

export default withTheme()(StatsChart);
