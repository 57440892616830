import React from "react";

import Grid from "@material-ui/core/Grid";
import DokuSidebar from "./doku-sidebar";
import ErrorBoundary from "../components/error-boundary";

const SummaryPage = ({ children, ...rest }) => (
  <Grid container spacing={24}>
    <Grid item xs={3}>
      <DokuSidebar />
    </Grid>
    <Grid item xs={9}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Grid>
  </Grid>
);

export default SummaryPage;
