import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import _get from "lodash/get";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import sum from "lodash/sum";
import { STATUS_UI } from "../../constants";
import Tooltip from "@material-ui/core/Tooltip";

const enhance = connect((state, props) => ({
  byStatus: _get(
    state,
    `firestore.data.questions.${props.questionId}.byStatus`,
    {}
  )
}));

const Line = styled.div`
  background-color: ${props => props.baseColor[300]};
  width: ${props => props.width}%;
  height: 16px;
  :hover {
    background-color: ${props => props.baseColor[500]};
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

export function DistributionLine({ byStatus }) {
  const total = sum(map(byStatus));
  const bars = sortBy(
    map(byStatus, (count, key) => {
      const UI = STATUS_UI[key];
      const width = Math.round((count / total) * 100);
      return (
        <Tooltip title={`${UI.label}: ${count}`} placement="top">
          <Line key={key} baseColor={UI.baseColor} width={width} />
        </Tooltip>
      );
    }),
    "order"
  );
  return <Container>{bars}</Container>;
}

export default enhance(DistributionLine);
