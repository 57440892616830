import React from "react";
import {
  HorizontalBarSeries,
  YAxis,
  LabelSeries,
  FlexibleWidthXYPlot
} from "react-vis";
import { withTheme } from "@material-ui/core/styles";
import sortBy from "lodash/sortBy";
import size from "lodash/size";
import times from "lodash/times";

const axisWidth = 200;

function SubStatsChart({ data, theme }) {
  const labelStyle = {
    ...theme.typography.caption,
    fontWeight: "bold"
  };

  const opacity = i => (i / size(data)) * 0.8 + 0.2;
  const shortenName = name =>
    name.length <= 23 ? name : `${name.substring(0, 20)}...`;

  const d = sortBy(data, "count").map(({ name, count }, i) => ({
    y: shortenName(name),
    x: count,
    opacity: opacity(i)
  }));
  const labelData = d.map(r => ({
    ...r,
    style: {
      fill: r.opacity > 0.7 ? "white" : "black"
    }
  }));
  return (
    <FlexibleWidthXYPlot yType="ordinal" height={500} animation>
      <YAxis width={axisWidth} style={labelStyle} />
      <HorizontalBarSeries
        data={d}
        marginLeft={axisWidth}
        color={theme.palette.primary.dark}
      />
      <LabelSeries
        data={labelData}
        getLabel={d => d.x}
        xRange={times(size(d), () => 0)}
        labelAnchorY="central"
        labelAnchorX="start"
        marginLeft={axisWidth + 10}
        style={labelStyle}
      />
    </FlexibleWidthXYPlot>
  );
}

export default withTheme()(SubStatsChart);
