import React from "react";
import { isEmpty } from "react-redux-firebase";
import styled from "styled-components";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import SuccessIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Close";

import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";

import format from "date-fns/format";

import TaskContainer from "../upload/tasks-container";

import {
  CREATED,
  UPLOADED,
  PROCESSING,
  ERROR,
  SUCCESS,
  STATUS
} from "../../store/upload";

const STATUS_ICONS = {
  [ERROR]: ErrorIcon,
  [SUCCESS]: SuccessIcon
};

const COLORS = {
  [CREATED]: blue[300],
  [UPLOADED]: blue[300],
  [PROCESSING]: blue[300],
  [ERROR]: red[500],
  [SUCCESS]: green[500]
};

const Label = styled.span`
  margin-left: 12px;
`;

const IconCell = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Inspiration: https://dribbble.com/shots/5356083-File-dropzone/attachments/1161596
 */
const Task = ({ task, archive, download }) => {
  let { id, date, questionId, status, user, count, error } = task;
  const Icon = STATUS_ICONS[status];
  const color = COLORS[status];
  const label = STATUS[status];
  const progress = [CREATED, UPLOADED, PROCESSING].indexOf(status) > -1;

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row">
        {format(date.toDate(), "YY-MM-DD HH:MM")}
      </TableCell>
      <TableCell>
        <IconCell>
          {progress ? (
            <CircularProgress size={12} />
          ) : (
            <Icon style={{ fill: color }} />
          )}
          <Label>{label}</Label>
        </IconCell>
      </TableCell>
      <TableCell>{questionId}</TableCell>
      <TableCell>{user}</TableCell>
      <TableCell>{error || count}</TableCell>
      <TableCell>
        {status === SUCCESS && <Button onClick={download}>Download</Button>}
      </TableCell>
      <TableCell>
        <Tooltip title="Archive">
          <IconButton onClick={archive}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

function TasksList({ tasks, archive, downloadExport }) {
  if (isEmpty(tasks)) {
    return null;
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Question</TableCell>
          <TableCell>User</TableCell>
          <TableCell>Processed</TableCell>
          <TableCell>Download</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map(t => (
          <Task
            key={t.id}
            task={t}
            archive={() => archive(t.id)}
            download={() => downloadExport(t.targetPath)}
          />
        ))}
      </TableBody>
    </Table>
  );
}

export default TaskContainer("downloads")(TasksList);
