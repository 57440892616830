import React from "react";
import Grid from "@material-ui/core/Grid";
import ProjectTable from "./project-table";
import ClassificationStats from "./classification-stats";

export default function HomePage() {
  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <ProjectTable />
      </Grid>
      <Grid item xs={12}>
        <ClassificationStats />
      </Grid>
    </Grid>
  );
}
