import pick from "lodash/pick";
import split from "lodash/split";
import head from "lodash/head";

export const AGENT_ROLE = "agent";
export const CLIENT_ROLE = "client";
export const ADMIN_ROLE = "admin";
export const DOCU_ROLE = "docus";

export function displayName(auth) {
  return auth.displayName || head(split(auth.email, "@"));
}

export const roleKeys = [AGENT_ROLE, CLIENT_ROLE, ADMIN_ROLE, DOCU_ROLE];

export const checkRole = role => context => {
  const roles = context.roles ? context.roles : context;
  return roles.indexOf(role) > -1;
};

export const isAgent = checkRole(AGENT_ROLE);
export const isClient = checkRole(CLIENT_ROLE);
export const isAdmin = checkRole(ADMIN_ROLE);
export const hasDocu = checkRole(DOCU_ROLE);

export const getUser = state => {
  return {
    // Auth
    id: state.firebase.auth.uid,
    name: state.firebase.auth.displayName,
    photoURL: state.firebase.auth.photoURL,
    // User
    roles: state.user.roles
  };
};

export const ROLES_LOADED = "USER:ROLES_LOADED";
export const loadRoles = claims => {
  const roles = Object.keys(pick(claims, roleKeys));
  return {
    type: ROLES_LOADED,
    roles
  };
};

const initialState = {
  isLoaded: false,
  roles: []
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ROLES_LOADED:
      return {
        ...state,
        isLoaded: true,
        roles: action.roles
      };
    case "@@reactReduxFirebase/LOGOUT":
      return initialState;
    default:
      return state;
  }
}
