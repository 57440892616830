import { connect } from "react-redux";
import { loadUsers } from "../../store/admin";
import { compose, lifecycle } from "recompose";

const enhance = compose(
  connect(
    state => ({
      users: state.admin.users
    }),
    { loadUsers }
  ),
  lifecycle({
    componentDidMount() {
      const { loadUsers, users } = this.props;
      if (users.length === 0) {
        loadUsers();
      }
    }
  })
);

export default enhance;
