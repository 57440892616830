import React from "react";
import { Router, Redirect } from "@reach/router";
import Layout from "./common/layout";

import {
  requireAdmin,
  isAuthenticated,
  notAuthenticated
} from "./common/auth-container";

import _LoginPage from "./auth/login-page";
import GoogleLogin from "./auth/google-login";
import ActivateAccountPage from "./auth/activate-account";
import _HomePage from "./home/home-page";
import _ClassifyPage from "./classify/classify-page";

import TextPage from "./classify/text";
import CompletedPage from "./classify/completed-page";
import NoResultPage from "./classify/no-result-page";
import NoAccessPage from "./classify/no-access-page";
import ErrorPage from "./classify/error-page";

import _UploadTextsPage from "./admin/upload/upload-page";
import _DownloadTextsPage from "./admin/download";
import _RecalcStatsPage from "./admin/recalc-page";
import _UserListPage from "./admin/user-management/user-list-page";
import UserForm from "./admin/user-management/user-form";
import _ProjectListPage from "./admin/project-admin";
import ProjectForm from "./admin/project-admin/project-form";
import {
  EditContainer,
  NewContainer
} from "./admin/project-admin/project-form-containers";

import SummaryStartPage from "./summary/summary-start-page";
import SummaryPage from "./summary/summary-page";
import SummaryCard from "./summary/summary-card";
import LoadResultPage from "./summary/load-result-page";
import DokuLoader from "./summary/doku-loader";

const LoginPage = notAuthenticated(_LoginPage);
const HomePage = isAuthenticated(_HomePage);
const ClassifyPage = isAuthenticated(_ClassifyPage);
const UploadTextsPage = requireAdmin(_UploadTextsPage);
const DownloadTextsPage = requireAdmin(_DownloadTextsPage);
const RecalcStatsPage = requireAdmin(_RecalcStatsPage);
const UserListPage = requireAdmin(_UserListPage);
const ProjectListPage = requireAdmin(_ProjectListPage);
const EditProject = EditContainer(ProjectForm);
const NewProject = NewContainer(ProjectForm);
// const DokuLoader = isAuthenticated(_DokuLoader);

const SummaryIndex = ({ lc, opco }) =>
  lc ? (
    <Redirect to={`/summary/lc/${lc}/global/global_byCountry`} replace />
  ) : (
    <Redirect to={`/summary/opco/${opco}/global/global_byBU`} replace />
  );

export default function Routes() {
  return (
    <Layout>
      <Router primary={false}>
        <HomePage path="/" />
        <LoginPage path="/login">
          <GoogleLogin path="google" />
        </LoginPage>
        <ActivateAccountPage path="/activate" />

        <NoAccessPage path="/permission-denied" />
        <ClassifyPage path="classify/:questionId">
          <NoAccessPage path="permission-denied" />
          <CompletedPage path="completed" />
          <NoResultPage path="no-result" />
          <ErrorPage path="error" />
          <TextPage path="text/:textId" />
        </ClassifyPage>

        <UploadTextsPage path="admin/upload" />
        <DownloadTextsPage path="admin/download" />
        <RecalcStatsPage path="admin/recalc" />
        <UserListPage path="admin/users">
          <UserForm path=":userId" />
        </UserListPage>
        <ProjectListPage path="admin/projects">
          <NewProject path="new" />
          <EditProject path=":projectId" />
        </ProjectListPage>

        <SummaryStartPage path="/summary" />
        <SummaryPage path="/summary/opco/:opco">
          <SummaryIndex path="/" />
          <SummaryCard path=":mainStep/:subStep" />
        </SummaryPage>
        <SummaryPage path="/summary/lc/:lc">
          <SummaryIndex path="/" />
          <SummaryCard path=":mainStep/:subStep" />
        </SummaryPage>
        <LoadResultPage path="/summary/load" />
        <DokuLoader path="/summary/check-file" />
      </Router>
    </Layout>
  );
}
