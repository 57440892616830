import Papa from "papaparse";
import { navigate } from "@reach/router";
import { loadState, saveState } from "../persist-localstorage";
import firebase from "../../firebase";
import omit from "lodash/omit";

import { structureDataSync, transformRow } from "./results-calculation";

const storageRef = firebase.storage().ref();

let worker;

const getWorker = () => {
  if (!worker) {
    // eslint-disable-next-line
    const ResultsWorker = require("-!worker-loader!./results.worker.js");
    worker = new ResultsWorker();
  }
  return worker;
};

const DATA_LOADED = "RESULT:DATA_LOADED";
const START_LOADING = "RESULT:START_LOADING";
const START_CLOUD_UPLOAD = "RESULT:START_CLOUD_UPLOAD";
const START_CLOUD_DOWNLOAD = "RESULT:START_CLOUD_DOWNLOAD";
const COMPLETE_CLOUD_UPLOAD = "RESULT:COMPLETE_CLOUD_UPLOAD";
const PROGRESS_CLOUD_UPLOAD = "RESULT:PROGRESS_CLOUD_UPLOAD";

const dataLoaded = structuredData => ({
  type: DATA_LOADED,
  structuredData
});

export const downloadFromCloud = filePath => async dispatch => {
  console.log("Downloading", filePath);
  dispatch({ type: START_CLOUD_DOWNLOAD, filePath });

  const downloadRef = storageRef.child(filePath);
  const downloadLink = await downloadRef.getDownloadURL();
  console.log("From URL", downloadLink);
  dispatch(parseFileSync(downloadLink, { download: true }));
};

export const uploadCloud = file => async dispatch => {
  const newName = `${new Date().getTime()}-${file.name}`;
  const year = new Date().getFullYear();
  const targetPath = `dokus-${year}/${newName}`;
  dispatch({ type: START_CLOUD_UPLOAD, targetPath });
  console.log("TargetPath", targetPath);
  const uploadRef = storageRef.child(targetPath);
  const uploadTask = uploadRef.put(file);
  uploadTask.on(
    "state_changed",
    function(snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(progress);
      dispatch({ type: PROGRESS_CLOUD_UPLOAD, progress });
      // console.log("Upload is " + progress + "% done");
      // switch (snapshot.state) {
      //   case firebase.storage.TaskState.PAUSED: // or 'paused'
      //     console.log("Upload is paused");
      //     break;
      //   case firebase.storage.TaskState.RUNNING: // or 'running'
      //     console.log("Upload is running");
      //     break;
      // }
    },
    function(error) {
      // Handle unsuccessful uploads
      console.error(error);
    },
    function() {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      dispatch({ type: COMPLETE_CLOUD_UPLOAD, targetPath });
      setTimeout(() => navigate("/summary"), 400);
    }
  );
};

export const loadFileSync = file => dispatch => {
  dispatch(parseFileSync(file));
};

export const loadFile = (file, cloudUpload) => dispatch => {
  if (cloudUpload) {
    dispatch(uploadCloud(file));
  }
  dispatch(loadFileSync(file));
};

export const loadTestFixtures = () => dispatch => {
  const filePath = require("../../fixtures/nps-2018-master.csv");
  if (process.env.NODE_ENV === "production") {
    // return loadTestFixturesAsync()(dispatch);
  }
  dispatch(parseFileSync(filePath, { download: true }));
};

export const parseFileSync = (file, config) => (dispatch, getState) => {
  dispatch({ type: START_LOADING });
  console.time("parse");
  Papa.parse(file, {
    header: true,
    dynamicTyping: true,
    ...config,
    complete: res => {
      console.timeEnd("parse");
      const mapped = res.data.map(transformRow);
      const structuredData = structureDataSync(mapped);
      dispatch(dataLoaded(structuredData));
      if (!getState().results.isCloudUploading) {
        setTimeout(() => navigate("/summary"), 400);
      }
    }
  });
};

export const loadTestFixturesAsync = () => dispatch => {
  console.time("workerProcess");
  dispatch({ type: START_LOADING });
  const worker = getWorker();
  worker.addEventListener("message", event => {
    const { data: message } = event;
    if (message.type === "WORKER:STRUCTURED_DATA") {
      dispatch(dataLoaded(message.data));
      console.timeEnd("workerProcess");
    }
    if (message.type === "WORKER:LOG") {
      console.log(message.msg);
    }
    // I can probably clean up now
  });
  worker.postMessage({ type: "WORKER:LOAD_TEST_FIXTURES" });
};

console.time("loadState");
const cachedState = loadState("results");
const defaultState = {
  isLoading: false,
  isCloudUploading: false,
  loaded: false
};
const initialState = {
  ...cachedState,
  ...defaultState
};
console.timeEnd("loadState");

function resultReducer(state = initialState, action) {
  switch (action.type) {
    case DATA_LOADED: {
      const newState = {
        ...state,
        structuredData: action.structuredData,
        isLoading: false,
        isDownloading: false,
        loaded: true
      };
      const cachedState = omit(newState, "isCloudUploading", "progress");
      saveState(cachedState, "results");
      return newState;
    }
    case START_LOADING: {
      return {
        ...state,
        isLoading: true,
        loaded: false
      };
    }
    case START_CLOUD_UPLOAD: {
      return {
        ...state,
        isCloudUploading: true,
        targetPath: action.targetPath
      };
    }
    case START_CLOUD_DOWNLOAD: {
      return {
        ...state,
        isDownloading: true,
        targetPath: action.filePath,
        hasDownloaded: true
      };
    }
    case PROGRESS_CLOUD_UPLOAD: {
      return {
        ...state,
        progress: action.progress
      };
    }
    case COMPLETE_CLOUD_UPLOAD: {
      return {
        ...state,
        isCloudUploading: false
      };
    }
    default:
      return state;
  }
}

export default resultReducer;
