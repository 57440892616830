import React, { useCallback } from "react";
import { Form } from "react-final-form";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import { downloadJob } from "../../store/upload";
import ProjectsContainer from "../upload/projects-container";

import QuestionSelect from "../upload/question-select";

function DownloadForm({ projects }) {
  const submit = useCallback(values => {
    const { question } = values;
    downloadJob(question.id);
  });

  return (
    <Form onSubmit={submit}>
      {({ handleSubmit, pristine, form, invalid, submitting }) => {
        return (
          <>
            <CardContent>
              <QuestionSelect projects={projects} />
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSubmit}
                disabled={pristine || invalid}
              >
                Generate Export
              </Button>
            </CardActions>
          </>
        );
      }}
    </Form>
  );
}

DownloadForm.defaultProps = {
  projects: []
};

export default ProjectsContainer(DownloadForm);
