import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import OnlyAuthenticated from "./only-authenticated";
import { navigate } from "@reach/router";

import FilterButton from "../classify/filter";

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${theme.drawerWidth}px)`,
    marginLeft: theme.drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  menuButton: {
    marginLeft: 12
  },
  hide: {
    display: "none"
  },
  grow: {
    marginLeft: 20,
    flexGrow: 1
  },
  toolbar: {
    paddingRight: 24
  }
});

function navigateRoot() {
  const [
    // eslint-disable-next-line
    none,
    summary
    // eslint-disable-next-line
  ] = location.pathname.split("/");
  if (summary === "summary") {
    return navigate("/summary");
  }
  return navigate("./");
}

function ClusterAppBar({ isOpen, classes, openDrawer }) {
  return (
    <AppBar
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: isOpen
      })}
    >
      <Toolbar disableGutters={!isOpen} className={classes.toolbar}>
        <OnlyAuthenticated>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={openDrawer}
            className={classNames(classes.menuButton, isOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </OnlyAuthenticated>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.grow}
          onClick={navigateRoot}
        >
          Clustertool
        </Typography>
        <FilterButton />
      </Toolbar>
    </AppBar>
  );
}

ClusterAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClusterAppBar);
