import React, { Fragment, useState } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import { ArrowDropDown } from "@material-ui/icons";

const styles = theme => ({
  button: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
    fontSize: 14,
    background: theme.palette.primary.main,
    borderRadius: 100,
    color: theme.palette.primary.contrastText,
    transition: "background-color 0.1s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    },
    "& svg": {
      color: "rgba(255,255,255,.7)"
    }
  },
  label: {
    textTransform: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "auto",
    whiteSpace: "nowrap"
  }
});

function ButtonMenu({ icon, label, children, classes }) {
  const [anchor, setAnchor] = useState();
  function openMenu(e) {
    setAnchor(e.currentTarget);
  }
  function close() {
    setAnchor();
  }
  const items = React.Children.map(children, child => {
    return React.cloneElement(child, {
      onClick: e => {
        child.props.onClick(e);
        close();
      }
    });
  });
  return (
    <Fragment>
      <ButtonBase
        className={classes.button}
        aria-owns={anchor ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={openMenu}
      >
        {icon}
        <span className={classes.label}>{label}</span>
        <ArrowDropDown style={{ marginLeft: 8 }} />
      </ButtonBase>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={close}
      >
        {items}
      </Menu>
    </Fragment>
  );
}

export default withStyles(styles)(ButtonMenu);
