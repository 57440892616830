import React, { useEffect } from "react";
import LoaderPage from "../components/loader-page";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "../firebase";
import head from "lodash/head";
import { useSelector, useDispatch } from "react-redux";
import ImagePage from "../common/image-page";
import CasetteImage from "../assets/insert-casette.jpg";
import CardContent from "@material-ui/core/CardContent";
import { downloadFromCloud } from "../store/results/results-module";
import Typography from "@material-ui/core/Typography";

export default function DokuLoader({ children }) {
  const [value, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("doku-uploads")
      .orderBy("date", "desc")
      .limit(1),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );
  const dispatch = useDispatch();
  const { targetPath, isLoading, isDownloading, hasDownloaded } = useSelector(
    state => ({
      targetPath: state.results.targetPath || "",
      isLoading: state.results.isLoading,
      isDownloading: state.results.isDownloading,
      hasDownloaded: state.results.hasDownloaded
    })
  );

  const latestFile = value && head(value.docs).data();
  const latestFilePath = latestFile && latestFile.filePath;
  const isSame = targetPath === latestFilePath;

  useEffect(() => {
    console.log(
      "isSame",
      isSame,
      "loading",
      loading,
      "error",
      error,
      "isLoading",
      isLoading,
      "isDownloading",
      isDownloading,
      "hasDownloaded",
      hasDownloaded
    );
    if (
      !isSame &&
      !loading &&
      !error &&
      !isDownloading &&
      !hasDownloaded &&
      !isLoading
    ) {
      dispatch(downloadFromCloud(latestFilePath));
    }
  }, [
    isSame,
    loading,
    error,
    isDownloading,
    latestFilePath,
    hasDownloaded,
    isLoading,
    dispatch
  ]);

  if (loading) {
    return <LoaderPage />;
  }
  if (error) {
    console.error(error);
    return children;
  }

  if (isSame && !isLoading && !isDownloading) {
    return children;
  }

  return (
    <ImagePage image={CasetteImage} title="Updating your data" showProgress>
      <CardContent>
        <Typography>
          It seems that your data is not up to date. Let me shortly get the
          newest Data
        </Typography>
      </CardContent>
    </ImagePage>
  );
}
