import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Chip } from "@material-ui/core";
import firebase from "../../firebase";
import { forEach } from "lodash";

export function useClassCount(questionId) {
  const [value, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("questions")
      .doc(questionId)
      .collection("classes"),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  let main = 0;
  let children = 0;
  if (value) {
    value.forEach(d => {
      main++;
      const data = d.data();
      forEach(data.children, c => children++);
    });
  }
  return {
    main,
    total: children,
    loading,
    error
  };
}

export default function QuestionClassCount({ questionId, render }) {
  const { loading, error, main, total } = useClassCount(questionId);
  if (error) {
    console.error(error);
    return null;
  }
  if (loading) {
    return null;
  }

  return (
    <Chip
      label={`Classes defined: ${main} | ${total}`}
      color={total === 0 ? "secondary" : "inherit"}
    />
  );
}
