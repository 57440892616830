import { STATUS } from "../constants";
import { ROLES_LOADED, isClient } from "./user";
import { getUrlState, NAVIGATED_PREVIOUS, NAVIGATED_NEXT } from "./worklist";
import { navigate } from "@reach/router/lib/history";
import isFinite from "lodash/isFinite";
import omit from "lodash/omit";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import createDecorator from "final-form-calculate";

export const filterConditions = state => {
  const filter = state.filter.filter;
  let counter = 0;
  let conditions = [];
  if (filter.status) {
    counter++;
    conditions.push(["status", "==", filter.status]);
  }
  if (filter.classification) {
    counter++;
    conditions.push(["classes", "array-contains", filter.classification]);
  }
  if (filter.agent) {
    counter++;
    conditions.push(["agent", "==", filter.agent]);
  }
  if (filter.batch) {
    counter++;
    conditions.push(["batch", "==", filter.batch]);
  }
  const sentiment = parseInt(filter.sentiment);
  if (isFinite(sentiment)) {
    conditions.push(["sentiment", "==", sentiment]);
  }
  if (filter.language) {
    counter++;
    conditions.push(["language", "==", filter.language]);
  }
  if (filter.bu) {
    counter++;
    conditions.push(["bu", "==", filter.bu]);
  }
  if (counter > 2) {
    console.error("Setting more than 2 Filters is not so optimal");
  }
  return conditions;
};

export const SET_FILTER = "FILTER:SET_FILTER";
export const OPEN_FILTER = "FILTER:OPEN";
export const CLOSE_FILTER = "FILTER:CLOSE";
const RESET_FILTER_PROGRESS = "FILTER:RESET_PROGRESS";
const CLEAR_FILTER = "FILTER:CLEAR_FILTER";

export const setFilter = filter => dispatch => {
  dispatch({
    type: SET_FILTER,
    filter
  });
  const { questionId } = getUrlState();
  navigate(`/classify/${questionId}`);
};

export const resetFilter = () => ({
  type: CLEAR_FILTER
});

export const closeFilter = () => ({
  type: CLOSE_FILTER
});

export const openFilter = () => ({
  type: OPEN_FILTER
});

export const resetFilterProgress = () => ({
  type: RESET_FILTER_PROGRESS
});

const defaultForRole = roles => {
  if (isClient(roles)) {
    return clientDefault;
  }
  return defaultFilter;
};

const defaultFilter = {
  status: STATUS.OPEN,
  classification: false,
  agent: false,
  sentiment: false,
  bu: false,
  batch: false,
  language: false
};
const clientDefault = {
  status: STATUS.DONE
};

const initialState = {
  defaultFilter,
  filter: defaultFilter,
  filterProgress: 1,
  open: false
};

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: action.filter,
        filterProgress: 1,
        open: false
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: state.defaultFilter
      };
    case NAVIGATED_NEXT:
      return {
        ...state,
        filterProgress: state.filterProgress + 1
      };
    case NAVIGATED_PREVIOUS:
      return {
        ...state,
        filterProgress: state.filterProgress - 1
      };
    case RESET_FILTER_PROGRESS: {
      return {
        ...state,
        filterProgress: 1
      };
    }
    case OPEN_FILTER:
      return {
        ...state,
        open: true
      };
    case CLOSE_FILTER:
      return {
        ...state,
        open: false
      };
    case ROLES_LOADED: {
      const newDefault = defaultForRole(action.roles);
      return {
        ...state,
        defaultFilter: newDefault,
        filter: newDefault
      };
    }
    default:
      return state;
  }
}

const allFields = [
  "classification",
  "agent",
  "sentiment",
  "bu",
  "batch",
  "language"
];

const unsetOthers = me => {
  const fieldMap = mapValues(
    omit(keyBy(allFields), me),
    field => (triggerValue, allValues) => {
      if (triggerValue) {
        return false;
      }
      return allValues[field];
    }
  );
  return fieldMap;
};

const notOpenStatus = (trigger, allValues) => {
  if (trigger && allValues.status === STATUS.OPEN) {
    return STATUS.DONE;
  }
  return allValues.status;
};

export const decorator = createDecorator(
  {
    field: "classification",
    updates: {
      ...unsetOthers("classification"),
      status: notOpenStatus
    }
  },
  {
    field: "agent",
    updates: {
      ...unsetOthers("agent"),
      status: notOpenStatus
    }
  },
  {
    field: "sentiment",
    updates: {
      ...unsetOthers("sentiment"),
      status: notOpenStatus
    }
  },
  {
    field: "bu",
    updates: {
      ...unsetOthers("bu")
    }
  },
  {
    field: "language",
    updates: {
      ...unsetOthers("language")
    }
  },
  {
    field: "batch",
    updates: {
      ...unsetOthers("batch")
    }
  }
);
