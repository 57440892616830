import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

import StepContainer from "./step-container";
// import SummaryTable from "./summary-table";
import SimpleTable from "./simple-table";

const SummaryCard = ({
  title,
  subTitle,
  hasData,
  data,
  columns,
  copy,
  stepContext,
  top,
  copyTransform
}) => {
  useNavigateToLoad(hasData);
  const Top = top;
  return (
    <Card>
      <CardHeader title={title} subheader={subTitle} />
      {top && <Top {...stepContext} />}
      {/* <SummaryTable data={data} columns={columns} />
      <h3>Other Table</h3> */}
      <SimpleTable
        data={data}
        columns={columns}
        copyTransform={copyTransform}
      />
    </Card>
  );
};

export function useNavigateToLoad(loaded) {
  useEffect(() => {
    if (!loaded) {
      navigate("/summary/load");
    }
  }, [loaded]);
}

export default StepContainer(SummaryCard);
