import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import chunk from "lodash/chunk";
import { navigate } from "@reach/router";
import { blue } from "@material-ui/core/colors";
import OrgContainer from "./org-container";

import divisionNames from "../store/results/data/divisions";

const ListContainer = styled.div`
  display: flex;
`;

const RowList = styled(List)`
  width: 33%;
`;
const avatarStyles = theme => ({
  root: {
    backgroundColor: blue[50],
    color: "#000"
  }
});
const FlagAvatar = withStyles(avatarStyles)(Avatar);

const OpcoItem = ({ id, title }) => (
  <ListItem button onClick={() => navigate(`/summary/opco/${id}`)}>
    <FlagAvatar>{id}</FlagAvatar>
    <ListItemText primary={id} secondary={title} />
  </ListItem>
);

function OpcoList({ divisions }) {
  const withTitle = Object.keys(divisions)
    .map(d => ({
      id: d,
      title: divisionNames[d]
    }))
    .filter(d => d.title);
  const chunkSize = Math.ceil(withTitle.length / 3);
  const [first, second, third] = chunk(withTitle, chunkSize);
  return (
    <ListContainer>
      <RowList>
        {first.map(op => (
          <OpcoItem key={op.id} id={op.id} title={op.title} />
        ))}
      </RowList>
      <RowList>
        {second.map(op => (
          <OpcoItem key={op.id} id={op.id} title={op.title} />
        ))}
      </RowList>
      <RowList>
        {third.map(op => (
          <OpcoItem key={op.id} id={op.id} title={op.title} />
        ))}
      </RowList>
    </ListContainer>
  );
}

export default OrgContainer(OpcoList);
