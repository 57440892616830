import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Stepper from "./summary-stepper";
import LCSelector from "./lc-selector";
import OpcoSelector from "./opco-selector";
import {
  navigateNextStep,
  navigatePrevStep,
  getUrlParams
} from "../store/results/step-navigation";
import { globalSteps, divSteps } from "../store/results/steps";
import { divOpcoSteps, buOpcoSteps } from "../store/results/opco-steps";
import divisionNames from "../store/results/data/divisions";
import { navigateStep, getUrlContext } from "../store/results/step-navigation";
import OrgContainer from "./org-container";
import map from "lodash/map";
import { navigate } from "@reach/router";

const styles = theme => ({
  paper: {
    height: "100%"
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing.unit * 4
  },
  backlink: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 8
  }
});

function getStepLogic({ byType, opco }, divisions) {
  const divisionsWithNames = Object.keys(divisions)
    .map(div => ({
      id: div,
      title: divisionNames[div]
    }))
    .filter(r => r.title);
  if (byType === "opco") {
    const bus = divisions[opco];
    return {
      mainStepKeys: ["global"].concat(bus),
      globalKeys: divOpcoSteps.map(s => s.id),
      subKeys: buOpcoSteps.map(s => s.id),
      globalSteps: divOpcoSteps,
      subCategory: bus.map(a => ({ id: a, title: a })),
      subSteps: buOpcoSteps
    };
  }
  return {
    mainStepKeys: ["global"].concat(Object.keys(divisions)),
    globalKeys: map(globalSteps, "id"),
    subKeys: map(divSteps, "id"),
    globalSteps: globalSteps,
    subSteps: divSteps,
    subCategory: divisionsWithNames
  };
}

export function SummarySidebar({ classes, divisions }) {
  const urlParams = getUrlParams();
  const { byType, opco } = urlParams;
  const stepLogic = getStepLogic(urlParams, divisions);
  const {
    mainStepKeys,
    globalKeys,
    subKeys,
    globalSteps,
    subCategory,
    subSteps
  } = stepLogic;

  // } else {
  //   currentMainStepKeys = mainStepKeys;
  //   currentGlobalKeys = globalKeys;
  //   currentSubKeys = divKeys;
  //   currentGlobalSteps = globalSteps;
  //   currentSubCategory = divisions;
  //   currentSubSteps = divSteps;
  // }
  return (
    <Paper className={classes.paper}>
      <div className={classes.backlink}>
        <Button onClick={() => navigate("/summary")}>Back to Overview</Button>
      </div>
      {byType === "lc" ? (
        <LCSelector />
      ) : (
        <OpcoSelector divisions={divisions} />
      )}
      <Divider />
      <div className={classes.navigation}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigatePrevStep(mainStepKeys, globalKeys, subKeys)}
        >
          Prev
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigateNextStep(mainStepKeys, globalKeys, subKeys);
          }}
        >
          Next
        </Button>
      </div>
      <Divider />
      <Stepper {...urlParams} {...stepLogic} />
    </Paper>
  );
}

export default withStyles(styles)(OrgContainer(SummarySidebar));
