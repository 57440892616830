import React from "react";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { linkToFirestore } from "../../store/admin";

export const ProjectListPage = ({ projects, children }) => {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} md={4}>
        <Paper>
          <List dense>
            <ListItem
              button
              component="a"
              href={linkToFirestore("projects")}
              target="_blank"
            >
              <Avatar>DB</Avatar>
              <ListItemText primary="Database" />
            </ListItem>
            <Divider />
            {projects.map(p => {
              return (
                <ListItem
                  key={p.id}
                  button
                  onClick={() => navigate(`/admin/projects/${p.id}`)}
                >
                  <ListItemText primary={p.name} />
                </ListItem>
              );
            })}
            <Divider />
            <ListItem button onClick={() => navigate(`/admin/projects/new`)}>
              <ListItemText primary="Create New Project" />
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        {children}
      </Grid>
    </Grid>
  );
};

ProjectListPage.defaultProps = {
  projects: []
};

const ProjectContainer = compose(
  firestoreConnect(["projects"]),
  connect(state => {
    return {
      projects: state.firestore.ordered.projects
    };
  })
);

export default ProjectContainer(ProjectListPage);
