import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { canBacktrack } from "../../store/worklist";

const styles = {
  button: {
    marginLeft: 8
  }
};

export function NavigationActions({
  disabled,
  hasPrevious,
  next,
  previous,
  classes
}) {
  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        className={classes.button}
        disabled={disabled || !hasPrevious}
        onClick={previous}
      >
        Previous
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={disabled}
        className={classes.button}
        onClick={next}
      >
        Next
      </Button>
    </>
  );
}

export default withStyles(styles)(
  connect(state => ({ hasPrevious: canBacktrack(state) }))(NavigationActions)
);
