import React, { memo } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "react-redux-firebase";
import { navigate } from "@reach/router";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ClusterIcon from "@material-ui/icons/TableChart";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PeopleIcon from "@material-ui/icons/People";
import InsertDriveIcon from "@material-ui/icons/InsertDriveFile";
import LogoutIcon from "mdi-material-ui/LogoutVariant";
import CalcIcon from "mdi-material-ui/Calculator";
import FileTreeIcon from "mdi-material-ui/FileTree";

import OnlyAdmin from "./only-admin";
import RequireRole from "./require-role";
import UserAvatar from "./user-avatar";
import { displayName, DOCU_ROLE } from "../store/user";

const styles = theme => ({
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: theme.drawerWidth
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: 14,
    backgroundColor: theme.palette.primary.light,
    fontSize: "1rem"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px 0 24px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
});

const enhance = compose(
  withStyles(styles),
  connect(({ firebase: { auth } }) => ({ auth })),
  withFirebase
);

const SiteNavigation = memo(function({
  isOpen,
  classes,
  closeDrawer,
  firebase,
  auth
}) {
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isOpen}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <ListItem className={classes.drawerHeader}>
        <UserAvatar auth={auth} className={classes.avatar} />
        <ListItemText primary={displayName(auth)} />
        <IconButton onClick={closeDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </ListItem>

      <Divider />
      <List>
        <ListItem button onClick={() => navigate("/")}>
          <ListItemIcon>
            <ClusterIcon />
          </ListItemIcon>
          <ListItemText primary="Cluster" />
        </ListItem>
        <RequireRole role={DOCU_ROLE}>
          <ListItem button onClick={() => navigate("/summary")}>
            <ListItemIcon>
              <InsertDriveIcon />
            </ListItemIcon>
            <ListItemText primary="NPS Docu" />
          </ListItem>
        </RequireRole>
        <OnlyAdmin>
          <ListSubheader>Admin</ListSubheader>
          <ListItem button onClick={() => navigate("/admin/upload")}>
            <ListItemIcon>
              <UploadIcon />
            </ListItemIcon>
            <ListItemText primary="Upload Text" />
          </ListItem>
          <ListItem button onClick={() => navigate("/admin/download")}>
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText primary="Download Text" />
          </ListItem>
          <ListItem button onClick={() => navigate("/summary/load")}>
            <ListItemIcon>
              <UploadIcon />
            </ListItemIcon>
            <ListItemText primary="Upload Doku" />
          </ListItem>
          <ListItem button onClick={() => navigate("/admin/recalc")}>
            <ListItemIcon>
              <CalcIcon />
            </ListItemIcon>
            <ListItemText primary="Recalc Stats" />
          </ListItem>
          <ListItem button onClick={() => navigate("/admin/projects")}>
            <ListItemIcon>
              <FileTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
          <ListItem button onClick={() => navigate("/admin/users")}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="User" />
          </ListItem>
          <Divider />
        </OnlyAdmin>
        <ListItem button onClick={() => firebase.logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
});

export default enhance(SiteNavigation);
