import React, { memo } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MessageBar from "../../components/message-bar";

export function ErrorSnackbar({ open }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
    >
      <MessageBar
        variant="info"
        message="This item is currently being edited by someone else"
      />
    </Snackbar>
  );
}

export default memo(ErrorSnackbar);
