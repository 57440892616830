import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import UploadTaskList from "./upload-tasks-list";
import UploadForm from "./upload-form";

export default function UploadPage() {
  return (
    <>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Upload Texts to Project
          </Typography>
        </CardContent>
        <CardContent>
          <UploadTaskList />
        </CardContent>
        <UploadForm />
      </Card>
    </>
  );
}
