import React from "react";
import { FieldSet } from "../../components/form-layout";
import Group from "@material-ui/icons/Group";
import CheckboxFieldSet from "../../components/checkbox-fieldset";
import UserContainer from "../user-management/user-container";

function MemberForm({ users, edit }) {
  const memberOptions = users.filter(u => !u.disabled).map(u => ({
    label: u.displayName || u.email,
    value: u.uid
  }));
  return (
    <FieldSet icon={<Group />} variant="selection">
      <CheckboxFieldSet
        name="members"
        label="Members"
        options={memberOptions}
      />
    </FieldSet>
  );
}

export default UserContainer(MemberForm);
