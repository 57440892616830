import React from "react";
import { Field } from "react-final-form";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import ListCheckboxField from "../../components/list-checkbox-field";

export function ClassGroup({
  classes,
  title,
  titleStyle,
  subClasses,
  disabled
}) {
  return (
    <Paper style={{ height: "100%" }}>
      <List
        dense
        subheader={<ListSubheader style={titleStyle}>{title}</ListSubheader>}
      >
        {subClasses.map(sub => (
          <Field
            key={sub.id}
            name="classes"
            component={ListCheckboxField}
            type="checkbox"
            subscription={{ value: true }}
            value={sub.id}
            label={sub.name}
            disabled={disabled}
            dense={true}
          />
        ))}
      </List>
    </Paper>
  );
}

export default ClassGroup;
