import React, { Fragment } from "react";
import styled from "styled-components";
import RDropzone from "react-dropzone";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ErrorIcon from "@material-ui/icons/ErrorOutlineOutlined";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import red from "@material-ui/core/colors/red";
import head from "lodash/head";
import _get from "lodash/get";

const SRDropzone = styled(RDropzone)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  > :not(:last-child) {
    margin-top: 8px;
  }
`;

const styles = theme => ({
  dropzone: {
    border: `2px dashed ${theme.palette.grey[500]}`,
    backgroundColor: theme.palette.grey[200],
    "&.active": {
      backgroundColor: theme.palette.grey[300],
      border: `2px dashed ${theme.palette.grey[600]}`
    },
    "&.rejecting": {
      backgroundColor: red[100]
    }
  }
});

const Wait = () => (
  <Fragment>
    <CloudUploadIcon style={{ fontSize: 60 }} />
    <Typography variant="h6" component="h3">
      Upload dataset here
    </Typography>
    <Typography variant="body1" component="p">
      Drag and Drop, or click to select
    </Typography>
  </Fragment>
);

const Accept = () => (
  <Fragment>
    <CloudUploadIcon style={{ fontSize: 60 }} />
    <Typography variant="h6" component="h3">
      ..... and release
    </Typography>
    <Typography variant="body1" component="p">
      Drop your file here to upload
    </Typography>
  </Fragment>
);

const Reject = () => (
  <Fragment>
    <ErrorIcon style={{ fontSize: 60 }} />
    <Typography variant="h6" component="h3">
      This file is invalid
    </Typography>
    <Typography variant="body1" component="p">
      Please upload only a single .csv file
    </Typography>
  </Fragment>
);

const Progressing = ({ progress, fileName }) => (
  <Fragment>
    <CircularProgress size={50} style={{ marginBottom: 8 }} />
    <Typography variant="h6" component="h3" style={{ marginTop: 8 }}>
      Uploading file. {progress && `${Math.round(progress)}%`}
    </Typography>
    <Typography variant="body1" component="p">
      {fileName}
    </Typography>
  </Fragment>
);

const Finished = ({ fileName }) => (
  <Fragment>
    <CheckCircle style={{ fontSize: 60 }} color="primary" />
    <Typography variant="h6" component="h3">
      Upload Successful
    </Typography>
  </Fragment>
);

/**
 * Inspiration: https://dribbble.com/shots/5356083-File-dropzone
 */
function DropZone({
  className,
  classes,
  isLoading,
  progress,
  isFinished,
  ...props
}) {
  return (
    <SRDropzone
      className={[classes.dropzone, className].join(" ")}
      rejectClassName="rejecting"
      activeClassName="active"
      {...props}
    >
      {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
        if (isLoading) {
          return (
            <Progressing
              progress={progress}
              fileName={_get(head(acceptedFiles), "name")}
            />
          );
        }
        if (isDragAccept) {
          return <Accept />;
        }
        if (isDragReject) {
          return <Reject />;
        }
        if (isFinished) {
          return <Finished fileName={_get(head(acceptedFiles), "name")} />;
        }
        return <Wait />;
      }}
    </SRDropzone>
  );
}

export default withStyles(styles)(DropZone);
