import React, { useEffect } from "react";
import { navigate } from "@reach/router";

import { useDispatch } from "react-redux";
import { resetFilterProgress } from "../store/filter";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import BirdsImage from "../assets/birds.jpeg";
import ImagePage from "../common/image-page";
import CoffeeImage from "../assets/coffee-1.jpeg";
import ChangeFilterButton from "./filter/change-filter-button";

export function CompletedPage({ questionId }) {
  const dispatch = useDispatch();
  useEffect(() => {
    console.warn("redux-react-rook");
    dispatch(resetFilterProgress());
  }, [dispatch]);
  return (
    <ImagePage image={CoffeeImage} title="Completed">
      <CardContent>
        <Typography component="p">
          You successfully went through all items in the worklist. <br />
          Its time for a coffee break.
        </Typography>
      </CardContent>

      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => navigate(`/classify/${questionId}`)}
        >
          Go to start
        </Button>
        <ChangeFilterButton />
      </CardActions>
    </ImagePage>
  );
}

export default CompletedPage;
