import { connect } from "react-redux";
import { getStep, getUrlContext } from "../store/results/step-navigation";

const identity = data => data;

const StepContainer = connect((state, props) => {
  const step = getStep();
  if (!step) {
    console.error("NO STEP FOUND");
  }
  const context = getUrlContext();
  let mapped = {
    hasData: !!state.results.structuredData,
    title: step && step.title(context),
    subTitle: step && step.subTitle && step.subTitle(context),
    top: step && step.top,
    stepContext: context,
    data: [],
    columns: []
  };
  if (step.process) {
    mapped.columns = step.process.columns;
    mapped.data = step.process.transform(state.results.structuredData, context);
    mapped.copyTransform = step.process.copyTransform || identity;
  }
  return mapped;
});

export default StepContainer;
