import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton
} from "@material-ui/core";
import {
  ChangeFormatter,
  PercentFormatter,
  NPSFormatter,
  CountFormatter,
  KeyFormatter,
  NumberFormatter,
  CategoryCountFormatter,
  CategoryPercentFormatter,
  DistributionPercentFormatter,
  SKIP
} from "./table-formatter";
import { indigo } from "@material-ui/core/colors";
import { styled, withStyles } from "@material-ui/styles";
import { copyValues } from "../store/results/clipboard";
import CopyIcon from "@material-ui/icons/FileCopy";
import map from "lodash/map";
import head from "lodash/head";
import isArray from "lodash/isArray";

const Value = ({ value }) => (value ? value : null);

const isDebug = process.env.NODE_ENV === "development";

const CopyButton = withStyles({
  root: {
    marginLeft: 8,
    padding: 0,
    height: 18,
    width: 18,
    "& svg": {
      height: 16,
      width: 16
    }
  }
})(({ onClick, classes }) => (
  <IconButton className={classes.root} onClick={onClick}>
    <CopyIcon color="primary" />
  </IconButton>
));

function getFormatter(column) {
  if (column.group) {
    return KeyFormatter;
  }
  switch (column.name) {
    case "nps":
    case "identical_nps":
    case "last_nps":
      return NPSFormatter;
    case "count":
    case "total_count":
    case "last_count":
    case "identical_count":
    case "category_count":
      return CountFormatter;
    case "category_percent":
      return PercentFormatter;
    case "promoter_percent":
    case "passive_percent":
    case "detractor_percent":
      return DistributionPercentFormatter;

    case "change":
    case "identical_change":
      return ChangeFormatter;
    case "promoter":
    case "passive":
    case "detractor":
      return NumberFormatter;
    case "current_category_count":
    case "last_category_count":
      return CategoryCountFormatter;
    case "current_percent":
    case "last_percent":
      return CategoryPercentFormatter;
    default:
      return Value;
  }
}

function Header({ columns, onCopy }) {
  return (
    <TableHead>
      {columns.map(c => (
        <HeaderCell key={c.name} onCopy={() => onCopy(c)}>
          {c.title}
        </HeaderCell>
      ))}
      {isDebug && <TableCell>ID</TableCell>}
    </TableHead>
  );
}

function HeaderCell({ onCopy, children, ...props }) {
  return (
    <TableCell {...props} style={{ paddingTop: 16, paddingBottom: 16 }}>
      {children}
      <CopyButton onClick={onCopy} />
    </TableCell>
  );
}

function getKey(d, columns) {
  let primary;
  const potentialKeysColumns = columns.filter(c => c.group || c.key);
  const potentialKeys = potentialKeysColumns.map(k => d[k.name]).filter(a => a);
  primary = potentialKeys[0];
  if (!primary) {
    primary = d["lc"] || d["div"] || d["bu"];
  }

  if (!primary) {
    console.log("WARN I COULD NOT FIGURE OUT THE KEY HERE", columns);
    if (isDebug) {
      throw new Error("This can lead to problems later");
    }
  }
  const secondary = d["year"];
  if (!secondary) {
    return primary;
  }
  return `${primary}_${secondary}`;
}

function Body({ data, columns }) {
  return (
    <TableBody>
      {data.map(d => (
        <Row
          row={d}
          key={getKey(d, columns)}
          columns={columns}
          identifier={getKey(d, columns)}
        />
      ))}
    </TableBody>
  );
}

function Row({ row, columns, identifier }) {
  return (
    <TableRow>
      {columns.map(c => (
        <Cell row={row} value={row[c.name]} column={c} columns={columns} />
      ))}
      {isDebug && <TableCell>"{identifier}"</TableCell>}
    </TableRow>
  );
}

function Cell({ value, row, column, columns }) {
  const Formatter = getFormatter(column);
  return (
    <TableCell>
      <Formatter value={value} row={row} column={column} columns={columns} />
    </TableCell>
  );
}

const StripedTable = styled(Table)({
  "& tbody tr:nth-of-type(odd)": {
    backgroundColor: indigo[50]
  }
});

function copy(data, column, columns, copyTransform) {
  const formatter = getFormatter({ name: column.name });
  const values = map(data, row => {
    return formatter({
      value: row[column.name],
      row,
      column,
      raw: true,
      columns
    });
  });
  const transformed = copyTransform ? copyTransform(values, column) : values;
  const cleaned = transformed.filter(a => a !== SKIP);
  copyValues(cleaned);
}

export default function SimpleTable({ data, columns, copyTransform }) {
  const onCopy = column => copy(data, column, columns, copyTransform);
  const first = head(data);
  if (isArray(first)) {
    return data.map(group => <SimpleTable data={group} columns={columns} />);
  }
  return (
    <StripedTable>
      <Header columns={columns} onCopy={onCopy} />
      <Body data={data} columns={columns} />
    </StripedTable>
  );
}
