import React from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import map from "lodash/map";
import _get from "lodash/get";

import SelectField from "../../components/select-field";

import { STATUS, STATUS_UI } from "../../constants";

const enhance = connect((state, props) => {
  const byStatus = _get(
    state.firestore,
    `data.questions.${props.questionId}.byStatus`
  );
  return {
    byStatus
  };
});

export function StatusDropdown({ byStatus }) {
  return (
    <Field
      name="status"
      label="Status"
      component={SelectField}
      controlProps={{ fullWidth: true }}
      renderValue={v => {
        if (!v) {
          return "All";
        }
        const ui = STATUS_UI[v];
        return ui.label;
      }}
    >
      <MenuItem value={false}>
        <ListItemText inset primary="All" />
      </MenuItem>
      {map(STATUS, key => {
        const ui = STATUS_UI[key];
        return (
          <MenuItem value={key} key={key}>
            <Avatar style={{ backgroundColor: ui.color }}>{ui.label[0]}</Avatar>
            <ListItemText
              inset
              primary={ui.label}
              secondary={`${_get(byStatus, key, 0)} comments`}
            />
          </MenuItem>
        );
      })}
    </Field>
  );
}

export default enhance(StatusDropdown);
