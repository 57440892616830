import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ReactCountryFlag from "react-country-flag";
import chunk from "lodash/chunk";
import sortBy from "lodash/sortBy";
import { navigate } from "@reach/router";
import { blue } from "@material-ui/core/colors";

import leadCountries from "../store/results/data/lead-countries";

const ListContainer = styled.div`
  display: flex;
`;

const RowList = styled(List)`
  width: 33%;
`;
const avatarStyles = theme => ({
  root: {
    backgroundColor: blue[50]
  }
});
const FlagAvatar = withStyles(avatarStyles)(Avatar);

const CountryItem = ({ id, code, title }) => (
  <ListItem button onClick={() => navigate(`/summary/lc/${id}`)}>
    <FlagAvatar>
      <ReactCountryFlag code={code} />
    </FlagAvatar>
    <ListItemText primary={id} secondary={title} />
  </ListItem>
);

function LeadCountryList({ classes }) {
  const chunkSize = Math.ceil(leadCountries.length / 3);
  const sorted = sortBy(leadCountries, "title");
  const [first, second, third] = chunk(sorted, chunkSize);
  return (
    <ListContainer>
      <RowList>
        {first.map(lc => (
          <CountryItem key={lc.id} id={lc.id} code={lc.code} title={lc.title} />
        ))}
      </RowList>
      <RowList>
        {second.map(lc => (
          <CountryItem key={lc.id} id={lc.id} code={lc.code} title={lc.title} />
        ))}
      </RowList>
      <RowList>
        {third.map(lc => (
          <CountryItem key={lc.id} id={lc.id} code={lc.code} title={lc.title} />
        ))}
      </RowList>
    </ListContainer>
  );
}

export default LeadCountryList;
