import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import _Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import _CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ClosedImage from "../assets/closed-door.jpg";
import { admin } from "../config";

const Centered = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;

const Card = styled(_Card)`
  max-width: 460px;
`;

const CardMedia = styled(_CardMedia)`
  height: 230px;
`;

export function NoAccessPagePage() {
  return (
    <Centered>
      <Card>
        <CardMedia image={ClosedImage} title="Closed Door" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Permission Denied
          </Typography>
          <Typography component="p">
            You don't have sufficient permissions to access this Project. If you
            think this is incorrect, please contact the administrator. <br />
            <b>{admin.adminContact}</b>
          </Typography>
        </CardContent>

        <CardActions>
          <Button size="small" color="primary" onClick={() => navigate(`/`)}>
            Back to Projects
          </Button>
        </CardActions>
      </Card>
    </Centered>
  );
}

export default NoAccessPagePage;
