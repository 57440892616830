import { useEffect, useCallback } from "react";
import get from "lodash/get";

import { useSelector } from "react-redux";

export default function useElevProject(questionId) {
  // Declare your memoized mapState function

  // Get data from and subscribe to the store
  const { elevGroup } = useSelector(state => {
    const projects = state.firestore.ordered.projects || [];
    const project = projects.find(p =>
      p.questions.find(q => q.link && q.link.id === questionId)
    );
    const elevGroup = get(project, "elev_group", "default");
    return {
      elevGroup
    };
  });
  useEffect(() => {
    console.log("SET ELEV GROUP HERE", elevGroup);
    // if (_elev && elevGroup) {
    //   const elev_user = {
    //     groups: [elevGroup]
    //   };
    //   _elev.changeUser(elev_user);
    // }
  }, [elevGroup]);
}
