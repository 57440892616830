import React from "react";
import styled from "styled-components";
import { green, red } from "@material-ui/core/colors";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import isFinite from "lodash/isFinite";
import round from "lodash/round";
import filter from "lodash/filter";
import map from "lodash/map";
import some from "lodash/some";
import isUndefined from "lodash/isUndefined";
import isString from "lodash/isString";

export const SKIP = Symbol("skip");

const percentDecimal = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});

function formatNumber(num) {
  if (!isFinite(num)) {
    return null;
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

export const NumberFormatter = ({ value }) => formatNumber(value);

export const CategoryPercentFormatter = ({ value, row, column }) => {
  const countColumn =
    column.name === "current_percent"
      ? "current_category_count"
      : "last_category_count";
  const count = row[countColumn];
  if (!isFinite(count) || count === 0) {
    return "-";
  }
  return isFinite(value) ? Math.round(value * 100) + "%" : "-";
};

export const CategoryCountFormatter = ({ value }) => {
  if (!isFinite(value) || value === 0) {
    return "-";
  }
  return formatNumber(value);
};

export const CountFormatter = ({ value, row }) => {
  if (!isFinite(value) || value === 0) {
    return "-";
  }
  if (value <= 5) {
    return "≤5";
  }
  return formatNumber(value);
};

function getCountColumn(npsColumn) {
  switch (npsColumn) {
    case "last_nps":
      return "last_count";
    case "identical_nps":
      return "identical_count";
    default:
      return "count";
  }
}

export const NPSFormatter = ({ value, row, column }) => {
  const countColumn = getCountColumn(column.name);
  const count = row[countColumn];
  if (!count || count <= 5 || count === "-") {
    return "-";
  }
  if (isFinite(value)) {
    return round(value);
  }
  if (isString(value)) {
    return value;
  }
  return "-";
};

const ColorCoded = styled.span`
  color: ${p =>
    p.value > 0 ? green[500] : p.value < 0 ? red[500] : "inherit"};
  ${({ value }) => !value && `padding-left: 24px`};
  display: flex;
  align-items: center;
`;

function getCountColumns(columns) {
  const relevant = filter(map(columns, "name"), c => c.indexOf("count") > -1);
  return relevant;
}

function hasCountUnderFive(row, columns) {
  const relevant = getCountColumns(columns);

  const mappedUnder = map(
    relevant,
    c => (isFinite(row[c]) && row[c] <= 5) || row[c] === "-"
  );
  const hasUnder = some(mappedUnder);
  return hasUnder;
  // const countUnder = isFinite(row["count"]) && row["count"] < 5;
  // const identicalCountUnder =
  //   isFinite(row["identical_count"]) && row["identical_count"] < 5;
  // const lastCountUnder = isFinite(row["last_count"]) && row["last_count"] < 5;
  // return countUnder || identicalCountUnder || lastCountUnder;
}

function hasBlockedCount(row, columns) {
  const relevant = getCountColumns(columns);

  const mappedValues = map(relevant, c => row[c]);
  const hasBlocked = some(mappedValues, c => c === "-");
  return hasBlocked;
}

export const ChangeFormatter = ({ value, row, raw, columns }) => {
  if (isUndefined(value) || value === -100 || isNaN(value)) {
    return "-";
  }
  if (hasCountUnderFive(row, columns) || hasBlockedCount(row, columns)) {
    return "-";
  }
  if (raw) {
    if (!isFinite(value)) {
      return SKIP;
    }
    return value > 0 ? `+${round(value)}` : round(value);
  }

  const isPositive = value > 0;
  const isNegative = value < 0;
  return (
    <ColorCoded value={value}>
      {isPositive && <ArrowDropUp />}
      {isNegative && <ArrowDropDown />}
      {isPositive ? `+${round(value)}` : round(value)}
    </ColorCoded>
  );
};

export const PercentFormatter = ({ value, row, columns }) => {
  if (hasCountUnderFive(row, columns)) {
    return "-";
  }
  return isFinite(value) ? Math.round(value * 100) + "%" : "";
};

export const DistributionPercentFormatter = ({ value, row, columns }) => {
  if (hasCountUnderFive(row, columns)) {
    return "-";
  }
  if (isUndefined(value)) {
    return "-";
  }
  return isFinite(value) ? formatPercentWithDecimal(value * 100) + "%" : "";
};

function formatPercentWithDecimal(value) {
  const number = percentDecimal.format(value);
  return number;
}

export const KeyFormatter = ({ value, row, raw }) => {
  if (row.skip) {
    return `${value}*`;
  }
  if (raw) {
    return value || null;
  }
  if (row.summary) {
    return <span style={{ fontWeight: "bold" }}>{value}</span>;
  }
  return value || null;
};
