import React from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import TableCell from "@material-ui/core/TableCell";
import { calculateStats } from "../../store/stats";

const QuestionStatusStats = ({
  total,
  open,
  done,
  percent,
  problem,
  check
}) => {
  return (
    <>
      <TableCell align="right">{total}</TableCell>
      <TableCell align="right">{open}</TableCell>
      <TableCell align="right">{done}</TableCell>
      <TableCell align="right">{percent}</TableCell>
      <TableCell align="right">{problem}</TableCell>
      <TableCell align="right">{check}</TableCell>
    </>
  );
};

export default connect((state, props) => {
  const byStatus = _get(
    state,
    `firestore.data.questions.${props.questionId}.byStatus`,
    {}
  );
  return {
    ...calculateStats(byStatus)
  };
})(QuestionStatusStats);
