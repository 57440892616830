import React from "react";
import { Field } from "react-final-form";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import map from "lodash/map";
import filter from "lodash/filter";
import _get from "lodash/get";

import SelectField from "../../components/select-field";

const SENTIMENT = {
  [-1]: "Negative",
  0: "Neutral",
  1: "Positive"
};

const enhance = connect((state, props) => {
  const bySentiment = _get(
    state.firestore,
    `data.questions.${props.questionId}.bySentiment`
  );
  return {
    sentiments: filter(
      map(bySentiment, (count, key) => ({
        id: key,
        label: SENTIMENT[key],
        count
      })),
      option => option.id && option.id !== "undefined"
    )
  };
});

export function SentimentDropdown({ sentiments }) {
  return (
    <Field
      name="sentiment"
      label="Sentiment"
      component={SelectField}
      controlProps={{ fullWidth: true }}
    >
      <MenuItem value={false}>
        <ListItemText primary="All" />
      </MenuItem>
      {map(sentiments, option => {
        return (
          <MenuItem value={option.id} key={option.id}>
            <ListItemText
              primary={option.label}
              secondary={`${option.count} comments`}
            />
          </MenuItem>
        );
      })}
    </Field>
  );
}

export default enhance(SentimentDropdown);
