import React from "react";
import styled from "styled-components";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import Button from "@material-ui/core/Button";

import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import QuestionClassCount from "./question-class-count";

import { FieldSet, SectionTitle } from "../../components/form-layout";

import renderTextField from "../../components/textfield-field";
import renderCheckbox from "../../components/single-checkbox-field";
import CopyClassification from "./copy-classification";
import Divider from "@material-ui/core/Divider";
import size from "lodash/size";

const Actions = styled.div`
  > :not(:last-child) {
    margin-right: 12px;
  }
`;

function QuestionForm({ projects, edit }) {
  return (
    <>
      <SectionTitle>Questions</SectionTitle>
      <FieldArray name="questions">
        {({ fields }) => {
          return (
            <>
              {fields.map((name, i) => (
                <>
                  <FieldSet icon={<QuestionAnswer />}>
                    <Field
                      label="Name"
                      name={`${name}.name`}
                      component={renderTextField}
                      fullWidth
                    />
                    <Field
                      label="Link"
                      name={`${name}.link`}
                      component={renderTextField}
                      fullWidth
                      disabled
                    />

                    <Field
                      type="checkbox"
                      name={`${name}.showStats`}
                      component={renderCheckbox}
                      label="Show Stats on Dashboard"
                      size={12}
                    />
                    <Field name={`${name}.link`}>
                      {({ input: { value } }) => {
                        if (value) {
                          return <QuestionClassCount questionId={value} />;
                        }
                        return null;
                      }}
                    </Field>
                    <Actions>
                      {edit && (
                        <Field
                          subscription={{ value: true }}
                          name={`${name}.link`}
                        >
                          {({ input: { value } }) => (
                            <CopyClassification
                              projects={projects}
                              questionId={value}
                            />
                          )}
                        </Field>
                      )}
                      <Button
                        variant="contained"
                        onClick={() => fields.remove(i)}
                      >
                        Delete
                      </Button>
                    </Actions>
                  </FieldSet>
                  {i < size(fields) - 1 && (
                    <Divider
                      style={{
                        marginLeft: 48,
                        marginTop: 24,
                        marginBottom: 24
                      }}
                    />
                  )}
                </>
              ))}

              <Button
                variant="contained"
                onClick={() => fields.push({ name: "" })}
              >
                Add Question
              </Button>
            </>
          );
        }}
      </FieldArray>
    </>
  );
}

export default QuestionForm;
