import React, { Fragment } from "react";
import Dropzone from "../dropzone";
import _get from "lodash/get";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Attachement from "@material-ui/icons/Attachment";

export default function DropZoneField(fieldProps) {
  const {
    label,
    input: { value, onChange, ...restInput },
    meta: { touched, error, submitError }
  } = fieldProps;
  const fileName = _get(value, "0.name");
  const e = touched && (error || submitError);
  return (
    <Fragment>
      <FormControl error={e}>
        <InputLabel>{label}</InputLabel>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Attachement />
            </InputAdornment>
          }
          disabled
          value={fileName}
          {...restInput}
        />
        <FormHelperText>{e}</FormHelperText>
      </FormControl>
      <div style={{ paddingTop: 8 }}>
        <Dropzone
          onDrop={(files, e) => {
            onChange(files);
          }}
        />
      </div>
    </Fragment>
  );
}
