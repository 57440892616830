import React from "react";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { ThemeProvider } from "styled-components";
import { createFirestoreInstance } from "redux-firestore";
import theme from "./theme";

import Routes from "./routes";
import store from "./store";
import { loadRoles } from "./store/user";
import firebase from "./firebase";

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  onAuthStateChanged: async (authData, firebase, dispatch) => {
    if (!authData) {
      return;
    }
    const user = firebase.auth().currentUser;
    const idTokenResult = await user.getIdTokenResult(true);
    dispatch(loadRoles(idTokenResult.claims)); // some custom action
  }
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

export const WithProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          {children}
        </ReactReduxFirebaseProvider>
      </Provider>
    </ThemeProvider>
  </MuiThemeProvider>
);

const App = () => (
  <WithProvider>
    <Routes />
  </WithProvider>
);

export default App;
