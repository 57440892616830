import React from "react";
import { connect } from "react-redux";
import pickBy from "lodash/pickBy";
import size from "lodash/size";
import _get from "lodash/get";
import isEqual from "lodash/isEqual";
import Chip from "@material-ui/core/Chip";

import { STATUS } from "../../constants";

function getTotal(filter, questionData) {
  if (filter.status) {
    return _get(questionData, `byStatus.${filter.status}`, 0);
  } else if (filter.classification) {
    return _get(questionData, `byClass.${filter.classification}`, 0);
  } else if (filter.sentiment) {
    return _get(questionData, `bySentiment.${filter.sentiment}`, 0);
  } else if (filter.language) {
    return _get(questionData, `byLanguage.${filter.language}`, 0);
  } else if (filter.bu) {
    return _get(questionData, `byBU.${filter.bu}`, 0);
  } else if (filter.batch) {
    return _get(questionData, `byBatch.${filter.batch}`, 0);
  } else {
    console.warn("havent configured filter progress selection");
  }
}

const enhance = connect((state, props) => {
  // THis would also show it for an agent.....
  // But due to the delay probably not optimal
  // const defaultFilter = pickBy(state.filter.defaultFilter);

  const defaultFilter = state.filter.defaultFilter;

  const filter = pickBy(state.filter.filter);
  const statusFilter = _get(filter, "status", "__OTHER__");
  const questionData = _get(
    state.firestore,
    `data.questions.${props.questionId}`,
    {}
  );
  const total = getTotal(filter, questionData);

  const showTotal = isEqual(filter, defaultFilter);
  // console.log(filter, defaultFilter);
  const show = size(filter) === 1 && statusFilter !== STATUS.OPEN && total;

  return {
    current: state.filter.filterProgress,
    show,
    total,
    showTotal
  };
});

export function FilterProgress({ current, total, show, showTotal }) {
  if (showTotal) {
    return <Chip label={total} />;
  }
  if (!show) {
    return null;
  }
  return <Chip label={`${current}/${total}`} />;
}

export default enhance(FilterProgress);
