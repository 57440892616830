import React from "react";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import DownloadForm from "./download-form";
import DownloadTaskList from "./download-task-list";

const Spacer = styled.div`
  height: 30px;
`;

export default function DownloadPage() {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Download Question Classification
        </Typography>
      </CardContent>
      <DownloadTaskList />
      <Spacer />
      <CardContent>
        <Typography variant="h6" component="h2">
          Generate new File
        </Typography>
      </CardContent>
      <DownloadForm />
    </Card>
  );
}
