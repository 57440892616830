import React from "react";
import { Field } from "react-final-form";
// import { Select } from "final-form-material-ui";
import Select from "../../components/select-field";
import ListSubheader from "@material-ui/core/ListSubheader";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import flatMap from "lodash/flatMap";
import { useClassCount } from "../project-admin/question-class-count";

function ClassCountChip({ questionId }) {
  const { total, loading, error } = useClassCount(questionId);
  if (loading || error) {
    return null;
  }
  return <Chip label={total} />;
}

export default function QuestionSelect({ projects }) {
  return (
    <Field
      component={Select}
      label="Question"
      name="question"
      style={{ width: 350 }}
      required
      validate={v => !v}
    >
      {flatMap(projects, p =>
        [<ListSubheader key={p.id}>{p.name}</ListSubheader>].concat(
          p.questions.map(q => (
            <MenuItem key={q.link.id} value={q.link}>
              {q.name}
              <ClassCountChip questionId={q.link.id} />
            </MenuItem>
          ))
        )
      )}
    </Field>
  );
}
