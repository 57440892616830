import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { getUser } from "../../store/user";
import {
  getText,
  next,
  previous,
  recordHistory,
  clearHistory,
  updateText
} from "../../store/worklist";

const enhance = compose(
  connect(
    (state, props) => {
      const { textId } = props;
      const item = getText(state, textId);
      const user = getUser(state);

      return {
        item,
        user
      };
    },
    { next, previous, clearHistory, recordHistory, updateText },
    (stateProps, dispatchProps, ownProps) => {
      const { questionId, textId } = ownProps;
      const { item, user } = stateProps;
      const { updateText } = dispatchProps;

      const save = values => updateText(questionId, textId, item, user, values);

      return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        save
      };
    }
  ),
  withFirestore,
  lifecycle({
    componentDidMount() {
      const {
        item,
        clearHistory,
        recordHistory,
        questionId,
        textId,
        firestore
      } = this.props;
      clearHistory();
      recordHistory(textId);
      if (!item) {
        const path = `questions/${questionId}/texts/${textId}`;
        firestore.get(path);
      }
    }
  })
);

export default enhance;
