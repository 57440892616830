import React from "react";
import { connect } from "react-redux";
import { closeFilter, openFilter } from "../../store/filter";
import { Match } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import FilterList from "@material-ui/icons/FilterList";
import FilterModal from "./filter-modal";

export const FilterContainer = connect(
  state => ({
    isOpen: state.filter.open
  }),
  { openFilter, closeFilter }
);

const FilterButton = FilterContainer(
  ({ openFilter, isOpen, closeFilter, questionId }) => {
    return (
      <>
        <IconButton color="inherit" onClick={openFilter}>
          <FilterList />
        </IconButton>
        <FilterModal
          isOpen={isOpen}
          questionId={questionId}
          close={closeFilter}
        />
      </>
    );
  }
);

export default function ConditionalFilterButton() {
  return (
    <Match path="classify/:questionId/*">
      {props => {
        return props.match ? (
          <FilterButton questionId={props.match.questionId} />
        ) : null;
      }}
    </Match>
  );
}
