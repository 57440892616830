import { createStore, compose, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import rootReducer from "./root";
// import firebase from "../firebase";
// import { reduxFirestore } from "redux-firestore";
// import { reactReduxFirebase } from "react-redux-firebase";

export default function configureStore(initialState, history) {
  const createStoreWithMiddleware = compose(
    applyMiddleware(ReduxThunk),
    // reactReduxFirebase(firebase, rrfConfig),
    // reduxFirestore(firebase),
    typeof window === "object" &&
      typeof window.devToolsExtension !== "undefined"
      ? window.devToolsExtension()
      : f => f
  )(createStore);
  const store = createStoreWithMiddleware(rootReducer);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./root", () => {
      const nextRootReducer = require("./root");
      store.replaceReducer(nextRootReducer);
    });
  }
  window.store = store;
  return store;
}
