import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from "@material-ui/core/CardActions";
import LoadingButton from "../components/loading-button";
import MessageBar from "../components/message-bar";
import flatMap from "lodash/flatMap";

import ProjectsContainer from "./upload/projects-container";
import { linkToFunctionsLog } from "../store/admin";
import { recalcStats } from "../firebase";
import sum from "lodash/sum";
import map from "lodash/map";

const RecalcPage = ({ projects }) => {
  const [processing, setProcessing] = useState();
  const [question, setQuestion] = useState();
  const [resultCount, setResultCount] = useState();
  const [error, setError] = useState();

  const recalculateQuestion = async () => {
    try {
      setProcessing(true);
      setError(false);
      const r = await recalcStats({ questionId: question });
      const { data } = r;
      // Just to not have to put it on the screen.
      console.log(data);
      const count = sum(map(data.byStatus, i => i));
      setResultCount(count);
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
      setError(e.message || true);
    }
  };

  const missing = flatMap(projects, "questions").filter(q => !q.link);

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Recalculate Stats for Questions
        </Typography>
      </CardContent>
      {missing.length > 0 && (
        <CardContent>
          <MessageBar
            variant="warning"
            message={
              <div>
                <b>Following Questions have no Link</b>
                {missing.map(q => (
                  <li key={q.name}>{q.name}</li>
                ))}
              </div>
            }
          />
        </CardContent>
      )}
      {error && (
        <CardContent>
          <h3>Error</h3>
          <a
            href={linkToFunctionsLog()}
            target="_blank"
            rel="noopener noreferrer"
          >
            Logs
          </a>
        </CardContent>
      )}
      {resultCount && (
        <CardContent>
          <div>Updated {resultCount} records</div>
        </CardContent>
      )}
      <CardContent>
        <Select onChange={e => setQuestion(e.target.value)} value={question}>
          {flatMap(projects, p =>
            [<ListSubheader key={p.id}>{p.name}</ListSubheader>].concat(
              p.questions.filter(q => q.link).map(q => {
                const id = q.link.id;
                return (
                  <MenuItem inset key={id} value={id}>
                    {q.name}
                  </MenuItem>
                );
              })
            )
          )}
        </Select>
      </CardContent>
      <CardActions>
        <LoadingButton
          variant="contained"
          size="small"
          color="primary"
          onClick={recalculateQuestion}
          disabled={!question || processing}
          loading={processing}
        >
          Recalc
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default ProjectsContainer(RecalcPage);
