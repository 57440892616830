import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const selectedColor = "secondary";

const styles = theme => ({
  outlinedSecondary: {
    "&$disabled": {
      color: theme.palette[selectedColor].light,
      borderColor: theme.palette[selectedColor].light
    }
  },
  disabled: {}
});

export function StateButton({ selected, disabled, ...props }) {
  const variant = disabled ? "outlined" : "contained";
  const color = selected ? selectedColor : null;
  return (
    <Button variant={variant} color={color} disabled={disabled} {...props} />
  );
}

export default withStyles(styles)(StateButton);
