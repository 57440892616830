import React from "react";
import { Field } from "react-final-form";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import renderCheckbox from "../single-checkbox-field";

export default function({ label, name, options, ...rest }) {
  // TODO: Implement Error Handling better
  const e = false;
  return (
    <FormControl error={e} component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      {options.map(o => {
        return (
          <FormControlLabel
            control={
              <Field
                name={name}
                type="checkbox"
                value={o.value}
                component={renderCheckbox}
              />
            }
            label={o.label}
          />
        );
      })}

      {e && <FormHelperText>{e}</FormHelperText>}
    </FormControl>
  );
}
