import divisions from "./data/divisions";
import SubCategorySelect from "../../summary/steps/category-select";
import {
  // npsByCountry,
  // npsByDivision,
  // npsByDivisionSplitYears,
  // npsByDivisionSplitYearsIdentical
  selectBase,
  selectFromAllLC,
  sumByGroup,
  filter,
  addSummaryRows,
  viewSplitYear,
  viewSplitYearColumns,
  viewIdenticalDevelopment,
  viewSplitYearColumnsIdentical,
  viewDistributionColumns,
  viewCombineYears,
  viewCombineYearsColumns,
  viewCategoryCombineYearsColumns,
  translateCountry,
  sumGlobalCategory,
  sumSubCategory,
  sumIdentical,
  viewColumnsIdenticalDevelopment,
  fillMissingYear,
  fillMissingCountries,
  fillMissingDivs,
  fillMissingBUs
} from "./results-calculation";
import edgeCase from "./edge-cases";
import { translateLC } from "./data/lead-countries";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";

const tLC = lc => `LC ${translateLC(lc)}`;
const makeCopyChange = name => (data, column) => {
  if (column.name === name) {
    const cleaned = filter(data, (val, index) => {
      const filterOut = val === "-" && index % 2 === 1;
      return !filterOut;
    });
    return cleaned;
  }
  return data;
};

export const globalSteps = [
  {
    id: "global_byCountry",
    title: ({ year }) => `NPS ${year} by assigned country`,
    shortTitle: () => "NPS by country",
    process: {
      transform: (state, { lc }) => {
        const base = selectBase(state, lc, "byCountry");
        const data = sumByGroup(base, "country");
        const withHeader = addSummaryRows(data, {
          country: tLC(lc)
        });
        const result = viewCombineYears(withHeader, "country").map(
          translateCountry
        );
        const [head, ...rest] = result;
        const withMissingCountries = fillMissingCountries(rest, lc);
        return [head].concat(sortBy(withMissingCountries, "country"));
      },
      columns: viewCombineYearsColumns("country", "Country")
    }
  },
  {
    id: "global_byDivision",
    title: ({ year }) => `NPS ${year} by Division`,
    shortTitle: () => "NPS by Division",
    process: {
      transform: (state, { lc }) => {
        const base = selectBase(state, lc, "byDivBU");
        const data = sumByGroup(base, "div");
        // const withMissing = fillMissingDivs(data);
        const withHeader = addSummaryRows(data, { div: tLC(lc) });

        const combined = viewCombineYears(withHeader, "div");
        const withMissing = fillMissingDivs(combined);
        return edgeCase("global_byDivision", withMissing);
      },
      columns: viewCombineYearsColumns("div", "Division")
    }
  },
  {
    id: "global_byDivision_all",
    title: ({ year }) =>
      `NPS of customers interviewed in ${year -
        1} and ${year}: all participants `,
    shortTitle: () => "NPS by Division for all",
    process: {
      transform: (state, { lc }) => {
        const base = selectBase(state, lc, "byDivBU");
        const data = sumByGroup(base, "div");
        const withHeader = addSummaryRows(data, { div: tLC(lc) });
        const splitted = viewSplitYear(withHeader, "div", true);
        const withMissing = fillMissingDivs(splitted);
        return edgeCase("global_byDivision_all", withMissing);
      },
      columns: viewSplitYearColumns("div", "Division"),
      copyTransform: makeCopyChange("change")
    }
  },
  {
    id: "global_byDivision_identical",
    title: ({ year }) =>
      `NPS of customers interviewed in ${year -
        1} and ${year}: identical participants `,
    shortTitle: () => "NPS by Division for identical",
    process: {
      transform: (state, { lc }) => {
        const base = selectBase(state, lc, "byDivBU");
        const data = sumByGroup(base, "div");
        const withMissing = fillMissingDivs(data);
        const withHeader = addSummaryRows(withMissing, { div: tLC(lc) });

        return edgeCase(
          "global_byDivision_identical",
          viewSplitYear(withHeader, "div", true)
        );
      },
      copyTransform: makeCopyChange("identical_change"),
      columns: viewSplitYearColumnsIdentical("div", "Division")
    }
  },
  {
    id: "global_development_identical",
    title: ({ year }) =>
      `NPS Development of identical customers ${year - 1} and ${year}`,
    shortTitle: () => "NPS Development identical",
    process: {
      transform: (state, { lc }) => {
        const base = selectBase(state, lc, "byDivBU");
        const sum = sumIdentical(base);
        const aggregated = viewIdenticalDevelopment(sum);
        return aggregated;
      },
      columns: viewColumnsIdenticalDevelopment()
    }
  },
  {
    id: "global_improvement",
    title: ({ year }) =>
      `Improvement Areas: Development ${year - 1}  – ${year}`,
    shortTitle: () => "Improvement Development",
    process: {
      transform: (state, { lc }) => {
        const base = selectBase(state, lc, "byDivArea");
        const data = sumGlobalCategory(base);
        return data;
      },
      columns: viewCategoryCombineYearsColumns()
    }
  },
  {
    id: "global_improvement_details",
    title: ({ year, mainCategory }) =>
      `Improvement Areas: Development ${year - 1}  – ${year} TOP -> ${
        mainCategory ? mainCategory : ""
      }`,
    shortTitle: () => "Improvement Development Details",
    process: {
      transform: (state, { lc, main, mainCategory }) => {
        const base = selectBase(state, lc, "byDivArea");
        const data = sumSubCategory(base, mainCategory);
        return data;
      },
      columns: viewCategoryCombineYearsColumns()
    },
    top: SubCategorySelect
  },
  // {
  //   id: "global_competitor_better",
  //   title: ({ year }) =>
  //     `All and top 3 competitors NPS Development ${year - 1}  – ${year}`,
  //   subTitle: () => `Where is the competitor better than us?`,
  //   shortTitle: () => "All Competitors",
  //   process: {
  //     transform: (state, { lc, div }) => {
  //       const base = selectBase(state, lc, "byDivArea");
  //       const data = sumGlobalCategory(base, true);
  //       return data;
  //     },
  //     columns: viewCategorySplitYearsColumns()
  //   }
  // },
  // {
  //   id: "global_competitor_top",
  //   title: ({ year }) =>
  //     `All and top 3 competitors NPS Development ${year - 1}  – ${year}`,
  //   subTitle: () => `NPS Score – All and top 3 competitors`,
  //   shortTitle: () => "Top Competitors",

  //   process: {
  //     transform: (state, { lc, div }) => {
  //       const base = selectBase(state, lc, "byDivBU");
  //       const data = sumCompetitors(base);
  //       return data;
  //     },
  //     columns: viewCompetitorYearsColumns()
  //   }
  // },
  {
    id: "global_improvement_subcat",
    title: ({ year, opco }) =>
      `${opco}: Improvement Areas (subcat slide): Development ${year -
        1} - ${year}`,
    shortTitle: () => "Improvement Areas for Subcat Slide",
    process: {
      transform: (state, { lc }) => {
        const base = selectBase(state, lc, "byDivArea");
        const data = sumGlobalCategory(base, null, true);
        const turned = reverse(data);
        return turned;
      },
      columns: viewCategoryCombineYearsColumns()
    }
  }
];

export const divSteps = [
  {
    id: "bu_byBU_NPS",
    title: ({ div, year }) =>
      `${div}: NPS Development ${year - 1}  – ${year} by Business Unit`,
    subTitle: () => `Lead Country NPS by Business Unit`,
    shortTitle: ({ div }) => `LC NPS by BU`,
    process: {
      transform: (state, { lc, div }) => {
        const base = selectBase(state, lc, "byDivBU");
        const data = filter(base, { div });
        const withMissing = fillMissingBUs(data, div);
        const withHeader = addSummaryRows(withMissing, { bu: div });
        const view = viewSplitYear(withHeader, "bu", true);
        return view;
      },
      columns: viewSplitYearColumns("bu", "BU")
    }
  },
  {
    id: "bu_byBU_distribution",
    title: ({ div, year }) =>
      `${div}: NPS Development ${year - 1}  – ${year} by Business Unit`,
    subTitle: () => `NPS distribution by Business Unit`,
    shortTitle: ({ div }) => `NPS Distribution by BU`,
    process: {
      transform: (state, { lc, div }) => {
        const base = selectBase(state, lc, "byDivBU");
        const data = filter(base, { div });
        const withMissingBu = fillMissingBUs(data, div);
        const withMissingYear = fillMissingYear(withMissingBu, "bu");
        const withHeader = addSummaryRows(withMissingYear, { bu: div });
        return withHeader;
      },
      columns: viewDistributionColumns()
    }
  },
  {
    id: "bu_byBU_global",
    title: ({ div, year }) =>
      `${div}: Lead Country and global NPS by Business Unit`,
    subTitle: () => `Global NPS by Business Unit`,
    shortTitle: ({ div }) => `Global NPS by BU`,
    process: {
      transform: (state, { div }) => {
        const base = selectFromAllLC(state, "byDivBU");
        const data = filter(base, { div });
        const withMissingBu = fillMissingBUs(data, div);
        const combined = sumByGroup(withMissingBu, "bu");
        const withHeader = addSummaryRows(combined, { bu: div });
        return viewSplitYear(withHeader, "bu", true);
      },
      columns: viewSplitYearColumns("bu", "BU")
    }
  },
  {
    id: "div_development_identical",
    title: ({ year, div }) =>
      `${div} NPS Development of identical customers ${year - 1} and ${year}`,
    shortTitle: () => "NPS Development identical",
    process: {
      transform: (state, { lc, div }) => {
        const base = selectBase(state, lc, "byDivBU");
        const filtered = filter(base, { div });
        const sum = sumIdentical(filtered);
        const aggregated = viewIdenticalDevelopment(sum);
        return aggregated;
      },
      columns: viewColumnsIdenticalDevelopment()
    }
  },
  {
    id: "div_improvement",
    title: ({ div, year }) =>
      `${div}: Improvement Areas: Development ${year - 1}  – ${year}`,
    subTitle: () => `Where could we improve?`,
    shortTitle: ({ div }) => `Improvement Areas`,
    process: {
      transform: (state, { lc, div }) => {
        const base = selectBase(state, lc, "byDivArea");
        const filtered = filter(base, { div });
        const data = sumGlobalCategory(filtered);
        return data;
      },
      columns: viewCategoryCombineYearsColumns()
    }
  },
  {
    id: "div_improvement_details",
    title: ({ mainStep: div, year }) =>
      `${div}: Improvement Areas: Development ${year - 1}  – ${year}`,
    subTitle: () => `Where could we improve? (Details)`,
    shortTitle: ({ div }) => `Improvement Areas: Details`,
    process: {
      transform: (state, { lc, div, mainCategory }) => {
        const base = selectBase(state, lc, "byDivArea");
        const filtered = filter(base, { div });
        const data = sumSubCategory(filtered, mainCategory);
        return data;
      },
      columns: viewCategoryCombineYearsColumns()
    },
    top: SubCategorySelect
  },
  // {
  //   id: "div_competitor_better",
  //   title: ({ div, year }) =>
  //     `${div}: All and main competitors Improvement Areas: Development ${year -
  //       1}  – ${year}`,
  //   subTitle: () => `Where is the competitor better than us?`,
  //   shortTitle: () => "All Competitors",
  //   process: {
  //     transform: (state, { lc, div }) => {
  //       const base = selectBase(state, lc, "byDivArea");
  //       const filtered = filter(base, { div });
  //       const data = sumGlobalCategory(filtered, true);
  //       return data;
  //     },
  //     columns: viewCategorySplitYearsColumns()
  //   }
  // },
  // {
  //   id: "div_competitor_top",
  //   title: ({ div, year }) =>
  //     `${div}: All and main competitors Improvement Areas: Development ${year -
  //       1}  – ${year}`,
  //   subTitle: () => `NPS Score – All and top 3 competitors`,
  //   shortTitle: () => "Top Competitors",
  //   process: {
  //     transform: (state, { lc, div }) => {
  //       const base = selectBase(state, lc, "byDivBU");
  //       const filtered = filter(base, { div });
  //       const data = sumCompetitors(filtered);
  //       return data;
  //     },
  //     columns: viewCompetitorYearsColumns()
  //   }
  // },
  {
    id: "div_improvement_subcat",
    title: ({ year, opco, bu }) =>
      `${bu}: Improvement Areas (Subcat Slide): Development ${year -
        1} - ${year}`,
    shortTitle: () => "Improvement Areas (Subcat Slide)",
    process: {
      transform: (state, { lc, opco, div }) => {
        const base = selectBase(state, lc, "byDivArea");
        const filtered = filter(base, { div });
        const data = sumGlobalCategory(filtered, null, true);
        return reverse(data);
      },
      columns: viewCategoryCombineYearsColumns()
    }
  }
];

// export const globalKeys = globalSteps.map(s => s.id);
// export const divKeys = divSteps.map(d => d.id);
// export const mainStepKeys = ["global"].concat(divisions.map(d => d.id));
// export const subStepKeys = mainKey =>
//   mainKey === "global" ? globalSteps.map(d => d.id) : divKeys;
