import { green, red, cyan, indigo } from "@material-ui/core/colors";

export const STATUS = {
  // NONE: "STATUS_NONE",
  OPEN: "STATUS_OPEN",
  DONE: "STATUS_DONE",
  ISSUE: "STATUS_ISSUE",
  CHECK: "STATUS_CHECK"
};

export const STATUS_UI = {
  STATUS_OPEN: {
    color: cyan[300],
    baseColor: cyan,
    label: "Open",
    order: 1
  },
  STATUS_ISSUE: {
    color: red[300],
    baseColor: red,
    label: "Problem",
    order: 2
  },
  STATUS_DONE: {
    color: green[300],
    baseColor: green,
    label: "Done",
    order: 3
  },
  STATUS_CHECK: {
    color: indigo[300],
    baseColor: indigo,
    label: "Check",
    order: 4
  }
};
