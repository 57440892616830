import { connect } from "react-redux";
import { compose } from "recompose";
import { firestoreConnect } from "react-redux-firebase";

const enhance = compose(
  firestoreConnect(["projects"]),
  connect(state => {
    return {
      projects: state.firestore.ordered.projects
    };
  })
);

export default enhance;
