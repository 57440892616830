import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

export const enhance = compose(
  connect(state => ({
    auth: state.firebase.auth
  })),
  // I also fetch the stats, but read them somewhere else from the state
  firestoreConnect(props => [
    {
      collection: "projects",
      where: [
        ["active", "==", true],
        ["members", "array-contains", props.auth.uid]
      ]
    }
  ])
);

export default enhance;
