import { connect } from "react-redux";
import { isAdmin } from "../store/user";

function OnlyAdmin({ isAdmin, children }) {
  if (!isAdmin) {
    return null;
  }
  return children;
}

export default connect(state => ({
  isAdmin: isAdmin(state.user.roles)
}))(OnlyAdmin);
