import React from "react";
import Avatar from "@material-ui/core/Avatar";
import head from "lodash/head";
import { displayName } from "../store/user";

export default function UserAvatar({ auth, ...rest }) {
  if (auth.photoURL) {
    return <Avatar src={auth.photoURL} {...rest} />;
  }
  const name = displayName(auth);
  return <Avatar {...rest}>{head(name)}</Avatar>;
}
