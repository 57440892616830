import { navigate } from "@reach/router";
import _get from "lodash/get";
import findIndex from "lodash/findIndex";
import size from "lodash/size";
import last from "lodash/last";
import head from "lodash/head";
import xor from "lodash/xor";
import reverse from "lodash/reverse";
import slice from "lodash/slice";
import pickBy from "lodash/pickBy";
import isUndefined from "lodash/isUndefined";
import differenceInMinutes from "date-fns/difference_in_minutes";
import { isAgent, isClient, isAdmin } from "./user";
import { SET_FILTER } from "./filter";
import { STATUS } from "../constants";
import { getTextRef } from "../firebase";

const removeEmpty = (obj) => {
  return pickBy(obj, (a) => !isUndefined(a));
};

/***** SELECTORS *******/

export const getUrlState = () => {
  const { pathname } = location; // eslint-disable-line no-restricted-globals
  // eslint-disable-next-line
  const [a, c, questionId, t, textId] = pathname.split("/");
  const completed = t === "completed";
  return {
    questionId,
    textId,
    completed,
  };
};

export const getText = (state, textId) => {
  const { questionId } = getUrlState();
  const fromWorklist = _get(
    state.firestore,
    `data.worklist/${questionId}.${textId}`
  );
  const fromData = _get(
    state.firestore,
    `data.questions.${questionId}.texts.${textId}`
  );
  const fromHistory = _get(state.worklist, `historyItems.${textId}`);
  return fromWorklist || fromData || fromHistory;
};

export const getWorklist = (state) => {
  const { questionId } = getUrlState();
  const worklist = _get(state, `firestore.ordered.worklist/${questionId}`, []);
  const f = _get(head(worklist), "id");
  const l = _get(last(worklist), "id");
  const sorted = f > l ? reverse(worklist) : worklist;
  return sorted;
};

const getCurrentIndex = (worklist) => {
  const { textId } = getUrlState();
  // console.log(worklist);
  const currentIndex = findIndex(worklist, (t) => t.id === textId);
  return currentIndex;
};

export const canClaim = (userId, t) => {
  const lockAge =
    t.lockedAt && differenceInMinutes(new Date(), t.lockedAt.toDate());

  return !t.lockedBy || t.lockedBy === userId || lockAge > 8;
};

export const canBacktrack = (state) => {
  // const worklist = getWorklist(state)
  // const currentIndex = getCurrentIndex(worklist)
  const { textId } = getUrlState();
  const hasHistory = size(state.worklist.history) > 0;
  const hasPreviousInHistory =
    hasHistory && state.worklist.history.indexOf(textId) !== 0;
  return hasPreviousInHistory;
};

/***** ACTIONS *******/

const SET_WINDOW_CRITERIA = "WORKLIST:SET_WINDOW_CRITERIA";
export const NAVIGATED_PREVIOUS = "WORKLIST:NAVIGATED_PREVIOUS";
export const NAVIGATED_NEXT = "WORKLIST:NAVIGATED_NEXT";
const CLEAR_HISTORY = "WORKLIST:HISTORY_CLEAR";
const STORE_HISTORY = "WORKLIST:STORE_HISTORY";
const TEXT_UPDATED = "WORKLIST:TEXT_UP";

const textUpdated = (textId, text, update) => ({
  type: TEXT_UPDATED,
  textId,
  text,
  update,
});

export const clearHistory = () => ({
  type: CLEAR_HISTORY,
});

export const updateText = (questionId, textId, text, user, values) => async (
  dispatch
) => {
  if (text.demo) {
    return;
  }
  const toUpdate = removeEmpty(values);
  // Find actual diff

  const currentClasses = values.classes;
  const classesChangeCount = size(
    xor(text.classes || [], values.classes || [])
  );

  // Check for core data change
  const classesChange = classesChangeCount > 0;
  const textChange = text.text !== toUpdate.text;
  const sentimentChange = text.sentiment !== toUpdate.sentiment;
  const issueChanged = text.issue !== toUpdate.issue;
  const storyChanged = text.storyline !== toUpdate.storyline;
  // console.log(
  //   "classChange",
  //   classesChangeCount,
  //   "textChange",
  //   textChange,
  //   "sentimentChange",
  //   sentimentChange,
  //   "issueChanged",
  //   issueChanged
  // );
  let changed =
    classesChange ||
    textChange ||
    sentimentChange ||
    issueChanged ||
    storyChanged;

  // console.log(
  //   "agent",
  //   isAgent(user),
  //   "client",
  //   isClient(user),
  //   "admin",
  //   isAdmin(user)
  // );

  // Handle Status Changes
  if (toUpdate.issue && !text.issue) {
    changed = true;
    toUpdate.hadIssue = true;
    toUpdate.status = STATUS.ISSUE;
  } else if (
    text.status !== STATUS.DONE &&
    text.status !== STATUS.CHECK &&
    size(currentClasses) > 0 &&
    (isAgent(user) || isAdmin(user))
  ) {
    changed = true;
    toUpdate.status = STATUS.DONE;
  } else if (
    text.status !== STATUS.CHECK &&
    size(currentClasses) > 0 &&
    isClient(user)
  ) {
    changed = true;
    toUpdate.status = STATUS.CHECK;
  } else if (size(currentClasses) === 0) {
    toUpdate.status = STATUS.OPEN;
  }

  if (!changed) {
    return;
  }

  // Were changing so update changelog
  const now = new Date();
  if (!text.agent && text.status === STATUS.OPEN && isAgent(user)) {
    toUpdate.agent = user.id;
    toUpdate.agentAt = now;
  }

  if (!text.client && isClient(user)) {
    toUpdate.client = user.id;
    toUpdate.clientAt = now;
  }

  if (!text.admin && isAdmin(user)) {
    toUpdate.admin = user.id;
    toUpdate.adminAt = now;
  }

  const t = getTextRef(questionId, textId);
  await t.update(toUpdate);
  dispatch(textUpdated(textId, text, toUpdate));
};

export const recordHistory = (textId) => (dispatch, getState) => {
  const text = getText(getState(), textId);
  dispatch({
    type: STORE_HISTORY,
    textId,
    text,
  });
};

export const setWindowCriteria = (criteria = {}, meta = {}) => {
  return {
    type: SET_WINDOW_CRITERIA,
    criteria,
    meta,
  };
};

export const navigatedPrevious = () => ({
  type: NAVIGATED_PREVIOUS,
});

export const navigatedNext = () => ({
  type: NAVIGATED_NEXT,
});

/***** ACTION CREATORS *******/

const goNext = (questionId, nextId) => (dispatch) => {
  dispatch(recordHistory(nextId));
  navigate(`/classify/${questionId}/text/${nextId}`);
  dispatch(navigatedNext());
};

export const next = () => (dispatch, getState) => {
  const { questionId, textId } = getUrlState();
  const state = getState();
  // Check if were in history and navigate there
  const { history } = state.worklist;
  const historyIndex = history.indexOf(textId);
  if (historyIndex > -1) {
    const historyNext = history[historyIndex + 1];
    if (historyNext) {
      dispatch(navigatedNext());
      return navigate(`/classify/${questionId}/text/${historyNext}`);
    }
  }

  // Normal Navigation
  const worklist = getWorklist(state);
  const currentIndex = getCurrentIndex(worklist);
  if (currentIndex === -1) {
    // Navigate to the beginning of the worklist
    if (size(worklist) > 0) {
      const first = head(worklist);
      return dispatch(goNext(questionId, first.id));
    }
    console.warn("Worklist is empty");
    return navigate(`/classify/${questionId}/completed`);
  }

  const ahead = slice(worklist, currentIndex + 1);
  const userId = state.user.id;
  const next = ahead.find((t) => canClaim(userId, t));
  if (next) {
    dispatch(goNext(questionId, next.id));
    const count = size(worklist);
    const bufferNext = count - 1 - currentIndex;
    if (bufferNext <= 5) {
      dispatch(advanceWindow());
    }
  } else {
    return navigate(`/classify/${questionId}/completed`);
  }
};

const advanceWindow = () => (dispatch, getState) => {
  const state = getState();
  const { startAt } = state.worklist.criteria;
  const { previousLast } = state.worklist.meta;
  const worklist = getWorklist(getState());
  const currentLast = last(worklist).id;
  const currentIndex = getCurrentIndex(worklist);
  // What if the worklist is so tiny, that this item does not exist anymore?
  // Exception handling
  const startIndex = currentIndex - 5;
  const startText = worklist[startIndex];
  if (startText && startText.id !== startAt && currentLast !== previousLast) {
    dispatch(
      setWindowCriteria(
        {
          startAt: startText.id,
        },
        {
          previousLast: currentLast,
        }
      )
    );
  }
};

export const previous = () => (dispatch, getState) => {
  const { questionId, textId } = getUrlState();
  const { history } = getState().worklist;
  const historyIndex = history.indexOf(textId);
  if (historyIndex > -1) {
    const before = history[historyIndex - 1];
    if (before) {
      dispatch(navigatedPrevious());
      return navigate(`/classify/${questionId}/text/${before}`);
    }
  } else {
    dispatch(navigatedPrevious());
    return navigate(`/classify/${questionId}/text/${last(history)}`);
  }
};

const initialState = {
  criteria: {},
  meta: {
    loaded: false,
  },
  history: [],
  historyItems: {},
};
export default function worklistReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WINDOW_CRITERIA: {
      return {
        ...state,
        criteria: action.criteria,
        meta: {
          ...state.meta,
          ...action.meta,
        },
      };
    }
    case SET_FILTER: {
      return {
        ...state,
        criteria: {},
        history: [],
        meta: {
          ...state.meta,
          loaded: false,
        },
      };
    }
    case CLEAR_HISTORY: {
      return {
        ...state,
        history: [],
      };
    }
    case STORE_HISTORY: {
      return {
        ...state,
        history: [...state.history, action.textId],
        historyItems: {
          ...state.historyItems,
          [action.textId]: action.text,
        },
      };
    }
    case TEXT_UPDATED: {
      return {
        ...state,
        historyItems: {
          ...state.historyItems,
          [action.textId]: {
            ...action.text,
            ...action.update,
          },
        },
      };
    }
    case "@@reduxFirestore/LISTENER_RESPONSE": {
      if (action.meta.storeAs && action.meta.storeAs.indexOf("worklist") > -1) {
        return {
          ...state,
          meta: {
            ...state.meta,
            start: _get(head(action.payload.ordered), "id"),
            end: _get(last(action.payload.ordered), "id"),
            loaded: true,
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}
