import React, { useState } from "react";
import size from "lodash/size";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import StatsChart from "./stats-chart";
import SubStatsChart from "./sub-stats-chart";

import ClassStatsContainer from "./class-stats-container";

function ClassStatsCardSafe(props) {
  if (size(props.stats) === 0) {
    return null;
  }
  return <ClassStatsCard {...props} />;
}

function ClassStatsCard({ stats }) {
  const first = stats[0].code;
  const [selected, setSelected] = useState(first);

  const sub = stats.find(c => c.code === selected);

  return (
    <Card>
      <CardHeader title="Summary for NPS Question" subheader={sub.name} />
      <Divider />
      <CardContent>
        <Grid container spacing={16}>
          <Grid item xs={12} md={4}>
            <StatsChart
              data={stats}
              selected={selected}
              setSelected={setSelected}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SubStatsChart data={sub.children} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ClassStatsContainer(ClassStatsCardSafe);
