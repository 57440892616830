import { connect } from "react-redux";
import { checkRole } from "../store/user";

function RequireRole({ isAllowed, children }) {
  if (!isAllowed) {
    return null;
  }
  return children;
}

export default connect((state, props) => {
  const check = checkRole(props.role);
  return {
    isAllowed: check(state.user.roles)
  };
})(RequireRole);
