import { getAuth } from "./auth-container";

function OnlyAuthenticated({ authenticated, children }) {
  if (!authenticated) {
    return null;
  }
  return children;
}

export default getAuth(OnlyAuthenticated);
