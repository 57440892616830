import React from "react";

import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Divider from "@material-ui/core/Divider";
import DeleteProjectButton from "./delete-project-button";

import TableChart from "@material-ui/icons/TableChart";

import LoadingButton from "../../components/loading-button";

import { FieldSet } from "../../components/form-layout";

import renderTextField from "../../components/textfield-field";
import renderCheckbox from "../../components/single-checkbox-field";
import MemberForm from "./member-form";

import QuestionForm from "./question-form";
import map from "lodash/map";
import _get from "lodash/get";

function ProjectForm({
  project,
  users,
  firestore,
  save,
  edit,
  projects,
  deleteProject
}) {
  const questions = _get(project, "questions", []);
  const initialValues = {
    name: _get(project, "name"),
    active: _get(project, "active", false),
    questions: map(questions, q => ({
      ...q,
      link: q.link && q.link.id
    })),
    members: _get(project, "members", [])
  };

  return (
    <Form
      onSubmit={save}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
    >
      {({ handleSubmit, pristine, submitting, submitError, ...other }) => (
        <Card>
          <CardHeader
            title={_get(project, "name", "Create New Project")}
            action={
              deleteProject && (
                <DeleteProjectButton deleteProject={deleteProject} />
              )
            }
          />
          {console.log("pristine", pristine, "other", other)}
          <Divider />
          <CardContent style={{ width: 600 }}>
            {submitError}
            <FieldSet icon={<TableChart />}>
              <Field name="name" component={renderTextField} label="Name" />
              <Field
                type="checkbox"
                name={`active`}
                label="Active"
                component={renderCheckbox}
              />
            </FieldSet>
            <MemberForm />
            <QuestionForm edit={edit} projects={projects} />
          </CardContent>
          <Divider />
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* Should also be disabled when pristine, but pristine has bugs when deleting element from */}
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={submitting}
              loading={submitting}
              onClick={handleSubmit}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      )}
    </Form>
  );
}

ProjectForm.defaultProps = {
  project: {},
  users: [],
  projects: []
};

export default ProjectForm;
