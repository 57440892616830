const divisionNames = {
  DI: "Digital Industries",
  GP: "Gas & Power",
  MO: "Mobility",
  SI: "Smart Infrastructure",
  SFS: "Financial Services",

  BT: "Building Technologies",
  DF: "Digital Factory",
  EM: "Energy Management",
  PD: "Process Industries and Drives",
  PG: "Power and Gas",
  PS: "Power Generation Services",
  DR: "DR"
};

export const activeDivisions = ["DI", "GP", "MO", "SI", "SFS"];

export const buOrder = {
  SI: [
    "SI",
    "RSS-DE",
    "RSS-EU",
    "RSS-AM",
    "RSS-MA",
    "DG",
    "DS",
    "LP",
    "CP",
    "BP"
  ],
  GP: [
    "GP",
    // "PG",
    // "EPC",
    // "O&G",
    // "TP",
    // "SPG",
    // "SDO",
    // "SCD",
    // "SPT"
    "Americas",
    "Asia Pacific",
    "China",
    "Europe Africa",
    "Middle East"
  ],
  MO: ["MO", "MM", "TPE", "RS", "CS"],
  EM: ["EM", "HP", "DG", "LP", "MS", "TR", "TS"]
};

export default divisionNames;
