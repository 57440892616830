import countries from "i18n-iso-countries";
import groupBy from "lodash/groupBy";
import flatMap from "lodash/flatMap";
import flatMapDeep from "lodash/flatMapDeep";
import map from "lodash/map";
import size from "lodash/size";
import meanBy from "lodash/meanBy";
import mapValues from "lodash/mapValues";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import _filter from "lodash/filter";
import uniq from "lodash/uniq";
import head from "lodash/head";
import isPlainObject from "lodash/isPlainObject";
import isArray from "lodash/isArray";
import _get from "lodash/get";
import isFinite from "lodash/isFinite";
import find from "lodash/find";
import sum from "lodash/sum";
import forEach from "lodash/forEach";
import zip from "lodash/zip";
import take from "lodash/take";
import slice from "lodash/slice";
import max from "lodash/max";
import pickBy from "lodash/pickBy";
import min from "lodash/min";
import omit from "lodash/omit";
import difference from "lodash/difference";
import keyBy from "lodash/keyBy";

import leadCountries, { CONTINENTS, translateLC } from "./data/lead-countries";

import divisionNames, { activeDivisions, buOrder } from "./data/divisions";

const {
  categories,
  allCategories,
  mainCategories,
  subCategories,
  ccCategories,
  ccMainCategories,
  ccSubCategories,
  categoryLabels,
  OTHER_CATEGORY
} = require("./data/classification");




const CURRENT_YEAR = 2019

/*
 *  1. Generate Structured Data
 */

export const transformRow = (r, i) => {
  let main = {};
  allCategories.forEach(c => {
    main[c.key] = r[c.source] || r[c.key];
  });
  const sub = {};
  flatMap(allCategories, "children").forEach(c => {
    sub[c.key] = r[c.source] || r[c.key];
    // if (!isFinite(sub[c.key])) {
    //   console.warn("Sub", c.key, "not defined", sub[c.key]);
    // }
  });

  let ccMain = {};
  ccCategories.forEach(c => {
    ccMain[c.key] = r[c.source] || r[c.key];
  });
  // cs_Misc field missing in test upload
  // if (!has(ccMain, "cs_Misc")) {
  //   ccMain["cs_Misc"] =
  //     r["cs_No_improvement_necessary"] || r["cs_Not_assignable"] ? 1 : 0;
  // }
  const ccSub = {};
  flatMap(ccCategories, "children").forEach(c => {
    ccSub[c.key] = r[c.source] || r[c.key];
    // if (!isFinite(ccSub[c.key])) {
    //   console.warn("CCSub", c.key, "not defined", ccSub[c.key]);
    // }
  });

  // const main = mapValues(keyBy(mainCategories), key => r[key]);
  // const sub = mapValues(keyBy(subCategories), key => r[key]);
  const division = r["Division"] || r["Divsion"];
  const bu = division === "GP" ? r["Hub"] || r["HUB"] : r["Business Units"];
  const result = {
    conId: r["con.id"] || r["c.contPersID"],
    div: division,
    bu,
    lc: r["Lead_country"],
    year: r["survey_year"],
    country: r["b.bpCountry"],
    identical: !!r["identical_coded"],
    nps_val: r["NPS_01"],
    nps_calc: r["NPScalc_spss"],
    competitor: r["BMK_02"],
    competitorNPS_calc: r["CompNPScalc_spss"],

    distLastYear: r["Development_previous_year"], // 1 = Promoter | 2 = Passive | 3 = Detractor
    distCurrYear: r["Distribution"], // 1 = Promoter | 2 = Passive | 3 = Detractor
    ...main,
    ...sub,
    ...ccMain,
    ...ccSub
  };
  return result;
};

export const structureDataSync = data => {
  console.time("groupStructureData");
  const byLC = groupBy(data, "lc");
  const grouped = mapValues(byLC, (lcData, lc) => {
    const byDiv = groupBy(lcData, "div");
    const nested = mapValues(byDiv, divData => groupBy(divData, "bu"));
    const nestedAggregated = aggregateGroups(nested, true);
    const byDivBU = flattenRecursive(nestedAggregated);

    const byCountry = flattenRecursive(
      aggregateGroups(groupBy(lcData, "country"))
    );

    const byDivArea = flatMap(byDiv, divData => aggregateCategories(divData));
    return {
      byDivBU,
      byCountry,
      byDivArea // Has slightly different structure
    };
  });
  console.time("byDivMain");
  const byDivMain = groupBy(data, "div");
  const aggregated = mapValues(byDivMain, divData => {
    const byBuData = groupBy(divData, "bu");
    const aggregatedByBu = flatMap(byBuData, (buData, bu) =>
      aggregateCategories(buData).map(d => ({ ...d, bu }))
    );
    return aggregatedByBu;
  });
  console.timeEnd("byDivMain");
  grouped.byDivMain = aggregated;

  // mapValues(byDiv, divData => {
  //   const byBU = groupBy(divData, "bu");
  //   const mappedByBU = flatMap(byBU, buData => aggregateCategories(buData));
  //   return mappedByBU;
  // });
  window.structuredData = grouped;
  console.timeEnd("groupStructureData");
  return grouped;
};

const aggregateCategories = col => {
  const byYear = groupBy(col, "year");
  const flat = flatMap(byYear, (rows, year) => {
    const withAnswer = filter(rows, r => isFinite(r.nps_val));
    let agg = {
      year,
      count: size(withAnswer),
      total_count: size(rows)
    };
    const divs = uniq(map(rows, "div"));
    if (size(divs) === 1) {
      agg.div = head(divs);
    }

    const classify = key => {
      const s = sum(map(rows, key));
      agg[key] = s;
    };
    forEach([...mainCategories, OTHER_CATEGORY.key], classify);
    forEach(subCategories, classify);
    forEach(ccMainCategories, classify);
    forEach(ccSubCategories, classify);
    return agg;
  });
  return reverse(sortBy(flat, "year"));
};

export function getCountries() {
  // const leadCountries.map()
}

const aggregateCompetitors = data => {
  const byCompetitor = groupBy(data, "competitor");
  const aggregated = mapValues(byCompetitor, (v, competitor) => {
    return {
      competitor,
      nps: meanBy(v, "competitorNPS_calc"),
      count: size(v)
    };
  });
  const sorted = reverse(sortBy(aggregated, "count"));
  return sorted;
};

function makeIdenticalDevelopment(identical) {
  const lastGrouped = groupBy(identical, "distLastYear");
  const withSub = flatMap(lastGrouped, (values, lastCategory) => {
    const subGrouped = groupBy(values, "distCurrYear");
    const mapped = map(subGrouped, (subValues, thisCategory) => {
      return {
        key: `${lastCategory}_${thisCategory}`,
        lastCategory,
        thisCategory,
        count: size(subValues)
      };
    });
    return mapped;
  });
  return withSub;
}

const aggregateGroups = (col, withIdentical) => {
  if (isPlainObject(col)) {
    return mapValues(col, rows => aggregateGroups(rows, withIdentical));
  }
  if (isArray(col)) {
    const byYear = groupBy(col, "year");
    const flat = flatMap(byYear, (rows, year) => {
      const withAnswer = filter(rows, r => isFinite(r.nps_val));
      const count = size(withAnswer);
      const promoter_count = size(withAnswer.filter(r => r.nps_val >= 9));
      const passive_count = size(
        withAnswer.filter(r => r.nps_val < 9 && r.nps_val > 6)
      );
      const detractor_count = size(withAnswer.filter(r => r.nps_val <= 6));
      const competitor = aggregateCompetitors(rows);

      let agg = {
        year,
        count,
        total_count: size(rows),
        nps: meanBy(withAnswer, "nps_calc"),
        promoter_count,
        passive_count,
        detractor_count,
        promoter_percent: promoter_count / count,
        passive_percent: passive_count / count,
        detractor_percent: detractor_count / count,
        competitor
      };
      const divs = uniq(map(rows, "div"));
      if (size(divs) === 1) {
        agg.div = head(divs);
      }
      const bus = uniq(map(rows, "bu"));
      if (size(bus) === 1) {
        agg.bu = head(bus);
      }
      const years = uniq(map(rows, "year"));
      if (size(years) === 1) {
        agg.year = head(years);
      }
      const countries = uniq(map(rows, "country"));
      if (size(countries) === 1) {
        agg.country = head(countries);
      }
      if (withIdentical) {
        const identical = _filter(withAnswer, { identical: true });
        agg.identical_count = size(identical);
        agg.identical_nps = meanBy(identical, "nps_calc");

        const identical_development = makeIdenticalDevelopment(identical);
        agg.identical_development = identical_development;
      }
      return agg;
    });
    return reverse(sortBy(flat, "year"));
  }
  return col;
};

const flattenRecursive = col => {
  return flatMapDeep(col, v => (isPlainObject(v) ? flattenRecursive(v) : [v]));
};

/*
 *  Filter/Group/Prepare Dat
 */

export const selectBase = (data, lc, structure) =>
  _get(data, `${lc}.${structure}`);

export const selectFromAllLC = (data, structure) => {
  const lcData = pickBy(
    data,
    (v, k) => k.indexOf("LC") === 0 || k.indexOf("CC") === 0
  );
  return flatMap(lcData, (d, lc) =>
    map(_get(d, structure), row => ({ lc, ...row }))
  );
};

export const filter = (data, filterCond) => {
  let filtered = _filter(data, filterCond);
  if (filterCond.div) {
    filtered = sortBy(filtered, "bu");
  }
  return filtered;
  // return filtered.map(r => ({
  //   ...r,
  //   nps: round(r.nps)
  // }));
};

export const sumIdentical = data => {
  const byYear = groupBy(data, "year");
  const aggregated = mapValues(byYear, yearData => {
    const allDevelopment = flatMap(yearData, "identical_development");
    const sum = allDevelopment.reduce((agg, curr) => {
      const { key, count } = curr;
      if (!agg[key]) {
        agg[key] = { ...curr };
      } else {
        agg[key].count += count;
      }
      return agg;
    }, {});
    return sum;
  });
  return aggregated;
};

const sortByDiv = data => {
  const sortedBu = Object.keys(divisionNames);
  return sortBy(data, d => sortedBu.indexOf(d.div));
};

const sortByKey = (data, key) => {
  switch (key) {
    case "div":
      return sortByDiv(data);
    case "bu": {
      const { div } = head(data);
      return sortByBU(data, div);
    }
    default:
      return sortBy(data, key);
  }
};

export const sumByGroup = (data, key) => {
  const sortedByKey = sortByKey(data, key);
  const byKey = groupBy(sortedByKey, key);
  const byYear = mapValues(byKey, (keyRows, keyVal) => {
    return mapValues(groupBy(keyRows, "year"), (yearRows, year) => {
      return sumResults(yearRows);
    });
  });
  const flattened = flatMap(map(byYear, c => reverse(map(c))));
  return flattened;
};

export const sumGlobalCategory = (data, competitor, addOthers) => {
  const byYear = groupBy(data, "year");
  let categories = competitor ? ccMainCategories : mainCategories;
  if (addOthers) {
    categories = [...categories, OTHER_CATEGORY.key];
  }
  const aggregated = mapCategories(byYear, categories);
  return splitCategories(aggregated, categories);
};

export const sumContinents = rows => {
  const missingCountries = difference(
    map(leadCountries, "id"),
    uniq(map(rows, "lc"))
  );
  const year = rows[0].year;
  const missingRecords = missingCountries.map(lc => ({ lc, year }));
  const allCountries = sortBy(rows.concat(missingRecords), "lc");
  const withContinent = allCountries.map(r => {
    const lc = leadCountries.find(lc => lc.id === r.lc);
    if (!lc) {
      console.warn("COULD NOT FIND LC for", r.lc);
    }
    return {
      ...r,
      continent: lc && lc.continent
    };
  });
  const grouped = groupBy(withContinent, "continent");
  const sorted = mapValues(keyBy(CONTINENTS), key => grouped[key]);

  const mapped = map(sorted, (continentData, continent) => {
    const sorted = sortBy(continentData, a => translateCountry(a).country);
    const withHeader = addSummaryRows(sorted, {
      country: continent
    });
    return withHeader;
  });

  return mapped;
};

export const sumSubCategory = (data, mainCategory, competitor) => {
  if (!mainCategory) {
    return [];
  }

  const main = categories.find(c => c.key === mainCategory);
  if (!main) {
    throw new Error("Could not find main category:" + mainCategory);
  }
  const currentSub = map(main.children, "key");

  const byYear = groupBy(data, "year");
  const selectedCategories = competitor ? ccSubCategories : subCategories;
  const aggregated = mapCategories(byYear, selectedCategories);
  return splitCategories(aggregated, currentSub, 6);
};

function weightedAverage(values, weights) {
  var weightSum = sum(weights);
  weights = map(weights, function(weight) {
    return weight / weightSum;
  });
  return sum(
    map(zip(values, weights), function(pair) {
      return pair[0] * pair[1];
    })
  );
}

const mergeCompetitors = data => {
  const grouped = groupBy(data, "competitor");
  const aggregated = map(grouped, (competitorData, competitor) => {
    const count = sum(map(competitorData, "count"));
    const nps = weightedAverage(
      map(competitorData, "nps"),
      map(competitorData, "count")
    );
    return {
      name: competitor,
      count,
      nps
    };
  });
  return aggregated;
};

const notRelevantCompetitor = [
  "Other",
  "no competitor",
  "no competitor business",
  "no answer",
  "not named"
];

export const sumCompetitors = data => {
  const [year2017, year2018] = map(groupBy(data, "year"));

  const aggregateCompetitorYear = (yearRecords, selectedCompetitors) => {
    const year = _get(head(yearRecords), "year");
    const results = [];
    const allCompetitors = flatMap(yearRecords, "competitor");
    const allCompetitorsSum = {
      name: "All",
      year,

      count: sum(map(allCompetitors, "count")),
      nps: weightedAverage(
        map(allCompetitors, "nps"),
        map(allCompetitors, "count")
      )
    };
    results.push(allCompetitorsSum);

    const siemensSum = {
      name: "Siemens",
      year,
      count: sum(map(yearRecords, "count")),
      nps: weightedAverage(map(yearRecords, "nps"), map(yearRecords, "count"))
    };
    results.push(siemensSum);

    const mergedCompetitors = mergeCompetitors(allCompetitors);

    if (selectedCompetitors) {
      const competitors = map(selectedCompetitors, name => {
        return {
          ...find(mergedCompetitors, { name }),
          year
        };
      });
      results.push(...competitors);
    } else {
      const top3 = take(
        reverse(
          sortBy(mergedCompetitors, "count").filter(
            n => notRelevantCompetitor.indexOf(n.name) === -1
          )
        ),
        3
      ).map(c => ({
        ...c,
        year
      }));
      results.push(...top3);
    }

    return results;
  };

  const result2018 = aggregateCompetitorYear(year2018);
  const relevantCompetitors = map(slice(result2018, 2), "name");
  const result2017 = aggregateCompetitorYear(year2017, relevantCompetitors);
  return flatMap(zip(result2018, result2017));
};

export const mapCategories = (byYear, categories) => {
  return map(byYear, (yearData, year) => {
    let agg = {
      year,
      // count: sum(map(yearData, "count")),
      total_count: sum(map(yearData, "total_count"))
    };
    const classify = key => {
      const s = sum(map(yearData, key));
      agg[key] = s;
    };
    // if (competitor) {
    //   forEach(ccMainCategories, classify);
    //   forEach(ccSubCategories, classify);
    // } else {
    forEach(categories, classify);
    // forEach(subCategories, classify);
    // }

    return agg;
  });
};

const splitCategories = (years, categories = mainCategories, limit = 0) => {
  const byYear = map(years, yearData => {
    return map(categories, main => {
      // const count = yearData.count;
      const total_count = yearData.total_count;
      const category_count = yearData[main];
      const category_percent = category_count / total_count;
      return {
        year: yearData.year,
        // count,
        total_count,
        category: categoryLabels[main] || main,
        category_count,
        category_percent
      };
    });
  });
  const [last, current] = byYear;
  let zipped = zip(current, last);
  if (limit) {
    zipped = take(reverse(sortBy(zipped, "0.category_count")), limit);
  }
  // Combine Years
  const combinedByYears = map(zipped, catData => {
    const [current, last] = catData;
    if(!last && parseInt(current.year)  !== CURRENT_YEAR){
      return {
        category: current.category,
        current_category_count: 0,
        current_percent: 0,
        current_total_count: 0,
        last_category_count: current ? current.category_count : 0,
        last_percent:current ? current.category_percent : 0,
        last_total_count: current ? current.total_count : 0
      };
    }
    return {
      category: current.category,
      current_category_count: current.category_count,
      current_percent: current.category_percent,
      current_total_count: current.total_count,
      last_category_count: last ? last.category_count : 0,
      last_percent: last ? last.category_percent: 0,
      last_total_count: last ? last.total_count : 0
    };
  });
  // return flatten(zipped);
  return combinedByYears;
};

export const addSummaryRows = (rows, headerData) => {
  const grouped = groupBy(rows, "year");
  const headers = map(grouped, r => {
    const sum = sumResults(r);
    return {
      summary: true,
      ...sum,
      ...headerData
    };
  });

  return reverse(headers).concat(rows);
};

const sumResults = rows => {
  const sum = rows.reduce(reduceAggregate);
  return sum;
  // return {
  //   ...sum,
  //   // Koennte man auch im View machen.... gibt aber noch keine probleme
  //   nps: round(sum.nps),
  //   identical_nps: round(sum.identical_nps)
  // };
};

export function sortByBU(data, division) {
  const order = buOrder[division];
  if (!order) {
    return data;
  }

  const withBuOrder = data.map(d => {
    const index = order.indexOf(d.bu);
    return {
      ...d,
      index: index > -1 ? index : 999
    };
  });
  return sortBy(withBuOrder, "index");
}

function cleanNumber(c) {
  if (c === "-" || !c || !isFinite(c)) {
    return 0;
  }
  return c;
}

const reduceAggregate = (agg, cur) => {
  const aggCount = cleanNumber(agg.count);
  const aggIdenticalCount = cleanNumber(agg.identical_count);
  const aggTotal_count = cleanNumber(agg.total_count);
  const aggNps = cleanNumber(agg.nps);
  const aggIdenticalNps = cleanNumber(agg.identical_nps);
  const aggLastNps = cleanNumber(agg.last_nps);
  const aggLastCount = cleanNumber(agg.last_count);
  const aggPromoters = cleanNumber(agg.promoter_count);
  const aggPassive = cleanNumber(agg.passive_count);
  const aggDetractor = cleanNumber(agg.detractor_count);

  const curIdenticalNPS = cleanNumber(cur.identical_nps);
  const curIdenticalCount = cleanNumber(cur.identical_count);

  const curNPS = cleanNumber(cur.nps);
  const curCount = cleanNumber(cur.count);
  const curLastNPS = cleanNumber(cur.last_nps);
  const curLastCount = cleanNumber(cur.last_count);

  const nps = (aggNps * aggCount + curNPS * curCount) / (aggCount + curCount);
  const identical_nps =
    (aggIdenticalNps * aggIdenticalCount +
      curIdenticalNPS * curIdenticalCount) /
    (aggIdenticalCount + curIdenticalCount);

  const last_nps =
    (aggLastNps * aggLastCount + curLastNPS * curLastCount) /
    (aggLastCount + curLastCount);

  const count = aggCount + curCount;
  const promoter_count = aggPromoters + cleanNumber(cur.promoter_count);
  const passive_count = aggPassive + cleanNumber(cur.passive_count);
  const detractor_count = aggDetractor + cleanNumber(cur.detractor_count);

  const last_count = aggLastCount + curLastCount;

  return {
    ...cur,
    count,
    total_count: aggTotal_count + cleanNumber(cur.total_count),
    nps,
    identical_count: aggIdenticalCount + curIdenticalCount,
    identical_nps,
    promoter_count,
    passive_count,
    detractor_count,
    promoter_percent: promoter_count / count,
    passive_percent: passive_count / count,
    detractor_percent: detractor_count / count,
    last_nps,
    last_count,
    change: Math.round(nps) - Math.round(last_nps)
  };
};

/*
 *  Views
 */

export const fillMissingCountries = (rows, lc) => {
  const lcData = find(leadCountries, { id: lc });
  const { countries } = lcData;
  const existing = uniq(map(rows, "country"));
  const missing = difference(countries, existing);
  const missingRecords = map(missing, m => ({
    country: m
  }));
  return rows.concat(missingRecords);
};

export const fillMissingBUs = (rows, div) => {
  const years = uniq(map(rows, "year"));
  const allBU = buOrder[div] || [];
  const buWithoutDiv = allBU.filter(bu => bu !== div);
  const existing = uniq(map(rows, "bu"));
  const missing = difference(buWithoutDiv, existing);
  const missingRecords = flatMap(missing, bu =>
    map(years, year => ({ bu, year }))
  );
  const together = rows.concat(missingRecords);
  const sorted = sortByBU(together, div);
  return sorted;
};

export const fillMissingDivs = rows => {
  const years = uniq(map(rows, "year"));
  const allDivisions = activeDivisions;
  const existing = uniq(map(rows, "div"));
  const missing = difference(allDivisions, existing);
  const missingRecords = flatMap(missing, div =>
    map(years, year => ({ div, year }))
  );
  return sortByDiv(rows.concat(missingRecords));
};

export const fillMissingYear = (rows, key) => {
  const years = sortBy(uniq(map(rows, "year")));
  const grouped = groupBy(rows, key);
  return flatMap(grouped, g => {
    if (size(g) === 2) {
      return g;
    }
    const single = head(g);
    const missingYear = years.filter(y => y !== single.year);
    const filled = {
      [key]: single[key],
      year: missingYear
    };
    if (years.indexOf(head(missingYear)) === 0) {
      return [single, filled];
    }
    return [filled, single];
  });
};

export const viewSplitYear = (rows, key, forceBothYears) => {
  const years = sortBy(uniq(map(rows, "year")));
  const grouped = groupByYear(rows, key);
  return flatMap(grouped, group => {
    const [current, last] = group;
    const newCurrent = {
      ...current
    };
    if (current && last && current.count > 5 && last.count > 5) {
      const change = Math.round(current.nps) - Math.round(last.nps);
      newCurrent.change = change;
      const identical_change =
        Math.round(current.identical_nps) - Math.round(last.identical_nps);
      newCurrent.identical_change = identical_change;
    }
    // if (
    //   (isFinite(current.count) && current.count <= 5) ||
    //   (isFinite(last.count) && last.count <= 5)
    // ) {

    // }
    const cleaned = [newCurrent, last].filter(i => i);
    if (size(cleaned) === 2 || !forceBothYears) {
      return cleaned;
    }
    const common = {
      ...omit(current, "identical_nps", "nps", "year", "count")
    };
    if (years.indexOf(current.year) === 0) {
      return [
        {
          ...common,
          year: years[1]
        },
        current
      ];
    } else if (years.indexOf(current.year) === 1) {
      return [
        current,
        {
          ...common,
          year: years[0]
        }
      ];
    }
  });
};

export const viewCombineYears = (rows, key) => {
  const years = sortBy(uniq(map(rows, "year")));
  const grouped = groupByYear(rows, key);
  const combined = map(grouped, group => {
    const [current, last] = group;
    if (current && last) {
      return {
        ...current,
        change: Math.round(current.nps) - Math.round(last.nps),
        last_nps: last.nps,
        last_count: last.count
      };
    }
    if (years.indexOf(current.year) === 0) {
      const combined = {
        ...omit(current, "nps", "count"),
        last_nps: current.nps,
        last_count: current.count
      };
      console.log(combined);
      return combined;
    }
    return {
      ...current
    };
  });
  return combined;
};

const NPS_CATEGORY = {
  PROMOTER: "1",
  PASSIVE: "2",
  DETRACTOR: "3"
};

function sumIdenticalDevelopment(yearData, year) {
  // Total
  const total = map(groupBy(yearData, "thisCategory"), (r, thisCategory) => {
    const count = sum(map(r, "count"));
    const result = {
      summary: true
    };
    if (thisCategory === NPS_CATEGORY.PROMOTER) {
      result.promoters = count;
    } else if (thisCategory === NPS_CATEGORY.PASSIVE) {
      result.passive = count;
    } else if (thisCategory === NPS_CATEGORY.DETRACTOR) {
      result.detractors = count;
    }
    return result;
  }).reduce(
    (agg, curr) => {
      return Object.assign({}, agg, curr);
    },
    { title: `Identical ${year}` }
  );
  return total;
}

export const viewIdenticalDevelopment = byYear => {
  const thisYear = max(Object.keys(byYear));
  const lastYear = min(Object.keys(byYear));
  const thisYearData = byYear[thisYear];
  const lastYearData = byYear[lastYear];
  const lastTotal = sumIdenticalDevelopment(lastYearData, lastYear);
  const thisTotal = sumIdenticalDevelopment(thisYearData, thisYear);

  const sub = map(groupBy(thisYearData, "lastCategory"), (r, lastCategory) => {
    const promoters = find(r, { thisCategory: NPS_CATEGORY.PROMOTER });
    const passive = find(r, { thisCategory: NPS_CATEGORY.PASSIVE });
    const detractors = find(r, { thisCategory: NPS_CATEGORY.DETRACTOR });
    const result = {
      promoters: _get(promoters, "count", 0),
      passive: _get(passive, "count", 0),
      detractors: _get(detractors, "count", 0)
    };
    if (lastCategory === NPS_CATEGORY.PROMOTER) {
      result.title = "Promoters Development";
    } else if (lastCategory === NPS_CATEGORY.PASSIVE) {
      result.title = "Passive Development";
    } else if (lastCategory === NPS_CATEGORY.DETRACTOR) {
      result.title = "Detractors Development";
    }
    return result;
  });
  const allRows = [lastTotal].concat(sub).concat([thisTotal]);
  return allRows;
};

///// **** COLUMNS *****

export const viewSplitYearColumns = (groupKey, groupTitle) => [
  {
    name: groupKey,
    title: groupTitle,
    group: true
  },
  { name: "year", title: "Year", key: true },
  { name: "nps", title: "NPS", value: true },
  { name: "change", title: "change", dataType: "change" },
  { name: "count", title: "n", count: true }
];

export const viewSplitYearColumnsIdentical = (groupKey, groupTitle) => [
  {
    name: groupKey,
    title: groupTitle,
    group: true
  },
  { name: "year", title: "Year" },
  { name: "identical_nps", title: "NPS" },
  { name: "identical_change", title: "change", dataType: "change" },
  { name: "identical_count", title: "n" }
];

export const viewColumnsIdenticalDevelopment = () => [
  {
    name: "title",
    title: "Title",
    key: true,
    group: true
  },
  { name: "promoters", title: "Promoters", count: true },
  { name: "passive", title: "Passive", count: true },
  { name: "detractors", title: "Detractors", count: true }
];

export const viewCombineYearsColumns = (groupKey, groupTitle, skipLast) =>
  [
    { name: groupKey, title: groupTitle, group: true, key: true },
    { name: "nps", title: "NPS", value: true },
    { name: "count", title: "n", count: true },
    { name: "change", title: "change", dataType: "change" }
  ].concat(
    skipLast
      ? []
      : [
          { name: "last_nps", title: "Last NPS" },
          { name: "last_count", title: "Last N" }
        ]
  );

export const viewCategoryCombineYearsColumns = () => [
  { name: "category", title: "Category", key: true },
  {
    name: "current_percent",
    title: "Cur. Percent",
    percent: true
  },
  { name: "current_category_count", title: "Cur. n" },
  {
    name: "last_percent",
    title: "Last Percent",
    percent: true
  },
  { name: "last_category_count", title: "Last n" }
];

export const viewCompetitorYearsColumns = () => [
  {
    name: "name",
    title: "Name",
    group: true
  },
  { name: "year", title: "Year", key: true },
  { name: "nps", title: "NPS", value: true },
  { name: "count", title: "n" }
];

export const viewDistributionColumns = () => [
  {
    name: "bu",
    title: "BU",
    group: true
  },
  { name: "year", title: "Year", key: true },
  {
    name: "promoter_percent",
    title: "Promoters",
    percent: true
  },
  {
    name: "passive_percent",
    title: "Passives",
    percent: true
  },
  {
    name: "detractor_percent",
    title: "Detractors",
    percent: true
  },
  { name: "count", title: "n", count: true }
];

const groupByYear = (rows, key) => {
  if (size(rows) === 0) {
    return [];
  }
  if (!key) {
    throw new Error("e");
    console.warn("Should probably specify key");
  }

  function fillCurrentGroup(input) {
    const currentGroup = [...input];
    if (size(currentGroup) === 1) {
      // Fill in missing Years
      const existingElement = currentGroup[0];
      const existingYear = currentGroup[0].year;
      const missingYear = head(
        uniq(map(rows, "year")).filter(y => y !== existingYear)
      );
      const missingElement = {
        ...existingElement,
        year: missingYear,
        nps: null,
        count: "-",
        change: "-",
        identical_count: "-",
        identical_change: "-"
      };
      if (missingYear > existingYear) {
        currentGroup.unshift(missingElement);
      } else {
        currentGroup.push(missingElement);
      }
    }
    return currentGroup;
  }

  console.log("Original Order", uniq(map(rows, key)));
  const grouped = map(groupBy(rows, key), (years, key) => {
    const sortedYears = reverse(sortBy(years, "year"));
    const filled = fillCurrentGroup(sortedYears);
    return filled;
  });
  console.log("after Order", uniq(map(rows, key)));
  return grouped;
};

export const translateCountry = i => {
  const r = {
    ...i
  };
  if (r.country === r.continent) {
    return r;
  }
  if (!r.country && r.lc) {
    r.country = translateLC(r.lc);
  }
  if (r.country) {
    r.code = r.country;
    const countryName = countries.getName(r.code, "en");
    r.country = (countryName && head(countryName.split(","))) || r.code;
  }
  return r;
};
