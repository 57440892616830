import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "@reach/router";
import _Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import _CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import BrokenCeramicsImage from "../assets/broken-ceramics.jpg";
import OnlyAdmin from "../common/only-admin";
import { admin } from "../config";
import _get from "lodash/get";
import size from "lodash/size";
import map from "lodash/map";
import ChangeFilterButton from "./filter/change-filter-button";

const Centered = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;

const Card = styled(_Card)`
  max-width: 680px;
`;

const CardMedia = styled(_CardMedia)`
  height: 230px;
`;

const enhance = connect(state => ({
  errors: _get(state, "firestore.errors.byQuery", {})
}));

export function ErrorPage({ questionId, errors }) {
  if (size(errors) === 0) {
    return <Redirect to={`/classify/${questionId}`} noThrow />;
  }
  return (
    <Centered>
      <Card>
        <CardMedia image={BrokenCeramicsImage} title="Broken ceramics" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Error
          </Typography>
          <Typography component="p" paragraph>
            This should not have happened, we are eternally sorry. One common
            reason for this error might be a filter combination that is not
            supported. If the problem persists, please contact the
            administrator. <br />
            <b>{admin.adminContact}</b>
          </Typography>
          <OnlyAdmin>
            {map(errors, e => (
              <Typography component="p" paragraph variant="body2">
                {e.message}
              </Typography>
            ))}
          </OnlyAdmin>
        </CardContent>

        <CardActions>
          <ChangeFilterButton />
        </CardActions>
      </Card>
    </Centered>
  );
}

export default enhance(ErrorPage);
