import React from "react";
// import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

// const Container = styled.div`
//   height: 95vh;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

export default function LoaderPage() {
  return (
    <div
      style={{
        height: "70vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress size={90} />
    </div>
  );
}
