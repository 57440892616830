import React, { memo } from "react";
import Sticky from "react-stickynode";
import styled from "styled-components";
import { Field } from "react-final-form";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Divider from "@material-ui/core/Divider";
import {
  SentimentSatisfiedAlt as SentimentSatisfied,
  SentimentVeryDissatisfied as SentimentDissatisfied,
} from "@material-ui/icons";

import TextFieldField from "../../components/textfield-field";
import { renderStateButton } from "../../components/state-button";

import { SentimentNeutral } from "../../components/icons";
import NavigationActions from "./navigation-actions";

import InfoChips from "./info-chips";

const StickyContent = styled(CardContent)`
  .active-sticky & {
    /* background-color: pink; */
    ${({ theme }) => {
      return {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
      };
    }};
  }
`;

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  divider: {
    flexGrow: 1,
  },
  textField: {
    width: "100%",
  },
});

export function TextContent({
  classes,
  id,
  language,
  bu,
  npsScore,
  batch,
  disabled,
  status,
  hasValidationErrors,
  submitNext,
  submitPrevious,
}) {
  return (
    <>
      <CardContent>
        <InfoChips
          id={id}
          language={language}
          bu={bu}
          nps={npsScore}
          batch={batch}
          status={status}
        />
      </CardContent>
      <Sticky enabled top={64} innerZ={1000} activeClass="active-sticky">
        <StickyContent>
          <Field
            render={TextFieldField}
            name="text"
            className={classes.textField}
            label="Text"
            multiline
            rowsMax="4"
            rows={3}
            margin="normal"
            variant="outlined"
            disabled={disabled}
          />
        </StickyContent>
      </Sticky>
      <Divider />
      <CardActions>
        <Field
          render={renderStateButton}
          name="sentiment"
          type="radio"
          value={1}
          aria-label="Positive"
          label={<SentimentSatisfied />}
          disabled={disabled}
        />
        <Field
          render={renderStateButton}
          name="sentiment"
          type="radio"
          value={0}
          aria-label="Neutral"
          label={<SentimentNeutral />}
          disabled={disabled}
        />
        <Field
          render={renderStateButton}
          name="sentiment"
          type="radio"
          value={-1}
          aria-label="Negative"
          label={<SentimentDissatisfied />}
          disabled={disabled}
        />
        <Field
          render={renderStateButton}
          type="checkbox"
          name="issue"
          aria-label="Issue"
          label="Issue"
          disabled={disabled}
        />
        <Field
          render={renderStateButton}
          type="checkbox"
          name="storyline"
          aria-label="Issue"
          label="Storyline"
          disabled={disabled}
        />
        <div className={classes.divider} />
        <NavigationActions
          disabled={hasValidationErrors}
          next={submitNext}
          previous={submitPrevious}
        />
      </CardActions>
    </>
  );
}

export default memo(withStyles(styles)(TextContent));
