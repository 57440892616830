import copy from "clipboard-copy";
import isString from "lodash/isString";
import replace from "lodash/replace";

const NEW_LINE = "\r\n";

export function copyValues(values) {
  const rows = values
    .map(cell => {
      if (isString(cell)) {
        const cleaned = replace(replace(cell, /\r\n/g, " "), /\n/g, " ");
        return cleaned;
      }
      return cell;
      // console.log(cell.indexOf("\r"), cell.indexOf("\n"), cell.indexOf("\r\n"));
      // console.log(cell.charCodeAt(cell.indexOf("\n")));
    })
    .join(NEW_LINE);
  copy(rows);
}
