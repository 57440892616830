import firebase from "firebase/app";
import { firebase as fbConfig } from "./config";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

const app = firebase.initializeApp(fbConfig);
const firestore = app.firestore();
const settings = {};
firestore.settings(settings);
// app.auth().languageCode = 'de'

//firebase.firestore.setLogLevel('debug')
window.firebase = app;

export const getTextRef = (questionId, textId) =>
  firestore
    .collection("questions")
    .doc(questionId)
    .collection("texts")
    .doc(textId);

export const recalcStats = firebase.functions().httpsCallable("recalcStats");
export const listUsers = firebase.functions().httpsCallable("listUsers");
export const updateUser = firebase.functions().httpsCallable("updateUser");
export const copyClassification = firebase
  .functions()
  .httpsCallable("copyClassification");

export const openTexts = firebase.functions().httpsCallable("openTexts");

export default app;
