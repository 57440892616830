import React from "react";
import TextField from "@material-ui/core/TextField";

export default function({ input: { onChange, ...inputRest }, ...rest }) {
  const wrappedOnChange =
    rest.type === "number"
      ? e => {
          onChange(parseInt(e.target.value, 10) || 0);
        }
      : onChange;
  return <TextField onChange={wrappedOnChange} {...inputRest} {...rest} />;
}
