import firebase from "../firebase";
import format from "date-fns/format";
import head from "lodash/head";
import _get from "lodash/get";

export const CREATED = "0_CREATED";
export const UPLOADED = "0_UPLOADED";
export const PROCESSING = "1_PROCESSING";
export const ERROR = "2_ERROR";
export const SUCCESS = "3_SUCCESS";

export const STATUS = {
  [CREATED]: "CREATED",
  [UPLOADED]: "UPLOADED",
  [PROCESSING]: "PROCESSING",
  [ERROR]: "ERROR",
  [SUCCESS]: "SUCCESS"
};

const datePrefix = () => format(new Date(), "YY-MM-DD HH:MM");

export const downloadExport = async path => {
  try {
    const ref = firebase.storage().ref(path);
    const link = await ref.getDownloadURL();
    window.open(link, "_blank");
  } catch (e) {
    console.error(e);
  }
};

export const uploadJob = async (questionId, files, raw) => {
  const storageRef = firebase.storage().ref();
  const db = firebase.firestore();
  try {
    const file = head(files);
    const targetFile = `${datePrefix()} ${file.name}`;
    const targetPath = `uploads/${targetFile}`;
    const target = storageRef.child(targetPath);

    await target.put(file);

    await db.collection("uploads").add({
      date: new Date(),
      questionId,
      targetPath,
      status: UPLOADED,
      raw: !!raw,
      deleted: false
    });
  } catch (e) {
    console.log(e.message);
    return Promise.reject({
      file: e.message
    });
  }
};

export const downloadJob = async questionId => {
  const db = firebase.firestore();
  try {
    const user = _get(
      firebase.auth(),
      "currentUser.displayName",
      _get(firebase.auth(), "currentUser.email")
    );
    await db.collection("downloads").add({
      date: new Date(),
      questionId,
      user,
      status: CREATED,
      deleted: false
    });
  } catch (e) {
    console.log(e.message);
    return Promise.reject({
      question: e.message
    });
  }
};
