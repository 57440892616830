import SubCategorySelect from "../../summary/steps/category-select";
import {
  // npsByCountry,
  // npsByDivision,
  // npsByDivisionSplitYears,
  // npsByDivisionSplitYearsIdentical
  // selectBase,
  sumByGroup,
  filter,
  addSummaryRows,
  viewSplitYear,
  viewSplitYearColumns,
  viewSplitYearColumnsIdentical,
  viewDistributionColumns,
  sumIdentical,
  viewIdenticalDevelopment,
  viewColumnsIdenticalDevelopment,
  viewCombineYears,
  viewCombineYearsColumns,
  viewCategoryCombineYearsColumns,
  // viewCompetitorYearsColumns,
  translateCountry,
  sumGlobalCategory,
  sumSubCategory,
  sumContinents,
  getCountries,
  // sumCompetitors
  sortByBU
} from "./results-calculation";
import omit from "lodash/omit";
import reverse from "lodash/reverse";
// Only for debugging
import getOpco from "./opco-selectors";

const makeCopyChange = name => (data, column) => {
  if (column.name === name) {
    const cleaned = filter(data, (val, index) => {
      const filterOut = val === "-" && index % 2 === 1;
      return !filterOut;
    });
    return cleaned;
  }
  return data;
};

export const divOpcoSteps = [
  {
    id: "global_byBU",
    title: ({ year, opco }) => `${opco} NPS Development ${year - 1} - ${year}`,
    subTitle: () => "NPS by Business Unit",
    shortTitle: () => "NPS Business Unit",
    process: {
      transform: (state, { opco }) => {
        const data = getOpco(state, opco);
        const combined = sumByGroup(data, "bu");
        const withHeader = addSummaryRows(combined, { bu: opco });
        const sorted = sortByBU(withHeader, opco);
        return viewSplitYear(sorted, "bu", true);
      },
      columns: viewSplitYearColumns("bu", "Business Unit"),
      copyTransform: makeCopyChange("change")
    }
  },
  {
    id: "global_byBUDistribution",
    title: ({ year }) =>
      `NPS Distribution ${year - 1} and ${year} by Business Unit`,
    subTitle: () => "NPS distribution by Business Unit",
    shortTitle: () => "NPS Distribution by BU",
    process: {
      transform: (state, { opco }) => {
        const data = getOpco(state, opco);
        const combined = sumByGroup(data, "bu");
        const withHeader = addSummaryRows(combined, { bu: opco });
        const sorted = sortByBU(withHeader, opco);
        return viewSplitYear(sorted, "bu", true);
      },
      columns: viewDistributionColumns()
    }
  },
  {
    id: "global_byBU_identical",
    title: ({ year, opco }) =>
      `${opco}: NPS of customers interviewed in ${year - 1} and ${year}`,
    subTitle: ({ year }) => `NPS by BU - identical participants`,
    shortTitle: () => "NPS by BU for identical",
    process: {
      transform: (state, { opco }) => {
        const data = getOpco(state, opco);
        const combined = sumByGroup(data, "bu");
        const withHeader = addSummaryRows(combined, { bu: opco });
        const sorted = sortByBU(withHeader, opco);
        return viewSplitYear(sorted, "bu", true);
      },
      columns: viewSplitYearColumnsIdentical("bu", "Business Unit"),
      copyTransform: makeCopyChange("identical_change")
    }
  },
  {
    id: "global_byBU_identical_development",
    title: ({ year, opco }) =>
      `${opco}: NPS Development of identical customers ${year - 1} - ${year}`,
    subTitle: ({ year }) =>
      `Development of customers that were suveyed both in ${year -
        1} and ${year}`,
    shortTitle: () => "NPS Development for Identical",
    process: {
      transform: (state, { lc, opco }) => {
        const data = getOpco(state, opco);
        const sum = sumIdentical(data);
        const aggregated = viewIdenticalDevelopment(sum);
        return aggregated;
      },
      columns: viewColumnsIdenticalDevelopment()
    }
  },
  {
    id: "global_bylc",
    title: ({ year, opco }) =>
      `${opco}: NPS ${year} by Lead Country & Corporate Country`,
    subTitle: () => `NPS by Lead Country`,
    shortTitle: () => "NPS by Lead Country",
    process: {
      transform: (state, { lc, opco }) => {
        const countries = getCountries(state);
        const data = getOpco(state, opco);
        const withoutCountry = data.map(d => omit(d, "country"));
        const combined = sumByGroup(withoutCountry, "lc");
        const result = viewCombineYears(combined, "lc");
        const withContinents = sumContinents(result, countries).map(continent =>
          continent.map(translateCountry)
        );
        return withContinents;
      },
      columns: viewCombineYearsColumns("country", "Lead Country" /*true*/)
    }
  },
  {
    id: "global_improvement",
    title: ({ year, opco }) =>
      `${opco}: Improvement Areas: Development ${year - 1} - ${year}`,
    shortTitle: () => "Improvement Areas",
    process: {
      transform: (state, { lc, opco }) => {
        const base = getOpco(state, opco, "byDivArea");
        const data = sumGlobalCategory(base);
        return data;
      },
      columns: viewCategoryCombineYearsColumns()
    }
  },
  {
    id: "global_improvement_details",
    title: ({ year, opco }) =>
      `${opco}: Improvement Areas Details: Development ${year - 1} - ${year}`,
    shortTitle: () => "Improvement Areas Details",
    process: {
      transform: (state, { lc, main, mainCategory, opco }) => {
        const base = getOpco(state, opco, "byDivArea");
        const data = sumSubCategory(base, mainCategory);
        return data;
      },
      columns: viewCategoryCombineYearsColumns()
    },
    top: SubCategorySelect
  },
  {
    id: "global_improvement_subcat",
    title: ({ year, opco }) =>
      `${opco}: Improvement Areas (subcat slide): Development ${year -
        1} - ${year}`,
    shortTitle: () => "Improvement Areas for Subcat Slide",
    process: {
      transform: (state, { lc, opco }) => {
        const base = getOpco(state, opco, "byDivArea");
        const data = sumGlobalCategory(base, null, true);
        return reverse(data);
      },
      columns: viewCategoryCombineYearsColumns()
    }
  }
  //   {
  //   id: "global_competitor_top",
  //   title: ({ year, opco }) => `${opco}: All and main Competitors by BU`,
  //   shortTitle: ({ year, opco }) => `Top Competitors by BU`
  // },
  // {
  //   id: "global_competitor_better",
  //   title: ({ year, opco }) => `${opco}: All and main Competitors by BU`,
  //   subTitle: ({ opco }) => `Where is competitor better than ${opco}?`,
  //   shortTitle: ({ year, opco }) => `Where is competitor better?`
  // }
];
export const buOpcoSteps = [
  // {
  //   id: "bu_byBU",
  //   title: ({ year, opco }) => `${opco} NPS Development ${year - 1} - ${year}`,
  //   subTitle: () => "NPS by Business Unit",
  //   shortTitle: () => "NPS Business Unit (COPY)"
  // },
  // {
  //   id: "bu_byBUDistribution",
  //   title: ({ year }) =>
  //     `NPS Distribution ${year - 1} and ${year} by Business Unit`,
  //   subTitle: () => "NPS distribution by Business Unit",
  //   shortTitle: () => "NPS Distribution by BU (COPY)"
  // },
  // {
  //   id: "bu_byBU_identical",
  //   title: ({ year, opco }) =>
  //     `${opco}: NPS of customers interviewed in ${year - 1} and ${year}`,
  //   subTitle: ({ year }) => `NPS by BU - identical participants`,
  //   shortTitle: () => "NPS by BU for identical (COPY)"
  // },
  {
    id: "bu_byBU_identical_development",
    title: ({ year, opco }) =>
      `${opco}: NPS Development of identical customers ${year - 1} - ${year}`,
    subTitle: ({ year }) =>
      `Development of customers that were suveyed both in ${year -
        1} and ${year}`,
    shortTitle: () => "NPS Development for Identical",
    process: {
      transform: (state, { lc, opco, bu }) => {
        const data = getOpco(state, opco);
        const filtered = filter(data, { bu });
        const sum = sumIdentical(filtered);
        const aggregated = viewIdenticalDevelopment(sum);
        return aggregated;
      },
      columns: viewColumnsIdenticalDevelopment()
    }
  },
  {
    id: "bu_bylc",
    title: ({ year, opco, bu }) =>
      `${bu}: NPS ${year} by Lead Country & Corporate Country`,
    subTitle: () => `NPS by Lead Country`,
    shortTitle: () => "NPS by Lead Country",
    process: {
      transform: (state, { lc, opco, bu, ...other }) => {
        const data = getOpco(state, opco);
        const filtered = filter(data, { bu });
        const withoutCountry = filtered.map(d => omit(d, "country"));
        const combined = sumByGroup(withoutCountry, "lc");

        const result = viewCombineYears(combined, "lc");
        const withContinents = sumContinents(result).map(continent =>
          continent.map(translateCountry)
        );
        return withContinents;
      },
      columns: viewCombineYearsColumns("country", "Lead Country" /*true*/)
    }
  },
  {
    id: "bu_improvement",
    title: ({ year, opco, bu }) =>
      `${bu}: Improvement Areas: Development ${year - 1} - ${year}`,
    shortTitle: () => "Improvement Areas",
    process: {
      transform: (state, { lc, opco, bu }) => {
        const base = state.byDivMain[opco];
        const forBu = filter(base, { bu });
        const data = sumGlobalCategory(forBu);
        return data;
      },
      columns: viewCategoryCombineYearsColumns()
    }
  },
  {
    id: "bu_improvement_details",
    title: ({ year, opco }) =>
      `${opco}: Improvement Areas Details: Development ${year - 1} - ${year}`,
    shortTitle: () => "Improvement Areas Details",
    process: {
      transform: (state, { lc, main, mainCategory, opco, bu }) => {
        const base = state.byDivMain[opco];
        const forBu = filter(base, { bu });

        const data = sumSubCategory(forBu, mainCategory);
        return data;
      },
      columns: viewCategoryCombineYearsColumns()
    },
    top: SubCategorySelect
  },
  {
    id: "bu_improvement_subcat",
    title: ({ year, opco, bu }) =>
      `${bu}: Improvement Areas (Subcat Slide): Development ${year -
        1} - ${year}`,
    shortTitle: () => "Improvement Areas (Subcat Slide)",
    process: {
      transform: (state, { lc, opco, bu }) => {
        const base = state.byDivMain[opco];
        const forBu = filter(base, { bu });
        const data = sumGlobalCategory(forBu, null, true);
        return reverse(data);
      },
      columns: viewCategoryCombineYearsColumns()
    }
  }
];

// export const globalOpcoKeys = globalOpcoSteps.map(s => s.id);
// export const subOpcoKeys = subOpcoSteps.map(d => d.id);
// export const mainOpcoStepKeys = ["global"]; //.concat(divisions.map(d => d.id));
// export const subOpcoStepKeys = mainKey =>
//   mainKey === "global" ? globalOpcoSteps.map(d => d.id) : subOpcoKeys;
