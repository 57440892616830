import React from "react";
import StateButton from "./StateButton";

export default function({
  input: { onChange, checked, value, ...restInput },
  label,
  children,
  type,
  ...rest
}) {
  const onClick = () => {
    const nextVal = type === "checkbox" ? !value : checked ? null : value;
    onChange(nextVal);
  };
  return (
    <StateButton onClick={onClick} selected={checked} {...restInput} {...rest}>
      {label || children}
    </StateButton>
  );
}
