import React, { memo } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

// I am usually only CHANGIN the checked
const areEqual = (prevProps, nextProps) =>
  prevProps.input.checked === nextProps.input.checked &&
  prevProps.disabled === nextProps.disabled;

export const ListCheckboxField = memo(function({
  input: { onChange, value, checked, ...restInput },
  meta,
  label,
  classes,
  dense,
  disabled,
  ...rest
}) {
  if (rest.type !== "checkbox") {
    console.warn("CheckboxField needs to be type=checkbox");
  }
  const onClick = () => {
    if (disabled) {
      return;
    }
    onChange({ target: { type: rest.type, value, checked: !checked } });
  };
  return (
    <ListItem
      role={undefined}
      button={!disabled}
      onClick={onClick}
      dense={dense}
    >
      <Checkbox
        tabIndex={-1}
        disableRipple
        style={{ padding: 0 }}
        onChange={onChange}
        checked={checked}
        value={value}
        disabled={disabled}
        {...restInput}
      />
      <ListItemText primary={label} />
    </ListItem>
  );
},
areEqual);

export default ListCheckboxField;
