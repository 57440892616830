import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { getQueryName } from "redux-firestore/src/utils/query";
import { redirectTo } from "@reach/router";
import head from "lodash/head";
import _get from "lodash/get";
import { filterConditions } from "../store/filter";

import {
  getUrlState,
  setWindowCriteria,
  getWorklist,
  previous
} from "../store/worklist";

const subPages = ["error", "no-result", "completed"];

const enhance = compose(
  connect((state, props) => {
    const conditions = filterConditions(state);
    const criteria = state.worklist.criteria;

    const reverse = !!criteria.endAt;
    const orderBy = reverse ? ["id", "desc"] : ["id"];
    const startAt = reverse ? criteria.endAt : criteria.startAt;
    const listener = {
      collection: `questions/${props.questionId}/texts`,
      orderBy,
      storeAs: `worklist/${props.questionId}`,
      limit: 30,
      where: conditions
    };
    if (startAt) {
      listener.startAt = startAt;
    }
    return {
      criteria,
      conditions,
      query: listener
    };
  }),
  // Potentially willMount setting StartAt (if directly loaded in the middle)
  firestoreConnect(props => {
    return [props.query];
  }),
  connect(
    (state, props) => {
      const queryName = getQueryName(props.query);
      return {
        error: _get(state.firestore.errors.byQuery, queryName),
        worklist: getWorklist(state),
        criteria: state.worklist.criteria,
        delayPrevious: state.worklist.delayPrevious,
        currentLoaded: state.worklist.meta.loaded
      };
    },
    { setWindowCriteria, previous }
  ),
  withProps(() => getUrlState()),
  lifecycle({
    componentDidUpdate() {
      const {
        worklist,
        questionId,
        textId,
        completed,
        setWindowCriteria,
        criteria,
        previous,
        delayPrevious,
        currentLoaded,
        error
      } = this.props;
      // No text Selected
      // console.log("--------");
      // console.log("isLoaded", isLoaded(worklist));
      // console.log("isEmpty", isEmpty(worklist));
      // console.log("completed", completed);
      // console.log("currentLoaded", currentLoaded);

      const subUrl = this.props["*"];
      const notOnSubPage = subPages.indexOf(subUrl) === -1;

      if (
        !textId &&
        isLoaded(worklist) &&
        !isEmpty(worklist) &&
        !completed &&
        currentLoaded
      ) {
        const firstText = head(worklist);
        const firstId = _get(firstText, "id");
        const next = `/classify/${questionId}/text/${firstId}`;
        console.log(":::::REDIRECT ---> first text");
        return redirectTo(next);
      } else if (
        // Opened in the middle, need to move window to current record
        isLoaded(worklist) &&
        !worklist.find(t => t.id === textId) &&
        criteria.startAt !== textId
      ) {
        console.log("CURRENT ITEM IS NOT IN WORKLIST");
        setWindowCriteria({ startAt: textId });
      } else if (delayPrevious) {
        previous();
      } else if (
        !textId &&
        isLoaded &&
        isEmpty &&
        currentLoaded &&
        notOnSubPage
      ) {
        console.log(":::::REDIRECT ---> No Results");
        return redirectTo(`/classify/${questionId}/no-result`);
      } else if (error && !textId && notOnSubPage) {
        console.log(":::::REDIRECT ---> Error");
        return redirectTo(`/classify/${questionId}/error`);
      }
    }
  })
);

export default enhance;
