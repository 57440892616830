import React from "react";
import ErrorPage from "./error-page";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (error && error.uri) {
      return {};
    }
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // REdirectRequest
    if (error && error.uri) {
      return;
    }
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage errors={[this.state.error]} />;
    }
    return this.props.children;
  }
}
