import React from "react";
import Button from "@material-ui/core/Button";
import { FilterContainer } from "./filter-button";

export function ChangeFilterButton({ openFilter }) {
  return (
    <Button size="medium" color="primary" onClick={() => openFilter()}>
      Change Filter
    </Button>
  );
}

export default FilterContainer(ChangeFilterButton);
