import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dropzone from "../components/dropzone";
import Divider from "@material-ui/core/Divider";
import OnlyAdmin from "../common/only-admin";

import { loadTestFixtures, loadFile } from "../store/results";

const styles = theme => ({
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  container: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  content: {
    height: "100%"
  },
  dropzone: {
    width: 600,
    height: 300
  }
});

export function LoadResultData({
  classes,
  loadTestFixtures,
  loadFile,
  isLoading,
  loaded,
  progress
}) {
  const [uploadCloud, setUploadCloud] = useState(false);
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardHeader title="Upload Data" />
        <Divider />
        <CardContent>
          <Dropzone
            isLoading={isLoading}
            isFinished={loaded && !isLoading}
            className={classes.dropzone}
            multiple={false}
            progress={progress}
            onDrop={acceptedFiles => {
              if (acceptedFiles.length > 0) {
                console.log(acceptedFiles);
                const file = acceptedFiles[0];
                loadFile(file, uploadCloud);
              }
            }}
          />
        </CardContent>
        <CardActions>
          <OnlyAdmin>
            <FormControlLabel
              style={{ marginLeft: 8 }}
              control={
                <Checkbox
                  checked={uploadCloud}
                  onChange={e => setUploadCloud(!!e.target.checked)}
                  name="upload"
                />
              }
              label="Upload for Everyone"
            />
            <div style={{ flexGrow: 1 }} />
          </OnlyAdmin>
          {/* <Button
            color="primary"
            onClick={loadTestFixtures}
            disabled={isLoading}
          >
            Load Test Data
            <CloudUploadIcon className={classes.rightIcon} />
          </Button> */}
        </CardActions>
      </Card>
    </div>
  );
}

export default withStyles(styles)(
  connect(
    state => ({
      isLoading: state.results.isLoading || state.results.isCloudUploading,
      loaded: state.results.loaded,
      progress: state.results.progress
    }),
    { loadTestFixtures, loadFile }
  )(LoadResultData)
);
