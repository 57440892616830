import React from "react";
import { isEmpty } from "react-redux-firebase";

import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadIcon from "@material-ui/icons/CloudUpload";
import ProgressIcon from "@material-ui/icons/Loop";
import SuccessIcon from "@material-ui/icons/DoneOutline";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import { withStyles } from "@material-ui/core/styles";

import TaskContainer from "./tasks-container";

import { UPLOADED, PROCESSING, ERROR, SUCCESS } from "../../store/upload";

const STATUS_ICONS = {
  [UPLOADED]: UploadIcon,
  [PROCESSING]: ProgressIcon,
  [ERROR]: ErrorIcon,
  [SUCCESS]: SuccessIcon
};

const COLORS = {
  [UPLOADED]: blue[300],
  [PROCESSING]: blue[300],
  [ERROR]: red[500],
  [SUCCESS]: green[500]
};

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative"
  },
  fabProgress: {
    color: blue[500],
    position: "absolute",
    // top: -6,
    // left: -6,
    zIndex: 1
  }
});
/**
 * Inspiration: https://dribbble.com/shots/5356083-File-dropzone/attachments/1161596
 */
const UploadTask = withStyles(styles)(({ task, archive, classes }) => {
  const { status } = task;
  const Icon = STATUS_ICONS[status];
  const color = COLORS[status];
  const progress = [UPLOADED, PROCESSING].indexOf(status) > -1;
  return (
    <Card style={{ margin: "16px 8px" }}>
      <CardHeader
        avatar={
          <div className={classes.wrapper}>
            {progress && <CircularProgress className={classes.fabProgress} />}
            <Avatar style={{ backgroundColor: color }}>
              <Icon />
            </Avatar>
          </div>
        }
        action={
          <IconButton onClick={archive}>
            <CloseIcon />
          </IconButton>
        }
        title={task.targetPath}
        subheader={task.error || task.questionId}
      />
    </Card>
  );
});

function UploadTasksList({ tasks, archive }) {
  if (isEmpty(tasks)) {
    return null;
  }
  return tasks.map(t => (
    <UploadTask key={t.id} task={t} archive={() => archive(t.id)} />
  ));
}

export default TaskContainer("uploads")(UploadTasksList);
