import React, { memo } from "react";
import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";
import MessageBar from "../../components/message-bar";
import size from "lodash/size";
import filter from "lodash/filter";

const ErrorList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-inline-start: 12px;
`;

const ErrorItem = styled.li``;

export function ErrorSnackbar({ errors }) {
  const open = size(errors) > 0;
  const msg = filter(errors, i => !!i);
  const message = (
    <ErrorList>
      {msg.map(m => (
        <ErrorItem>{m}</ErrorItem>
      ))}
    </ErrorList>
  );
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
    >
      <MessageBar variant="warning" message={message} />
    </Snackbar>
  );
}

export default memo(ErrorSnackbar);
