import React, { memo } from "react";
import ClassesContainer from "./classes-container";
import ClassGroup from "./class-group";
import Grid from "@material-ui/core/Grid";
import LoaderPage from "../../components/loader-page";
import size from "lodash/size";

const areEqual = (prevProps, nextProps) =>
  size(prevProps.classes) === size(nextProps.classes) &&
  prevProps.disabled === nextProps.disabled;

const AllClasses = memo(function({ classes, disabled }) {
  if (!classes) {
    return <LoaderPage />;
  }
  return (
    <Grid container spacing={16} style={{ marginTop: 16 }}>
      {classes.map(top => (
        <Grid
          item
          key={top.id}
          s
          justify="space-evenly"
          style={{ minWidth: 270, width: "16.6%" }}
          alignContent="stretch"
        >
          <ClassGroup
            titleStyle={top.style}
            title={top.name}
            subClasses={top.children}
            disabled={disabled}
          />
        </Grid>
      ))}
    </Grid>
  );
}, areEqual);

const Wrapper = props => <AllClasses {...props} />;

export default ClassesContainer(Wrapper);
