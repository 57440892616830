import { useEffect } from "react";
import { getTextRef } from "../../firebase";
import { isAdmin, isClient } from "../../store/user";
import { canClaim } from "../../store/worklist";

function useLock(questionId, text, user) {
  useEffect(
    () => {
      if (!text.id || !canClaim(user.id, text)) {
        return;
      }
      const ref = getTextRef(questionId, text.id);
      console.time(`LOCK_${text.id}`);
      const lockUpdate = {
        lockedAt: new Date(),
        lockedBy: user.id
      };
      if (isClient(user) && !text.client) {
        lockUpdate.client = user.id;
        lockUpdate.clientAt = new Date();
      } else if (isAdmin(user) && !text.admin) {
        lockUpdate.admin = user.id;
        lockUpdate.adminAt = new Date();
      }
      ref.update(lockUpdate).then(() => console.timeEnd(`LOCK_${text.id}`));
      return () => {
        console.time(`UNLOCK_${text.id}`);
        ref
          .update({
            lockedAt: null,
            lockedBy: null
          })
          .then(() => console.timeEnd(`UNLOCK_${text.id}`));
      };
    },
    [questionId, text.id, user.id]
  );
}

export default useLock;
