import React from "react";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { useNavigateToLoad } from "./summary-card";
import DokuLoader from "./doku-loader";

import LeadCountryList from "./lc-list";
import OpcoList from "./opco-list";

export default function SummaryStart() {
  return (
    <DokuLoader>
      <Grid container spacing={24}>
        <Grid item style={{ width: "100%" }}>
          <Card>
            <CardHeader
              title="Select the OpCo"
              subheader="to get started analyzing the data"
            />
            <Divider />
            <CardContent>
              <OpcoList />
            </CardContent>
          </Card>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Card>
            <CardHeader
              title="Select the lead country"
              subheader="to get started analyzing the data"
            />
            <Divider />
            <CardContent>
              <LeadCountryList />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DokuLoader>
  );
}
