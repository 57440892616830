import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export default function Header() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell align="right">Total</TableCell>
        <TableCell align="right">Open</TableCell>
        <TableCell align="right">Done</TableCell>
        <TableCell align="right">%</TableCell>
        <TableCell align="right">Problem</TableCell>
        <TableCell align="right">Checked</TableCell>
      </TableRow>
    </TableHead>
  );
}
