import React, { useEffect } from "react";
import { mainCategories } from "../../store/results/data/classification";
import take from "lodash/take";
import { navigate } from "@reach/router";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  row: {
    display: "flex",
    justifyContent: "space-around"
  }
});

export function CategorySelect({ categories, mainCategory }) {
  useEffect(() => {
    if (!mainCategory) {
      navigate(`?mainCategory=${categories[0]}`);
    }
  }, [mainCategory]);
  const classes = useStyles();
  return (
    <div className={classes.row}>
      {categories.map(c => (
        <Chip
          onClick={() => navigate(`?mainCategory=${c}`)}
          color="primary"
          variant={c === mainCategory ? null : "outlined"}
          label={c}
        />
      ))}
    </div>
  );
}

CategorySelect.defaultProps = {
  categories: take(mainCategories, 4)
};

export default CategorySelect;
