import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Language } from "@material-ui/icons";
import divisionNames from "../store/results/data/divisions";

import ButtonMenu from "../components/button-menu";
import { getUrlContext, selectOpco } from "../store/results/step-navigation";
import map from "lodash/map";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 4
  }
});

export function OpcoSelector({ classes, divisions }) {
  const { opco } = getUrlContext();
  const selectedOpco = divisions[opco];
  return (
    <div className={classes.root}>
      <ButtonMenu
        icon={<Language style={{ marginRight: 8 }} />}
        label={`${divisionNames[opco]} (${opco})`}
        inputProps={{
          name: "lead-country",
          id: "lead-country"
        }}
      >
        {map(divisions, (bus, op) => (
          <MenuItem key={op} onClick={() => selectOpco(op)}>
            {divisionNames[op]} ({op})
          </MenuItem>
        ))}
      </ButtonMenu>
    </div>
  );
}

export default withStyles(styles)(OpcoSelector);
