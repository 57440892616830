import React from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";
import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import QuestionStatusStats from "./question-status-stats";

const Row = styled(TableRow)`
  cursor: pointer;
`;

export default function QuestionRow({ name, link }) {
  const id = link ? link.id : "";
  return (
    <Row hover onClick={() => navigate(`/classify/${id}`)}>
      <TableCell>{name}</TableCell>
      <QuestionStatusStats questionId={id} />
    </Row>
  );
}

QuestionRow.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};
