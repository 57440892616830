import React from "react";

export function ActivateAccountPage() {
  return (
    <div>
      You're account is not activated, please contact the administrator to
      activate it.
    </div>
  );
}

export default ActivateAccountPage;
