import React from "react";
import { Form, Field } from "react-final-form";
import { compose } from "recompose";
import { withFirebase } from "react-redux-firebase";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import LoadingButton from "../components/loading-button";
import TextField from "../components/textfield-field";
import _get from "lodash/get";

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const enhance = compose(
  withFirebase,
  withStyles(styles),
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  }))
);

function SignIn(props) {
  const { classes, firebase, children } = props;

  const login = values => firebase.login(values);

  return (
    <Form onSubmit={login}>
      {({ handleSubmit, pristine, submitting, submitErrors }) => (
        <>
          <CssBaseline />
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              {children}
              <form className={classes.form}>
                {_get(submitErrors, "message")}
                <Field
                  name="email"
                  label="Email Address"
                  component={TextField}
                  required
                  fullWidth
                  margin="normal"
                  autoComplete="email"
                  autoFocus
                />
                <Field
                  name="password"
                  label="Password"
                  component={TextField}
                  type="password"
                  required
                  fullWidth
                  margin="normal"
                  autoComplete="current-password"
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                  disabled={pristine || submitting}
                  loading={submitting}
                >
                  Sign in
                </LoadingButton>
              </form>
            </Paper>
          </main>
        </>
      )}
    </Form>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default enhance(SignIn);
