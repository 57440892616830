import React from "react";
import { compose, branch, renderComponent } from "recompose";
import { connect } from "react-redux";
import { Redirect } from "@reach/router";
import LoaderPage from "../components/loader-page";
import { isAdmin } from "../store/user";

const RedirectLogin = () => <Redirect to="/login" noThrow />;
const RedirectHome = () => <Redirect to="/" noThrow />;

export const getAuth = connect(state => {
  const { auth, profile, isInitializing } = state.firebase;
  const res = {
    auth,
    profile,
    authenticating: isInitializing || !profile.isLoaded || !auth.isLoaded,
    authenticated: auth.isLoaded && !auth.isEmpty,
    rolesLoaded: state.user.isLoaded,
    roles: state.user.roles
  };
  return res;
});

const spinnerWhileLoading = isLoading =>
  branch(isLoading, renderComponent(LoaderPage));

const conditionalRedirect = (cond, comp) => branch(cond, renderComponent(comp));

const authenticatedRedirect = conditionalRedirect(
  props => !props.authenticating && !props.authenticated,
  RedirectLogin
);

export const isAuthenticated = compose(
  getAuth,
  spinnerWhileLoading(props => props.authenticating),
  authenticatedRedirect,
  spinnerWhileLoading(props => !props.rolesLoaded)
);

export const notAuthenticated = compose(
  getAuth,
  spinnerWhileLoading(props => props.authenticating),
  conditionalRedirect(
    props => !props.authenticating && props.authenticated,
    RedirectHome
  )
);

export const requireAdmin = compose(
  getAuth,
  spinnerWhileLoading(props => props.authenticating),
  authenticatedRedirect,
  spinnerWhileLoading(props => !props.rolesLoaded),
  conditionalRedirect(props => !isAdmin(props.roles), RedirectHome)
);
