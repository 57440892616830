import { listUsers, updateUser } from "../firebase";
import isEqual from "lodash/isEqual";
import update from "immutability-helper";

const PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;

export const linkToFunctionsLog = () =>
  `https://console.firebase.google.com/u/0/project/${PROJECT_ID}/functions/logs?search=&severity=DEBUG`;

export const linkToUserAdmin = () =>
  `https://console.firebase.google.com/u/0/project/${PROJECT_ID}/authentication/users`;

export const linkToFirestore = collection =>
  `https://console.firebase.google.com/u/0/project/${PROJECT_ID}/database/firestore/data~2F${collection}`;

const USERS_LOADED = "ADMIN:USERS_LOADED";
const USER_UPDATED = "ADMIN:USER_UPDATED";
const usersLoaded = users => ({
  type: USERS_LOADED,
  users
});

const userUpdated = user => ({
  type: USER_UPDATED,
  user
});

export const loadUsers = () => async dispatch => {
  const usersResponse = await listUsers();
  dispatch(usersLoaded(usersResponse.data));
};

export const changeUser = (uid, values) => async (dispatch, getState) => {
  const user = getState().admin.users.find(u => u.uid === uid);

  const userUpdate = {};
  let claims = false;

  if (values.displayName !== user.displayName) {
    userUpdate.displayName = values.displayName;
  }

  if (values.disabled !== user.disabled) {
    userUpdate.disabled = values.disabled;
  }
  const claimValues = values.roles.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});
  if (!isEqual(user.customClaims, claimValues)) {
    claims = claimValues;
  }

  const payload = { uid, userUpdate, claims };
  const updated = await updateUser(payload);
  dispatch(userUpdated(updated.data));
  return updated;
};

const initialState = {
  users: []
};
export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case USERS_LOADED:
      return {
        ...state,
        users: action.users
      };
    case USER_UPDATED: {
      const index = state.users.findIndex(u => u.uid === action.user.uid);
      return update(state, {
        users: {
          [index]: {
            $set: action.user
          }
        }
      });
    }
    default:
      return state;
  }
}
