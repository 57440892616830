import React from "react";
import { navigate } from "@reach/router";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import UserContainer from "./user-container";
import LoaderPage from "../../components/loader-page";
import { linkToUserAdmin } from "../../store/admin";

export const UserListPage = ({ users, children }) => {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} md={4}>
        <Paper>
          <List dense>
            <ListItem
              button
              component="a"
              href={linkToUserAdmin()}
              target="_blank"
            >
              <Avatar>FB</Avatar>
              <ListItemText primary="Firebase Admin" />
            </ListItem>
            <ListSubheader>Users</ListSubheader>
            {users.map(u => {
              const title = u.displayName || u.email;
              const subheader = u.displayName ? u.email : "";
              return (
                <ListItem
                  key={u.uid}
                  button
                  onClick={() => navigate(`/admin/users/${u.uid}`)}
                >
                  <Avatar src={u.photoURL} />
                  <ListItemText primary={title} secondary={subheader} />
                </ListItem>
              );
            })}
          </List>
          {users.length === 0 && <LoaderPage />}
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        {children}
      </Grid>
    </Grid>
  );
};

export default UserContainer(UserListPage);
