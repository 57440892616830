import React from "react";
import { Field } from "react-final-form";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import map from "lodash/map";
import _get from "lodash/get";

import SelectField from "../../components/select-field";

const enhance = connect((state, props) => {
  const byBatch = _get(
    state.firestore,
    `data.questions.${props.questionId}.byBatch`
  );
  return {
    batches: map(byBatch, (count, key) => ({
      id: key,
      label: key,
      count
    }))
  };
});

export function BatchDropdown({ batches }) {
  return (
    <Field
      name="batch"
      label="Batch"
      component={SelectField}
      controlProps={{ fullWidth: true }}
    >
      <MenuItem value={false}>
        <ListItemText primary="All" />
      </MenuItem>
      {map(batches, option => {
        return (
          <MenuItem value={option.id} key={option.id}>
            <ListItemText
              primary={option.label}
              secondary={`${option.count} comments`}
            />
          </MenuItem>
        );
      })}
    </Field>
  );
}

export default enhance(BatchDropdown);
