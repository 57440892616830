import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";

export const DialogForm = styled.div`
  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const variant = ({ variant }) => {
  switch (variant) {
    case "outlined":
      return {
        paddingTop: "16px"
      };
    case "selection":
      return {
        paddingTop: "12px"
      };
    default:
      return {
        paddingTop: "20px"
      };
  }
};

const FieldSetContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  & + & {
    margin-top: 32px;
  }
`;
const IconContainer = styled.div`
  padding-right: 16px;
  padding-left: 12px;
  color: ${grey[500]};
  ${variant};
`;

const FieldSetContent = styled(Grid)`
  legend {
    padding-top: 16px;
  }
`;

export const SectionTitle = styled(Typography)`
  padding-left: 52px;
  padding-top: 12px;
  padding-bottom: 12px;
`;
SectionTitle.defaultProps = {
  variant: "h6"
};

export const FieldSet = ({ icon, children, variant }) => {
  return (
    <FieldSetContainer>
      <IconContainer variant={variant}>{icon}</IconContainer>
      <FieldSetContent container spacing={16}>
        {React.Children.map(children, c => {
          const xs = c.props.size || 12;
          return (
            <Grid xs={xs} item>
              {c}
            </Grid>
          );
        })}
      </FieldSetContent>
    </FieldSetContainer>
  );
};
