import React from "react";
import { Field, Form } from "react-final-form";
import { Checkbox } from "final-form-material-ui";

import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import DropzoneField from "../../components/dropzone-field";
import { uploadJob } from "../../store/upload";
import ProjectsContainer from "./projects-container";

import QuestionSelect from "./question-select";

class UploadForm extends React.Component {
  upload(values) {
    const { question, file, raw } = values;
    return uploadJob(question.id, file, raw);
  }
  render() {
    const { projects } = this.props;
    return (
      <Form onSubmit={this.upload}>
        {({ handleSubmit, pristine, form, invalid, submitting }) => {
          return (
            <>
              <CardContent>
                <Grid container direction="column" spacing={32}>
                  <Grid item>
                    <QuestionSelect projects={projects} />
                  </Grid>
                  <Grid item>
                    <Field
                      label="File"
                      name="file"
                      component={DropzoneField}
                      isLoading={submitting}
                      required
                      validate={v => !v}
                    />
                  </Grid>
                  <Grid item>
                    <label>Raw Upload</label>
                    <Tooltip title="Only update fields without filling the status">
                      <Field
                        label="Raw Upload"
                        name="raw"
                        component={Checkbox}
                        type="checkbox"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={pristine || invalid}
                >
                  Upload
                </Button>
              </CardActions>
            </>
          );
        }}
      </Form>
    );
  }
}

UploadForm.defaultProps = {
  projects: []
};

export default ProjectsContainer(UploadForm);
