import React from "react"; //eslint-disable-line
import WorklistContainer from "./worklist-container";
import useElevProject from "./elev-project-hook";
// import DebugWorklist from "./debug-worklist";
import { onlyUpdateForKeys } from "recompose";

const enhance = onlyUpdateForKeys(["children", "worklist"]);
function ClassifyPage({ questionId, worklist, textId, children, error }) {
  useElevProject(questionId);
  //@TODO: Add Progress in the worklist container and then add it to the children props
  return (
    <>
      {children}
      {/* <DebugWorklist worklist={worklist} textId={textId} /> */}
    </>
  );
}

ClassifyPage.defaultProps = {
  worklist: []
};

export default WorklistContainer(enhance(ClassifyPage));
