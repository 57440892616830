import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";

export function SelectField({
  input: { value, onChange, ...restInput },
  meta: { touched, error, submitError },
  label,
  children,
  controlProps,
  labelProps,
  ...rest
}) {
  const e = touched && (error || submitError);
  return (
    <FormControl error={e} {...controlProps}>
      {label && (
        <InputLabel htmlFor={`id-${restInput.name}`} {...labelProps}>
          {label}
        </InputLabel>
      )}
      <Select
        value={value}
        onChange={onChange}
        inputProps={{
          id: `id-${restInput.name}`,
          name: restInput.name,
          ...restInput
        }}
        {...rest}
      >
        {children}
      </Select>
      {e && <FormHelperText>{e}</FormHelperText>}
    </FormControl>
  );
}

export default SelectField;
