import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import LoaderPage from "../../components/loader-page";
import MessageBar from "../../components/message-bar";

import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import map from "lodash/map";
import flatMap from "lodash/flatMap";

import ProjectRow from "./project-row";
import Header from "./project-table-header";
import MyProjectsContainer from "./my-projects-container";

export const ProjectTable = ({ projects }) => {
  if (!isLoaded(projects)) {
    return <LoaderPage />;
  }

  if (isEmpty(projects)) {
    return (
      <MessageBar
        variant="warning"
        message="No Projects found. You might not have access to any active projects."
      />
    );
  }

  return (
    <Paper>
      <Table>
        <Header />
        <TableBody>
          {map(projects, p => (
            <ProjectRow key={p.id} {...p} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default compose(
  MyProjectsContainer,
  // This is not needed for this component, but used fsomewhere else
  connect(state => {
    return {
      projects: state.firestore.ordered.projects
    };
  }),
  firestoreConnect(props => {
    const query = flatMap(props.projects, p =>
      p.questions.filter(q => q.link).map(q => `questions/${q.link.id}`)
    );
    return query;
  })
)(ProjectTable);
