import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { withFirestore } from "react-redux-firebase";
import _get from "lodash/get";
import flatMap from "lodash/flatMap";
import map from "lodash/map";
import sortBy from "lodash/sortBy";

function aggregateTopGroup(stats, classes) {
  // console.time("aggregateTopGroup");
  const res = sortBy(
    map(classes, main => {
      let mainCount = 0;
      const childrenWithCount = map(main.children, child => {
        const count = stats[child.id] || 0;
        mainCount = mainCount + count;
        return {
          ...child,
          count
        };
      });
      return {
        ...main,
        children: childrenWithCount,
        count: mainCount
      };
    }),
    "order"
  );
  // console.timeEnd("aggregateTopGroup");
  return res;
}

const ClassStatsContainer = connect(state => {
  const projects = _get(state, "firestore.ordered.projects", []);
  const questions = flatMap(projects, "questions");
  const relevantQuestion = questions.find(q => q.showStats);
  if (!relevantQuestion || !relevantQuestion.link) {
    console.log("no relevant question");
    return {
      fetchClasses: false
    };
  }
  const questionId = relevantQuestion.link.id;
  const classStats = _get(
    state,
    `firestore.data.questions.${questionId}.byClass`,
    {}
  );
  const allClasses = _get(
    state,
    `firestore.data.questions.${questionId}.classes`
  );
  if (!allClasses) {
    return {
      fetchClasses: true,
      questionId
    };
  }
  return {
    stats: aggregateTopGroup(classStats, allClasses)
  };
});

const enhance = compose(
  withFirestore,
  ClassStatsContainer,
  lifecycle({
    componentDidMount() {
      const { fetchClasses, firestore, questionId } = this.props;
      if (fetchClasses && questionId) {
        const path = `questions/${questionId}/classes`;
        firestore.get(path);
      }
    },
    componentDidUpdate(prevProps) {
      const { fetchClasses, questionId, firestore } = this.props;
      if (!prevProps.fetchClasses && fetchClasses) {
        const path = `questions/${questionId}/classes`;
        firestore.get(path);
      }
    }
  })
);

export default enhance;
