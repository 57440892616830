import React, { useState } from "react";
import { Form } from "react-final-form";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import QuestionSelect from "../upload/question-select";
import { copyClassification } from "../../firebase";
import LoadingButton from "../../components/loading-button";

export default function CopyClassificationButton({ questionId, projects }) {
  const [open, setOpen] = useState(false);
  const submit = async values => {
    const from = values.question.id;
    const to = questionId;
    await copyClassification({ from, to });
    setOpen(false);
  };
  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained">
        Import Classification
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Copy Classification Options from other Question
        </DialogTitle>
        <Form onSubmit={submit}>
          {({ handleSubmit, pristine, submitting }) => {
            return (
              <>
                <DialogContent>
                  <QuestionSelect projects={projects} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary">
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={submitting}
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Import
                  </LoadingButton>
                </DialogActions>
              </>
            );
          }}
        </Form>
      </Dialog>
    </>
  );
}
