import React from "react";
import { compose, pure, lifecycle } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import { firestoreConnect } from "react-redux-firebase";
import _get from "lodash/get";

import CardHeader from "@material-ui/core/CardHeader";

import MyProjectsContainer from "../../home/project-table/my-projects-container";
import FilterProgress from "../filter/filter-progress";
import DistributionLine from "./distribution-line";

import { calculateStats } from "../../store/stats";

const styles = {
  action: {
    margin: 0
  }
};

function TextHeader({
  hasValidationErrors,
  hasPrevious,
  submitNext,
  submitPrevious,
  progress,
  classes,
  projectName,
  questionName,
  questionId
}) {
  return (
    <>
      <CardHeader
        title={projectName}
        subheader={questionName}
        classes={{ action: classes.action }}
        action={<FilterProgress questionId={questionId} />}
      />
      <DistributionLine questionId={questionId} />
    </>
  );
}

const enhance = compose(
  pure,
  withStyles(styles),
  MyProjectsContainer,
  firestoreConnect(props => [`questions/${props.questionId}`]),
  connect((state, props) => {
    const { questionId } = props;
    const projects = _get(state, "firestore.ordered.projects", []);
    const p = projects.find(p =>
      p.questions.find(q => q.link.id === questionId)
    );
    const q = p && p.questions.find(q => q.link && q.link.id === questionId);
    const byStatus = _get(
      state,
      `firestore.data.questions.${questionId}.byStatus`,
      {}
    );
    const { percent } = calculateStats(byStatus);
    const error = _get(
      state,
      `firestore.errors.byQuery.questions/${props.questionId}`
    );
    return {
      error,
      projectName: _get(p, "name", ""),
      questionName: _get(q, "name", ""),
      progress: isNaN(percent) ? 0 : percent
    };
  }),
  lifecycle({
    componentDidMount() {
      const { error } = this.props;
      if (error) {
        console.log(error);
        navigate("/permission-denied");
      }
    },
    componentWillUpdate() {
      const { error } = this.props;
      if (error) {
        console.log(error);
        navigate("/permission-denied");
      }
    }
  })
);

export default enhance(TextHeader);
