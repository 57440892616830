import { navigate } from "@reach/router";
import queryString from "query-string";
import size from "lodash/size";
import last from "lodash/last";
import { globalSteps, divSteps } from "./steps";
import { divOpcoSteps, buOpcoSteps } from "./opco-steps";

const YEAR = 2019;

export const selectLC = lc =>
  navigate(`/summary/lc/${lc}/global/global_byCountry`);

export const selectOpco = opco =>
  navigate(`/summary/opco/${opco}/global/${divOpcoSteps[0].id}`);

export const getUrlParams = () => {
  const [
    none,
    s,
    byType,
    typePart,
    mainStepPart,
    subStepPart
    // eslint-disable-next-line
  ] = location.pathname.split("/");

  // eslint-disable-next-line
  const query = queryString.parse(location.search);
  return {
    byType,
    lc: byType === "lc" ? typePart.replace("%20", " ") : null,
    opco: byType === "opco" ? typePart : null,
    mainStep: decodeURIComponent(mainStepPart),
    subStep: decodeURIComponent(subStepPart),
    ...query
  };
};

export const getUrlContext = () => {
  const { lc, mainStep, ...rest } = getUrlParams();
  let context = {
    year: YEAR,
    lc,
    ...rest
  };
  if (rest.byType === "opco") {
    context.div = context.opco;
    if (mainStep !== "global") {
      context.bu = mainStep;
    }
  } else if (mainStep !== "global") {
    context.div = mainStep;
  }
  return context;
};

export const getStep = () => {
  const { byType, mainStep, subStep } = getUrlParams();
  if (byType === "opco" && mainStep === "global") {
    const opcoGlobal = divOpcoSteps.find(s => s.id === subStep);
    return opcoGlobal;
  } else if (byType === "opco") {
    return buOpcoSteps.find(s => s.id === subStep);
  } else if (mainStep === "global") {
    return globalSteps.find(s => s.id === subStep);
  }
  return divSteps.find(s => s.id === subStep);
};

// @Make this a 2 step process (second one with calculation and DEFER)
export const navigateStep = (mainStep, subStep) => {
  const { lc, byType, opco } = getUrlParams();
  if (byType === "lc") {
    return navigate(`/summary/${byType}/${lc}/${mainStep}/${subStep}`);
  }
  return navigate(`/summary/${byType}/${opco}/${mainStep}/${subStep}`);
};

export const navigateNextStep = (mainKeys, globalKeys, categoryKeys) => {
  const { mainStep, subStep } = getUrlParams();

  const currentSubKeys = mainStep === "global" ? globalKeys : categoryKeys;

  // Only Navigate Sub

  const currentSubIndex = currentSubKeys.indexOf(subStep);
  if (currentSubIndex < size(currentSubKeys) - 1) {
    return navigateStep(mainStep, currentSubKeys[currentSubIndex + 1]);
  }
  // Navigate Mainstep
  const currentMainStepIndex = mainKeys.indexOf(mainStep);
  if (currentMainStepIndex < size(mainKeys) - 1) {
    const nextMainstep = mainKeys[currentMainStepIndex + 1];
    return navigateStep(nextMainstep, categoryKeys[0]);
  }
};

export const navigatePrevStep = (mainKeys, globalKeys, categoryKeys) => {
  const { mainStep, subStep } = getUrlParams();
  const currentSubKeys = mainStep === "global" ? globalKeys : categoryKeys;

  // Only Navigate Sub
  const currentSubIndex = currentSubKeys.indexOf(subStep);
  if (currentSubIndex > 0) {
    return navigateStep(mainStep, currentSubKeys[currentSubIndex - 1]);
  }
  // Navigate Mainstep
  const currentMainStepIndex = mainKeys.indexOf(mainStep);
  if (currentMainStepIndex > 0) {
    const prevMainstep = mainKeys[currentMainStepIndex - 1];
    const prevSubSteps = prevMainstep === "global" ? globalKeys : categoryKeys;
    return navigateStep(prevMainstep, last(prevSubSteps));
  }
};
