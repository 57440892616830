import { createMuiTheme } from "@material-ui/core/styles";
import {
  // red,
  // pink,
  // purple,
  // deepPurple,
  // indigo,
  blue
  // lightBlue,
  // cyan,
  // teal,
  // green,
  // lightGreen,
  // lime,
  // yellow,
  // amber,
  // orange,
  // deepOrange,
  // brown
} from "@material-ui/core/colors";

// const chartPalette = [
//   red[300],
//   deepPurple[300],
//   lightBlue[300],
//   lime[300],
//   orange[300],
//   yellow[300],
//   purple[300]
// ];

const range = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50];

const chartPalette = range.map(key => blue[key]);

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  drawerWidth: 240,
  chartPalette
});

export default theme;
