import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import worklist from "./worklist";
import user from "./user";
import results from "./results";
import filter from "./filter";
import admin from "./admin";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  worklist,
  user,
  results,
  filter,
  admin
});

export default rootReducer;
