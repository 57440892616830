import get from "lodash/get";
import has from "lodash/has";
import set from "lodash/set";
import { selectFromAllLC, filter } from "./results-calculation";

let opcoCache = {};

export default function getOpco(state, opco, key = "byDivBU") {
  const path = `${opco}.${key}`;
  if (has(opcoCache, path)) {
    return get(opcoCache, path);
  }
  const base = selectFromAllLC(state, key);
  const data = filter(base, { div: opco });
  set(opcoCache, path, data);
  return data;
}
