import { STATUS } from "../constants";
import sum from "lodash/sum";
import map from "lodash/map";
import _get from "lodash/get";

export function calculateStats(byStatus) {
  if (!byStatus) {
    return {};
  }
  const open = _get(byStatus, STATUS.OPEN, 0);
  const total = sum(map(byStatus, i => i));
  const check = _get(byStatus, STATUS.CHECK, 0);
  const done = _get(byStatus, STATUS.DONE, 0) + check;
  const percentRaw = Math.round((done / total) * 100);
  const percent = isNaN(percentRaw) ? 0 : percentRaw;
  const problem = _get(byStatus, STATUS.ISSUE, 0);
  return {
    total,
    open,
    check,
    done,
    percent,
    problem
  };
}
