import React from "react";
import { connect } from "react-redux";
import { Form } from "react-final-form";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import { DialogTitle } from "@material-ui/core";
import StatusDropdown from "./status-dropdown";
import { DialogForm } from "../../components/form-layout";
import { FieldSet, SectionTitle } from "../../components/form-layout";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";

import { setFilter, resetFilter, decorator } from "../../store/filter";
import forEach from "lodash/forEach";

import ClassificationDropdown from "./classification-dropdown";
import BUDropdown from "./bu-dropdown";
import LanguageDropdown from "./language-dropdown";
import BatchDropdown from "./batch-dropdown";
import SentimentDropdown from "./sentiment-dropdown";

const styles = theme => ({
  header: {
    padding: "24px 24px 20px"
  },
  content: {
    paddingTop: 24
    // padding: 0
  }
});

/*
 Idea 1: Form with Icon on the left for single fields
    https://material.io/design/components/text-fields.html#usage
 Idea 2: List with clickable Item for dropdown:
    https://material-ui.com/demos/menus/#selected-menus
*/
function FilterModal({
  classes,
  isOpen,
  close,
  questionId,
  setFilter,
  resetFilter,
  filter,
  defaultFilter
}) {
  const initialValues = filter;
  const onSubmit = values => {
    setFilter(values);
    close();
  };
  //@TODO: Decorator to update status from open to DONE when classes are selected
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="filter-modal-title"
      fullWidth
      maxWidth="sm"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        decorators={[decorator]}
      >
        {({ handleSubmit, dirty, form }) => (
          <>
            <DialogTitle id="filter-modal-title">Filter Worklist</DialogTitle>
            <Divider />
            <DialogContent className={classes.content}>
              <DialogForm>
                <Typography variant="body1">
                  You can select only a status filter, as well as one additional
                  Filter
                </Typography>
                <FieldSet icon={<CheckCircleIcon />}>
                  <StatusDropdown questionId={questionId} />
                </FieldSet>
                <SectionTitle>Additional Filters</SectionTitle>
                <FieldSet icon={<FilterListIcon />}>
                  <ClassificationDropdown questionId={questionId} />
                  <SentimentDropdown questionId={questionId} />
                  <BUDropdown questionId={questionId} />
                  <LanguageDropdown questionId={questionId} />
                  <BatchDropdown questionId={questionId} />
                </FieldSet>
              </DialogForm>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  form.batch(() => {
                    form.initialize({});
                    forEach(defaultFilter, (value, key) => {
                      form.change(key, value);
                    });
                  })
                }
                color="secondary"
              >
                Reset to default
              </Button>
              <Button onClick={close} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" disabled={!dirty}>
                Apply
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
}

export default withStyles(styles)(
  connect(
    state => ({
      filter: state.filter.filter,
      defaultFilter: state.filter.defaultFilter
    }),
    { setFilter, resetFilter }
  )(FilterModal)
);
