import React from "react";
import Button from "@material-ui/core/Button";
import GoogleLogo from "../assets/g-logo.png";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from "react-redux-firebase";

const styles = {
  root: {
    marginTop: 40,
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  logo: {
    height: 18,
    width: 18
  },
  button: {
    backgroundColor: "white"
  },
  label: {
    paddingLeft: 16,
    textTransform: "none",
    verticalAlign: "middle"
  }
};

// Alternative: https://www.npmjs.com/package/react-google-button
function LoginButton({ firebase, classes }) {
  const handleLogin = async () => {
    await firebase.login({ provider: "google", type: "popup" });
  };
  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        onClick={handleLogin}
      >
        <img src={GoogleLogo} className={classes.logo} alt="" />
        <span className={classes.label}>Sign in with Google</span>
      </Button>
    </div>
  );
}

export default withStyles(styles)(withFirebase(LoginButton));
