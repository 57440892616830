import React from "react";
import { Field } from "react-final-form";
import SelectField from "../../components/select-field";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ClassesContainer from "../classes/classes-container";

//@warn: classes is also the prop for withStyles .....
export function ClassificationDropdown({ classes, byClass }) {
  return (
    <Field
      name="classification"
      label="Classification"
      component={SelectField}
      controlProps={{ fullWidth: true }}
    >
      <MenuItem value={false}>All</MenuItem>
      {classes.map(mainClass => [
        <ListSubheader style={{ backgroundColor: "white" }} key={mainClass.id}>
          {mainClass.name}
        </ListSubheader>,
        mainClass.children.map(child => (
          <MenuItem key={child.id} value={child.id}>
            <ListItemText
              primary={`${child.name} (${byClass[child.id] || 0})`}
            />
          </MenuItem>
        ))
      ])}
    </Field>
  );
}

ClassificationDropdown.defaultProps = {
  classes: []
};
export default ClassesContainer(ClassificationDropdown);
