import partition from "lodash/partition";

const edgeCase = (chart, rows) => {
  switch (chart) {
    case "global_byDivision":
    case "global_byDivision_all":
    case "global_byDivision_identical":
      const [DRS, rest] = partition(rows, { div: "DR" });
      return rest.concat(DRS.map(dr => ({ ...dr, skip: true })));
    case "global_byCountry": {
      //@TODO:
      console.warn("SHOULD CHECK IF WE NEED TO ADD AFGHANISTAN");
      return rows;
    }
    default: {
      return rows;
    }
  }
};

export default edgeCase;
