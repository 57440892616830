import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import map from "lodash/map";
import { navigateStep, getUrlContext } from "../store/results/step-navigation";

function SummaryStepper({
  mainStep,
  subStep,
  byType,
  opco,
  mainStepKeys,
  globalKeys,
  subKeys,
  globalSteps,
  subCategory,
  subSteps
}) {
  const activeTopStep = mainStepKeys.indexOf(mainStep);
  const activeSubStep =
    mainStep === "global"
      ? globalKeys.indexOf(subStep)
      : subKeys.indexOf(subStep);

  const selection = getUrlContext();

  return (
    <Stepper orientation="vertical" activeStep={activeTopStep} nonLinear>
      <Step key="global">
        <StepButton onClick={() => navigateStep("global", globalSteps[0].id)}>
          Global
        </StepButton>
        <StepContent>
          <Stepper orientation="vertical" activeStep={activeSubStep} nonLinear>
            {map(globalSteps, s => (
              <Step key={s.id}>
                <StepButton onClick={() => navigateStep("global", s.id)}>
                  {!s.process || !s.process.columns ? "TODO:" : ""}
                  {s.shortTitle(selection)}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </StepContent>
      </Step>
      {map(subCategory, div => {
        return (
          <Step key={div.id}>
            <StepButton onClick={() => navigateStep(div.id, subSteps[0].id)}>
              {div.title || div.id}
            </StepButton>
            <StepContent>
              <Stepper
                orientation="vertical"
                activeStep={activeSubStep}
                nonLinear
              >
                {subSteps.map(s => (
                  <Step key={s.id}>
                    <StepButton onClick={() => navigateStep(div.id, s.id)}>
                      {s.shortTitle({ ...selection })}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default SummaryStepper;
