// Using require syntax for using in node as well.

const map = require("lodash/map");
const flatMap = require("lodash/flatMap");
const forEach = require("lodash/forEach");

const data = [
  {
    key: "cs_Sales/Proposals",
    source: "cs_Sales&Proposals",
    order: 1,
    name: "Sales & Proposals",
    children: [
      {
        key: "cs_sp_Communication/Information_in_pre_&_post_sales",
        name: "Communication & Information in pre/post sales",
        docuName: `Communication /
Information in
Pre & Post Sales`
      },
      {
        key: "cs_sp_Response_Time/Responsiveness_to_needs",
        name: "Response Time & Responsiveness to needs",
        docuName: `Response Time /
Responsiveness to
Needs`
      },
      {
        key: "cs_sp_Competence/Skills",
        name: "Competence & Skills",
        docuName: `Competence / Skills`
      },
      {
        key: "cs_sp_Attitude/Customer_Orientation",
        name: "Attitude & Customer Orientation",
        docuName: `Attitude / Customer
Orientation`
      },
      {
        key: "cs_sp_Understanding_of_Requirements",
        name: "Understanding of Requirements",
        docuName: `Understanding of
Requirements`
      },
      {
        key: "cs_sp_Accessibility/Defined_Contact_Person",
        name: "Accessibility & Defined Contact Person",
        docuName: `Accessibility / Defined
Contact Person`
      },
      { key: "cs_sp_Contact_Frequency", name: "Contact Frequency" },
      { key: "cs_sp_Proposals", name: "Proposals" },
      { key: "cs_sp_Proposal_Document", name: "Proposal Document" },
      { key: "cs_sp_Distribution_Policy", name: "Distribution Policy" },
      { key: "cs_sp_Terms_&_Conditions", name: "Terms & Conditions" },
      { key: "cs_sp_Local_presence", name: "Local Presence" },
      { key: "cs_sp_Other", name: "Other" }
    ]
  },
  {
    key: "cs_Ordering/Delivery/Projectmanagement",
    name: "Ordering & Delivery & PM",
    order: 2,
    children: [
      {
        key: "cs_od_Communication/Information",
        name: "Communication & Information",
        docuName: `Communication /
Information`
      },
      {
        key: "cs_od_Response_Time/Responsiveness_to_needs",
        name: "Response Time & Responsiveness to needs",
        docuName: `Response Time /
Responsiveness to
Needs`
      },
      {
        key: "cs_od_Competence/Skills",
        name: "Competence & Skills",
        docuName: `Competence / Skills`
      },
      {
        key: "cs_od_Attitude/Customer_Orientation",
        name: "Attitude & Customer Orientation",
        docuName: `Attitude / Customer
Orientation`
      },
      {
        key: "cs_od_Understanding_of_Requirements",
        name: "Understanding of Requirements",
        docuName: `Understanding of
Requirements`
      },
      { key: "cs_od_Delivery_Reliability", name: "Delivery Reliability" },
      {
        key: "cs_od_Delivery_Capability/Speed",
        name: "Delivery Capability & Speed",
        docuName: `Delivery Capability /
Speed`
      },
      {
        key: "cs_od_Installation/Supervision/Commissioning",
        name: "Installation & Supervision & Commisioning",
        docuName: `Installation / Supervision /
Commissioning`
      },
      { key: "cs_od_Project_Management", name: "Project Management" },
      { key: "cs_od_Invoicing", name: "Invoicing" },
      { key: "cs_od_Other", name: "Other" }
    ]
  },
  {
    key: "cs_Products/Solutions",
    source: "cs_Products&Solutions",
    name: "Products & Solutions",
    order: 4, // This will still be 3rd in the documentatin, but 4th in cluster
    children: [
      {
        key: "cs_ps_Communication/Information",
        name: "Communication & Information",
        docuName: `Communication /
Information`
      },
      {
        key:
          "cs_ps_Product_Range/Product_Features_meeting_Customer_Requirements",
        name: "Product Range & Product Features meeting customer requirements",
        docuName: `Product Range / Product
Features meeting
Customer Requirements`
      },
      { key: "cs_ps_Product_Quality", name: "Product Quality" },
      {
        key: "cs_ps_Usability/Ease_of_use",
        name: "Usability & Ease of use",
        docuName: `Usability /
Ease of use`
      },
      {
        key: "cs_ps_Product_Updates_Integration_&_Compatibility",
        name: "Product Updates & Integration & Compatibility",
        docuName: `Product Updates /
Integration &
Compatibility`
      },
      { key: "cs_ps_Documentation", name: "Documentation" },
      { key: "cs_ps_Innovation", name: "Innovation" },
      { key: "cs_ps_Price", name: "Price" },
      {
        key: "cs_ps_Business_Model_Product_vs._Solution_Business",
        name: "Business Model: Product vs. Solution Business",
        docuName: `Business Model Product
vs. Solution Business`
      },
      { key: "cs_ps_Other", name: "Other" }
    ]
  },
  {
    key: "cs_AfterSalesSupport",
    name: "After Sales Service",
    order: 3,
    children: [
      {
        key: "cs_as_Communication/Information",
        name: "Communication & Information",
        docuName: `Communication /
Information`
      },
      {
        key: "cs_as_Response_Time/Responsiveness_to_needs",
        name: "Response Time & Responsiveness to needs",
        docuName: `Response Time /
Responsiveness to
Needs`
      },
      {
        key: "cs_as_Competence/Skills_of_Service_Personnel",
        name: "Competence & Skills of Service Personnel",
        docuName: `Competence / Skills
of Service Personnel`
      },
      {
        key: "cs_as_Attitude/Customer_Orientation",
        name: "Attitude & Customer Orientation",
        docuName: `Attitude / Customer
Orientation`
      },
      {
        key: "cs_as_Accessibility/Defined_Contact_Person",
        name: "Accessibility & Defined Contact Person",
        docuName: `Accessibility / Defined
Contact Person`
      },
      { key: "cs_as_Solution_Quality", name: "Solution Quality" },
      { key: "cs_as_Solution_Time", name: "Solution Time" },
      { key: "cs_as_CustomerTraining", name: "Customer Training" },
      { key: "cs_as_Price", name: "Price" },
      { key: "cs_as_Other", name: "Other" }
    ]
  },
  {
    key: "cs_No_improvement_necessary",
    name: "No improvement necessary",
    order: 5,
    children: []
  }
];

export const OTHER_CATEGORY = {
  key: "cs_Not_assignable",
  name: "Others",
  order: 6,
  children: []
};

export const allCategories = [...data, OTHER_CATEGORY];

const competize = s => "cc" + s.substring(2);

const competitorData = data.map(d => {
  return {
    ...d,
    key: competize(d.key),
    source: d.source && competize(d.source),
    children: d.children.map(c => ({
      ...c,
      key: competize(c.key)
    }))
  };
});

export const mainCategories = map(data, "key");
export const subCategories = flatMap(data, r => map(r.children, "key"));

export const categories = data;
export const ccCategories = competitorData;
export const ccMainCategories = map(ccCategories, "key");
export const ccSubCategories = flatMap(ccCategories, r =>
  map(r.children, "key")
);

export const categoryLabels = allCategories.reduce((agg, curr) => {
  agg[curr.key] = curr.name;
  forEach(curr.children, c => {
    agg[c.key] = c.docuName || c.name;
  });
  return agg;
}, {});

// module.exports = {
//   categories: data,
//   ccCategories: competitorData,
//   mainCategories,
//   subCategories
// };
