import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import QuestionRow from "./question-row";

const ProjectTableCell = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

export function ProjectRow({ name, questions }) {
  return (
    <Fragment>
      <TableRow>
        <ProjectTableCell style={{ width: 300 }}>{name}</ProjectTableCell>
        <ProjectTableCell />
        <ProjectTableCell />
        <ProjectTableCell />
        <ProjectTableCell />
        <ProjectTableCell />
        <ProjectTableCell />
      </TableRow>
      {questions.map(q => (
        <QuestionRow key={q.name} {...q} />
      ))}
    </Fragment>
  );
}

ProjectRow.defaultProps = {
  questions: []
};

export default ProjectRow;
