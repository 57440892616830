import { connect } from "react-redux";
import { buOrder } from "../store/results/data/divisions";

import { selectFromAllLC } from "../store/results/results-calculation";

import groupBy from "lodash/groupBy";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";

function sortByBu(bus, div) {
  const order = buOrder[div];
  if (!order) {
    return sortBy(bus);
  }
  return sortBy(bus, bu => {
    const index = order.indexOf(bu);
    return index > -1 ? index : 1000;
  });
}

function sortDivisions(div) {
  var ordered = {};
  sortBy(Object.keys(div)).forEach(key => {
    ordered[key] = div[key];
  });
  return ordered;
}

export default connect(
  state => {
    const baseData = state.results.structuredData;
    const fromAll = selectFromAllLC(baseData, "byDivBU");
    const grouped = groupBy(fromAll, "div");
    const sorted = sortDivisions(grouped);
    const unique = mapValues(sorted, (bus, div) =>
      sortByBu(uniq(map(bus, "bu")), div).filter(a => !!a)
    );
    const lcs = Object.keys(baseData);
    return {
      divisions: unique,
      lcs
    };
  },
  null,
  null,
  { pure: false }
);
