import React from "react";
import styled from "styled-components";
import _Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import _CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import DesertImage from "../assets/salt-desert.jpg";
import ChangeFilterButton from "./filter/change-filter-button";

const Centered = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;

const Card = styled(_Card)`
  max-width: 460px;
`;

const CardMedia = styled(_CardMedia)`
  height: 230px;
`;

export function CompletedPage({ questionId }) {
  return (
    <Centered>
      <Card>
        <CardMedia image={DesertImage} title="Empty Desert" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            No results found
          </Typography>
          <Typography component="p">
            You probably selected a filter, that has no results.
          </Typography>
        </CardContent>

        <CardActions>
          <ChangeFilterButton />
        </CardActions>
      </Card>
    </Centered>
  );
}

export default CompletedPage;
