import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Language } from "@material-ui/icons";

import leadCountries from "../store/results/data/lead-countries";
import ButtonMenu from "../components/button-menu";
import { getUrlContext, selectLC } from "../store/results/step-navigation";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 4
  }
});

export function LeadCountrySelector({ classes }) {
  const { lc } = getUrlContext();
  const selectedLC = leadCountries.find(l => l.id === lc);
  return (
    <div className={classes.root}>
      <ButtonMenu
        icon={<Language style={{ marginRight: 8 }} />}
        label={`${selectedLC.title} (${selectedLC.id})`}
        inputProps={{
          name: "lead-country",
          id: "lead-country"
        }}
      >
        {leadCountries.map(lc => (
          <MenuItem key={lc.id} onClick={() => selectLC(lc.id)}>
            {lc.title} ({lc.id})
          </MenuItem>
        ))}
      </ButtonMenu>
    </div>
  );
}

export default withStyles(styles)(LeadCountrySelector);
