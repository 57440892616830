import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
// import green from "@material-ui/core/colors/green";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
};

const LoadingButton = props => {
  const { loading, classes, children, ...rest } = props;
  return (
    <Button {...rest}>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {children}
    </Button>
  );
};

export default withStyles(styles)(LoadingButton);
