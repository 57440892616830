import React from "react";
import { Form } from "react-final-form";

import Card from "@material-ui/core/Card";
import TextContainer from "./text-container";
import AllClasses from "../classes";
import size from "lodash/size";
import ErrorSnackbar from "./error-snackbar";
import LockSnackbar from "./lock-snackbar";
import TextHeader from "./text-header";

import TextContent from "./text-content";
import useLock from "./lock-text-hook";
import { canClaim } from "../../store/worklist";

//@TODO: Make this configurable
const MAX_CATEGORIES_PER_QUESTION = 4;

const validateText = (values) => {
  if (size(values.classes) > MAX_CATEGORIES_PER_QUESTION) {
    return {
      classes: `Not more than ${MAX_CATEGORIES_PER_QUESTION} classes allowed`,
    };
  }
};

const TextForm = ({
  handleSubmit,
  pristine,
  hasValidationErrors,
  dirty,
  errors,
  disabled,
  next,
  questionId,
  previous,
  item,
  classes,
}) => {
  const submitNext = () => {
    if (!disabled) {
      handleSubmit();
    }
    next();
  };
  const submitPrevious = () => {
    if (!disabled) {
      handleSubmit();
    }
    previous();
  };
  return (
    <>
      <ErrorSnackbar errors={errors} />
      <LockSnackbar open={disabled} />
      <Card style={{ width: "100%" }}>
        <TextHeader questionId={questionId} />
        <TextContent
          id={item.id}
          language={item.language}
          bu={item.bu}
          npsScore={item.nps_score}
          batch={item.batch}
          status={item.status}
          disabled={disabled}
          submitNext={submitNext}
          submitPrevious={submitPrevious}
          hasValidationErrors={hasValidationErrors}
        />
      </Card>
      <AllClasses questionId={questionId} disabled={disabled} />
    </>
  );
};

function TextPage({
  questionId,
  textId,
  user,
  progress,
  item,
  lock,
  unlock,
  save,
  next,
  previous,
}) {
  const initialValues = {
    text: item.text,
    classes: item.classes || [],
    issue: !!item.issue,
    sentiment: item.sentiment,
    storyline: !!item.storyline,
  };

  useLock(questionId, item, user);
  const disabled = !canClaim(user.id, item);
  return (
    <Form
      onSubmit={save}
      initialValues={initialValues}
      validate={validateText}
      subscription={{
        pristine: true,
        dirty: true,
        hasValidationErrors: true,
        errors: true,
      }}
      component={TextForm}
      disabled={disabled}
      next={next}
      previous={previous}
      item={item}
      questionId={questionId}
    />
  );
}

TextPage.defaultProps = {
  item: {},
  progress: 33,
};

export default TextContainer(TextPage);
