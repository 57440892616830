import { connect } from "react-redux";
import { compose, pure } from "recompose";
import { firestoreConnect } from "react-redux-firebase";
import _get from "lodash/get";
import sortBy from "lodash/sortBy";

const enhance = compose(
  pure,
  firestoreConnect(props => [
    {
      collection: `questions`,
      doc: props.questionId,
      subcollections: [{ collection: "classes" }]
    }
  ]),
  connect((state, props) => {
    const path = `questions.${props.questionId}.classes`;
    const classes = _get(state.firestore.data, path, []);
    const sorted = sortBy(classes, "order");
    const byClass = _get(
      state.firestore.data,
      `questions.${props.questionId}.byClass`,
      {}
    );
    return {
      classes: sorted,
      byClass
    };
  })
);

export default enhance;
