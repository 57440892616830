import countries from "i18n-iso-countries";
import find from "lodash/find";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const EMEA = "EUR&AFR";
const CIS = "CIS";
const ME = "ME";
const LAM = "LAM";
const NAFTA = "NAFTA";
const APAC = "Asia / Australia";
const CORP = "Corporate Countries";

export const CONTINENTS = [EMEA, CIS, ME, LAM, NAFTA, APAC, CORP];

const leadCountries = [
  // EMEA
  {
    id: "LC AT",
    continent: EMEA,
    countries: [
      "Austria",
      "Azerbaijan",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Czech Republic",
      "Hungary",
      "Israel",
      "Kosovo",
      "Montenegro",
      "Poland",
      "Romania",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "Ukraine"
    ]
  },
  {
    id: "LC FR",
    continent: EMEA,
    countries: [
      "Belgium",
      "France",
      "Luxembourg",
      "Martinique",
      "Monaco",
      "New Caledonia"
    ]
  },
  {
    id: "LC IT",
    continent: EMEA,
    countries: ["Cyprus", "Greece", "Italy", "Malta"]
  },
  {
    id: "LC MA",
    continent: EMEA,
    countries: [
      "Algeria",
      "Côte d'Ivoire",
      "Gabun",
      "Liberia",
      "Morocco",
      "Senegal",
      "Tunisia"
    ]
  },
  { id: "LC NL", continent: EMEA, countries: ["Netherlands"] },
  {
    id: "LC NG",
    continent: EMEA,
    countries: ["Nigeria"]
  },
  {
    id: "LC ZA",
    continent: EMEA,
    countries: [
      "Angola",
      "Botswana",
      "Ethiopia",
      "Ghana",
      "Kenya",
      "Lesotho",
      "Malawi",
      "Mauritius",
      "Mosambik",
      "Namibia",
      "Sambia",
      "South Africa",
      "Tanzania",
      "Uganda",
      "Zimbabwe"
    ]
  },
  { id: "LC ES", continent: EMEA, countries: ["Portugal", "Spain"] },
  {
    id: "LC SE",
    continent: EMEA,
    countries: [
      "Denmark",
      "Estonia",
      "Finland",
      "Island",
      "Latvia",
      "Lithuania",
      "Norway",
      "Sweden"
    ]
  },
  { id: "LC CH", continent: EMEA, countries: ["Lichtenstein", "Switzerland"] },
  { id: "LC TR", continent: EMEA, countries: ["Turkey"] },
  {
    id: "LC UK",
    title: "United Kingdom",
    code: "GB",
    continent: EMEA,
    countries: ["United Kingdom", "Ireland"]
  },
  // CIS
  {
    id: "LC RU",
    continent: CIS,
    title: "Russian Fed.",
    countries: [
      "Belarus",
      "Kazakhstan",
      "Russian Federation",
      "Turkmenistan",
      "Uzbekistan"
    ]
  },
  // ME
  { id: "LC SA", continent: ME, countries: ["Saudi Arabia"] },
  {
    id: "LC AE",
    continent: ME,
    title: "UAE",
    countries: [
      "Bahrain",
      "Egypt",
      "Iran",
      "Iraq",
      "Jemen",
      "Jordan",
      "Kuwait",
      "Lebanon",
      "Libya",
      "Oman",
      "Pakistan",
      "Qatar",
      "Sudan",
      "UAE"
    ]
  },

  // LAM
  {
    id: "LC AR",
    continent: LAM,
    countries: [
      "Argentina",
      "Bolivia",
      "Chile",
      "Colombia",
      "Ecuador",
      "Paraguay",
      "Peru",
      "Trinidad and Tobago",
      "Uruguay",
      "Venezuela"
    ]
  },
  { id: "LC BR", continent: LAM, countries: ["Brazil"] },

  // NAFTA
  { id: "LC CA", continent: NAFTA, countries: ["Canada"] },
  {
    id: "LC MX",
    continent: NAFTA,
    countries: [
      "Costa Rica",
      "Dominican Republic",
      "El Salvador",
      "Guatemala",
      "Honduras",
      "Mexico",
      "Nicaragua",
      "Panama"
    ]
  },

  // ASIA (APAC)
  {
    id: "LC AU",
    continent: APAC,
    countries: ["Australia", "New Zealand", "Papua New Guinea"]
  },
  { id: "LC JP", continent: APAC, countries: ["Japan"] },
  { id: "LC KR", continent: APAC, title: "Korea", countries: ["Korea"] },
  {
    id: "LC SG",
    continent: APAC,
    countries: [
      "Brunei Darussalam",
      "Cambodia",
      "Indonesia",
      "Laos",
      "Malaysia",
      "Myanmar",
      "Philippines",
      "Singapore",
      "Thailand",
      "Vietnam"
    ]
  },

  // Corporate Countries

  { id: "CC DE", continent: CORP },

  {
    id: "CC US",
    continent: CORP,
    title: "USA ",
    countries: ["Puerto Rico", "USA"]
  },

  {
    id: "CC CN",
    continent: CORP,
    countries: ["China", "Hong Kong", "Macao", "Taiwan"]
  },

  {
    id: "CC IN",
    continent: CORP,
    countries: ["Bangladesh", "India", "Sri Lanka"]
  }

  // OLD LEAD COUNTRIES
  // { id: "LC BE", continent: EMEA },
  // { id: "LC CO", continent: NA },
  // { id: "LC PL", continent: EMEA },
  // { id: "LC CZ", continent: EMEA },
  // { id: "LC ID", continent: APAC },
];

const mapping = {
  UK: "GB"
};

export const translateLC = lc => {
  const code = lc.split(" ")[1];
  const lcDef = find(leadCountries, { id: lc });
  if (lcDef.title) {
    return lcDef.title;
  }
  return countries.getName(mapping[code] || code, "en");
};

const withTitle = lc => {
  const code = lc.id.split(" ")[1];
  return {
    code,
    title: translateLC(lc.id),
    ...lc
  };
};

export default leadCountries.map(withTitle);
