import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { Language, Favorite } from "@material-ui/icons";
import { green, red } from "@material-ui/core/colors";
import { STATUS_UI } from "../../constants";

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  chip: {
    marginLeft: 8
  },
  avatar: {
    marginLeft: -1
  },
  // This is a weird fix
  greenChip: {
    borderColor: green[500]
  },
  greenAvatar: {
    backgroundColor: green[300]
  },
  redChip: {
    borderColor: red[300]
  },
  redAvatar: {
    backgroundColor: red[300]
  }
};

export function InfoChip({ id, language, bu, nps, batch, classes, status }) {
  const npsChip =
    nps >= 9 ? classes.greenChip : nps < 6 ? classes.redChip : undefined;
  const npsAvatar =
    nps >= 9 ? classes.greenAvatar : nps < 6 ? classes.redAvatar : undefined;

  const statusUI = STATUS_UI[status];
  return (
    <div className={classes.root}>
      <div>
        <Chip
          classes={{ root: classes.chip, avatar: classes.avatar }}
          avatar={<Avatar>ID</Avatar>}
          label={id}
          variant="outlined"
          color="primary"
        />
        {batch && (
          <Chip
            classes={{ root: classes.chip }}
            label={batch}
            variant="outlined"
          />
        )}
        {statusUI && (
          <Chip
            classes={{ root: classes.chip, avatar: classes.avatar }}
            avatar={
              <Avatar style={{ backgroundColor: statusUI.color }}>
                {statusUI.label[0]}
              </Avatar>
            }
            variant="outlined"
            label={statusUI.label}
          />
        )}
        <Chip
          classes={{ root: classes.chip, avatar: classes.avatar }}
          avatar={
            <Avatar>
              <Language />
            </Avatar>
          }
          variant="outlined"
          label={language}
        />
        <Chip
          classes={{ root: classes.chip, avatar: classes.avatar }}
          avatar={<Avatar>BU</Avatar>}
          label={bu}
          variant="outlined"
        />
        <Chip
          className={[classes.chip, npsChip].join(" ")}
          avatar={
            <Avatar className={[classes.avatar, npsAvatar].join(" ")}>
              <Favorite />
            </Avatar>
          }
          variant="outlined"
          label={nps}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(InfoChip);
