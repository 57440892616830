import React from "react";
import styled from "styled-components";
import _Card from "@material-ui/core/Card";
import _CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";

const Centered = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;

const Card = styled(_Card)`
  max-width: 460px;
`;

const CardMedia = styled(_CardMedia)`
  height: 230px;
`;

export default function ImagePage({ title, image, children, showProgress }) {
  return (
    <Centered>
      <Card>
        <CardMedia image={image} title={title} />
        {showProgress && <LinearProgress />}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
        </CardContent>
        {children}
      </Card>
    </Centered>
  );
}
