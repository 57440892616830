import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { navigate } from "@reach/router";
import UserContainer from "../user-management/user-container";
import _get from "lodash/get";

function transformValues(values, db) {
  const project = {
    name: values.name,
    active: values.active,
    members: values.members,
    questions: values.questions.map(q => {
      const col = db.collection("questions");
      const link = q.link ? col.doc(q.link) : col.doc();
      return {
        ...q,
        link
      };
    })
  };
  return project;
}

export const EditContainer = compose(
  connect((state, props) => {
    return {
      project: _get(state, `firestore.data.projects.${props.projectId}`),
      projects: _get(state, `firestore.ordered.projects`),
      edit: true
    };
  }),
  withFirestore,
  withHandlers({
    save: props => values => {
      const { projectId, firestore } = props;
      const toUpdate = transformValues(values, firestore);
      return firestore.update(
        { collection: "projects", doc: projectId },
        toUpdate
      );
    },
    deleteProject: props => () => {
      const { projectId, firestore } = props;
      return firestore
        .delete({ collection: "projects", doc: projectId })
        .then(() => navigate("/admin/projects"));
    }
  }),
  UserContainer
);

export const NewContainer = compose(
  withFirestore,
  withHandlers({
    save: props => values => {
      const { firestore } = props;
      const toCreate = transformValues(values, firestore);
      return firestore
        .add({ collection: "projects" }, toCreate)
        .then(doc => navigate(`/admin/projects/${doc.id}`));
    }
  }),
  UserContainer
);
