export const loadState = suffix => {
  try {
    const serializedState = localStorage.getItem(`state.${suffix}`);
    if (!serializedState) {
      return undefined;
    }
    const data = JSON.parse(serializedState);
    window.data = data.structuredData;
    return data;
  } catch (e) {
    return undefined;
  }
};

export const saveState = (state, suffix) => {
  try {
    console.time(`cacheSave_${suffix}`);
    const serializedState = JSON.stringify(state);
    localStorage.setItem(`state.${suffix}`, serializedState);
    console.timeEnd(`cacheSave_${suffix}`);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
