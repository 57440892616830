import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function({
  input,
  meta: { touched, error, submitError },
  label,
  ...rest
}) {
  if (rest.type !== "checkbox") {
    console.warn("CheckboxField needs to be type=checkbox");
  }
  const e = touched && (error || submitError);
  return (
    <FormControl error={e}>
      <FormControlLabel
        control={<Checkbox {...input} {...rest} />}
        label={label}
      />
      {e && <FormHelperText>{e}</FormHelperText>}
    </FormControl>
  );
}
